import logo from '../assets/logos/fs_logo_mono_on_dark.svg';
import logoOnlyWhite from '../assets/logos/fs_logo_mono_on_dark_f_only.svg';
import logoBlack from '../assets/logos/fs_logo_mono_main.svg';
import logoOnlyBlack from '../assets/logos/fs_logo_mono_main_f_only.svg';
import logoBlue from '../assets/logos/fs_logo_main.svg';
import logoMainBlackText from '../assets/logos/fs_logo_main_black_text.svg';
import logoMonoMain from '../assets/logos/fs_logo_mono_main.svg';
import downArrow from '../assets/down_arrow.svg';
import upArrow from '../assets/up_arrow.svg';
import facebook from '../assets/facebook.png';
import linkedIn from '../assets/linkedin.png';
import twitter from '../assets/twitter.png';
import gaugePointer from '../assets/gauge.png';
import gaugeModal from '../assets/gauge-modal.png';
import defaultAvatar from '../assets/placeholder-avatar.png';
import tanks from '../assets/challenges/tanks.png';
import space from '../assets/challenges/space.png';
import bluff from '../assets/challenges/bluff.jpg';
import art from '../assets/challenges/art.jpg';
import elevator from '../assets/challenges/elevator.png';
import deFlag from '../assets/flags/de.svg';
import enFlag from '../assets/flags/en.svg';
import svFlag from '../assets/flags/sv.svg';
import defaultCompanyLogo from '../assets/Images/placeholder-company.png';
import paymentBackground from '../assets/Images/payment-background.jpeg';
import defaultAvatarPlaceholder from '../assets/Images/placeholder-avatar.png';
import landingHeader from '../assets/Images/landingHeader.png';
import landingHeaderDark from '../assets/Images/landingHeaderDark.png';
import learnFromPeers from '../assets/Images/learnFromPeers.png';
import learnFromPeersDark from '../assets/Images/learnFromPeersDark.png';
import funCodingIcons from '../assets/Images/funCodingIcons.png';
import startToCode from '../assets/Images/startToCode.webp';
import codingCharacters from '../assets/Images/codingCharacters.webp';
import headerAnimation from '../assets/Images/headerAnimation.png';
import communityListDarkBackground from '../assets/Images/background-dark.png';
import communityListLightBackground from '../assets/Images/background-light.png';
import authLightBackground from '../assets/Images/auth-light-background.png';
import authDarkBackground from '../assets/Images/auth-dark-background.png';
import laptop from '../assets/Images/laptop.png';
import kodcentrumLogo from '../assets/logos/kodcentrum-logo.png'; // Alternate: kodboken-logo.svg

export const Images = {
  logo,
  logoOnlyWhite,
  logoBlack,
  logoOnlyBlack,
  logoBlue,
  logoMainBlackText,
  logoMonoMain,
  downArrow,
  upArrow,
  facebook,
  linkedIn,
  twitter,
  gaugePointer,
  gaugeModal,
  defaultAvatar,
  tanks,
  space,
  bluff,
  art,
  elevator,
  deFlag,
  enFlag,
  svFlag,
  defaultCompanyLogo,
  paymentBackground,
  defaultAvatarPlaceholder,
  landingHeader,
  landingHeaderDark,
  learnFromPeers,
  learnFromPeersDark,
  funCodingIcons,
  laptop,
  communityListDarkBackground,
  communityListLightBackground,
  authLightBackground,
  authDarkBackground,
  kodcentrumLogo,
};

export const GIFs = {
  startToCode,
  codingCharacters,
};

export const Animations = {
  headerAnimation,
};
