import { produce } from 'immer';

import {
  LOAD_PAYMENTS_REQUEST,
  LOAD_PAYMENTS_SUCCESS,
  LOAD_PAYMENTS_FAILURE,
  CREATE_PAYMENTS_REQUEST,
  CREATE_PAYMENTS_SUCCESS,
  CREATE_PAYMENTS_FAILURE,
} from './constants';

const initialState = {
  loading: false,
  error: '',
  payments: [],
  transaction: {},
};

export default function PaymentsPageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case LOAD_PAYMENTS_REQUEST:
        state.loading = true;
        state.error = '';
        state.payments = [];
        break;
      case LOAD_PAYMENTS_SUCCESS:
        state.loading = false;
        state.error = '';
        state.payments = action.data;
        break;
      case LOAD_PAYMENTS_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.payments = [];
        break;
      case CREATE_PAYMENTS_REQUEST:
        state.loading = true;
        state.error = '';
        state.transaction = {};
        break;
      case CREATE_PAYMENTS_SUCCESS:
        state.loading = false;
        state.error = '';
        state.transaction = action.data;
        state.payments = [action.data.payment, ...state.payments];
        break;
      case CREATE_PAYMENTS_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.transaction = {};
        break;
      default:
        break;
    }
  });
}
