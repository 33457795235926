import API from '../api';

export default class XPService {
  constructor() {
    this.API = new API();
  }

  getXPs = (accountId) => this.API.get(`/xp/events/${accountId}`);

  updateCourseFlowEvent = (body) => this.API.post('/xp/courseFlowEvent', body);
}
