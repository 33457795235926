import API from '../api';

export default class InputService {
  constructor() {
    this.API = new API();
  }

  updateEvent = (ticketId, questionId, body) => this.API.patch(`/MCInput/${ticketId}/${questionId}/update-event`, body);

  autoSaveTicketCode = (body) => this.API.post('/FCInput/autosave-ticket-code', body);

  autoSaveExerciseCode = (body) => this.API.post('/inputExercise/autosave-code', body);

  updateFlowProgress = (body) => this.API.post('/inputExercise/update-flow-progress', body);
}
