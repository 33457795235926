import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from 'global/colors';

export const Button = ({ children, type, disabled, zIndex, margin, onClick, removeMargin, selected, ...props }) => (
  <ButtonContainer zIndex={zIndex} disabled={disabled} margin={margin} removeMargin={removeMargin}>
    <ButtonElement
      type={type}
      disabled={disabled}
      selected={selected}
      onClick={() => !disabled && onClick && onClick()}
      {...props}
    >
      {children}
    </ButtonElement>
  </ButtonContainer>
);

export const LinkButton = ({ children, removeMargin, href, ...props }) => (
  <AnchorLink href={href}>
    <Button removeMargin={removeMargin} {...props}>
      {children}
    </Button>
  </AnchorLink>
);

export const OutlineButton = ({ children, small, removeMargin, margin, secondaryBlue, disabled, onClick }) => (
  <ButtonContainer removeMargin={removeMargin} margin={margin} disabled={disabled}>
    <OutlineButtonElement small={small} secondaryBlue={secondaryBlue} disabled={disabled} onClick={onClick}>
      {children}
    </OutlineButtonElement>
  </ButtonContainer>
);

const AnchorLink = styled.a`
  color: ${colors.white};
  margin: 'auto';
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;

  :hover {
    color: ${colors.fsCyan};
    text-decoration: none;
  }
`;

const ButtonContainer = styled.div`
  margin: ${(props) => (props.removeMargin ? '0' : 'auto')};

  ${(props) =>
    props.zIndex &&
    css`
      z-index: ${props.zIndex};
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}

    ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
`;

const ButtonElement = styled.button`
  text-transform: uppercase;
  padding: 7px 15px;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1px;
  color: ${colors.white};
  transition: padding 0.25s ease-in-out;
  border: 2px solid transparent;
  background: transparent;
  user-select: none;
  appearance: none !important;
  white-space: nowrap;

  :focus {
    outline: none;
    background: ${colors.fsCyan};
    color: ${colors.white};
    text-decoration: none;
  }

  :hover {
    background: ${colors.white};
    color: ${colors.fsCyan};
    text-decoration: none;
  }

  ${(props) =>
    props.isScrolled &&
    css`
      padding-top: 3px;
      padding-bottom: 3px;
    `}

  ${(props) =>
    props.primary &&
    css`
      background: ${colors.fsCyan};

      ${props.border &&
      css`
        :hover {
          border: 2px solid ${colors.fsCyan};
        }
      `}
    `}

  ${(props) =>
    props.secondary &&
    css`
      border-color: ${colors.white};
    `}

  ${(props) =>
    props.primaryBlue &&
    css`
      background: ${colors.fsDarkBlue};
      :focus {
        outline: none;
        background: ${colors.fsDarkBlue};
      }
      :hover {
        background: ${colors.white};
        color: ${colors.fsCyan};
        text-decoration: none;
      }
    `}

  ${(props) =>
    props.primaryGreenBorder &&
    css`
      :hover {
        border: 2px solid ${colors.fsCyan};
      }
    `}

  ${(props) =>
    props.small &&
    css`
      min-height: 2rem;
      padding: 6px 18px;
      font-size: 12px;
    `}

  ${(props) =>
    props.isMenu &&
    css`
      @media (max-width: 1024px) {
        background: none;
        border: none;
        padding: 0;
        color: ${colors.darkGray2};

        :hover {
          background: none;
        }
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}

    ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}

    ${(props) =>
    props.primaryGreenBorder &&
    css`
      :hover {
        border: 2px solid ${colors.fsCyan};
      }
    `}
    
    ${(props) =>
    props.primaryRed &&
    css`
      background: ${colors.red};

      :hover {
        border: 2px solid ${colors.red};
        color: ${colors.red};
      }
    `}
    
    ${({ selected }) =>
    selected &&
    css`
      color: ${colors.white};
      background: ${colors.green2};
      border: 2px solid ${colors.green2};

      :hover {
        color: ${colors.white};
        background: ${colors.hoverGreen};
        border-color: ${colors.hoverGreen};
      }
      :focus {
        color: ${colors.white};
        background: ${colors.hoverGreen};
        border-color: ${colors.hoverGreen};
      }
    `}
`;

const OutlineButtonElement = styled.button`
  text-transform: uppercase;
  padding: 7px 15px;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1px;
  transition: padding 0.25s ease-in-out;
  border: 2px solid transparent;
  user-select: none;
  appearance: none !important;
  white-space: nowrap;
  background: ${colors.white};
  color: ${colors.fsDarkBlue};
  border-color: ${colors.fsDarkBlue};
  :hover {
    color: ${colors.fsCyan};
    border-color: ${colors.fsCyan};
    background: ${colors.white};
    text-decoration: none;
  }
  :focus {
    color: ${colors.fsDarkBlue};
    border-color: ${colors.fsDarkBlue};
    background: ${colors.white};
    text-decoration: none;
  }

  ${(props) =>
    props.small &&
    css`
      padding: 6px 18px;
      font-size: 12px;
    `}

  ${(props) =>
    props.secondaryBlue &&
    css`
      background: ${colors.white};
      color: ${colors.fsCyan};
      border-color: ${colors.fsCyan};
      :hover {
        color: ${colors.fsDarkBlue};
        border-color: ${colors.fsDarkBlue};
      }
      :focus {
        color: ${colors.fsDarkBlue};
        border-color: ${colors.fsDarkBlue};
        background-color: ${colors.white};
      }
    `}

    ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;
