import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import BuilderComponent from 'components/BuilderComponent/BuilderComponent';
import { Wrapper } from 'components/Layout/Layout';
import * as strings from './strings';
import { useActions } from 'hooks/useActions';
import { useAppContext } from 'hooks/useContext';
import { getCookie } from 'functions/getCookie';
import * as actions from './actions';
import apiErrors from 'api/apiErrors';

export default React.memo(() => {
  const { language } = useAppContext();

  const { error, success } = useSelector((state) => state.signUp);
  const { signup, clearState, clearError } = useActions(actions);

  const campaignSource = getCookie('campaignSource');

  useEffect(() => {
    if (error && language) {
      toast.error(apiErrors?.[error?.name]?.[language] ?? error);
      clearError();
    }
  }, [error, clearError, language]);

  useEffect(() => {
    if (success && language) {
      toast.success(strings.successLabel[language]);
      clearState();
    }
  }, [success, clearState, language]);

  const registerDemoCompany = (company, email, username) => {
    if (!company.trim()) {
      toast.error(strings.companyEmpty[language]);
    } else if (!username.trim()) {
      toast.error(strings.nameEmpty[language]);
    } else {
      signup(email, company.trim(), username.trim(), true, campaignSource ? campaignSource : undefined);
    }
  };

  return (
    <Wrapper>
      <BuilderComponent
        entries={{
          de: '5e9343063c664df5a0fd68f7a108477d',
          en: 'ea132d4254c249e6a8f1839f62b880f5',
          sv: '62a0637310da45dcaedc735d2c215802',
        }}
        data={{
          myFunction: (company, email, username) => (email ? registerDemoCompany(company, email, username) : null),
        }}
      />
    </Wrapper>
  );
});
