import API from '../api';

export default class ConfigService {
  constructor() {
    this.API = new API();
  }

  getLanguageVersions = () => this.API.get(`/config/languageVersions`);

  updateEmails = (body) => this.API.patch(`/config/updateConfigEmails`, body);

  updateConfig = (id, body) => this.API.patch(`/config/updateConfig/${id}`, body);

  updatePlan = (body) => this.API.patch(`/config/updatePlan`, body);

  getPlan = () => this.API.get(`/config/plan`);
}
