export const LOAD_SUPERADMIN_REQUEST = 'SuperAdminPage/LOAD_SUPERADMIN_REQUEST';
export const LOAD_SUPERADMIN_SUCCESS = 'SuperAdminPage/LOAD_SUPERADMIN_SUCCESS';
export const LOAD_SUPERADMIN_FAILURE = 'SuperAdminPage/LOAD_SUPERADMIN_FAILURE';
export const CREATE_COMPANY_REQUEST = 'SuperAdminPage/CREATE_COMPANY_REQUEST';
export const CREATE_COMPANY_SUCCESS = 'SuperAdminPage/CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAILURE = 'SuperAdminPage/CREATE_COMPANY_FAILURE';
export const UPDATE_COMPANY_REQUEST = 'SuperAdminPage/UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'SuperAdminPage/UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILURE = 'SuperAdminPage/UPDATE_COMPANY_FAILURE';
export const UPDATE_ADMIN_COMPANY_REQUEST = 'SuperAdminPage/UPDATE_ADMIN_COMPANY_REQUEST';
export const UPDATE_ADMIN_COMPANY_SUCCESS = 'SuperAdminPage/UPDATE_ADMIN_COMPANY_SUCCESS';
export const UPDATE_ADMIN_COMPANY_FAILURE = 'SuperAdminPage/UPDATE_ADMIN_COMPANY_FAILURE';
export const UPDATE_CONFIG_EMAILS_REQUEST = 'SuperAdminPage/UPDATE_CONFIG_EMAILS_REQUEST';
export const UPDATE_CONFIG_EMAILS_SUCCESS = 'SuperAdminPage/UPDATE_CONFIG_EMAILS_SUCCESS';
export const UPDATE_CONFIG_EMAILS_FAILURE = 'SuperAdminPage/UPDATE_CONFIG_EMAILS_FAILURE';
export const UPDATE_CONFIG_REQUEST = 'SuperAdminPage/UPDATE_CONFIG_REQUEST';
export const UPDATE_CONFIG_SUCCESS = 'SuperAdminPage/UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_FAILURE = 'SuperAdminPage/UPDATE_CONFIG_FAILURE';
export const UPDATE_PLAN_REQUEST = 'SuperAdminPage/UPDATE_PLAN_REQUEST';
export const UPDATE_PLAN_SUCCESS = 'SuperAdminPage/UPDATE_PLAN_SUCCESS';
export const UPDATE_PLAN_FAILURE = 'SuperAdminPage/UPDATE_PLAN_FAILURE';
