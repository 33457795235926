import { produce } from 'immer';

import {
  GET_ME_REQUEST,
  GET_ME_SUCCESS,
  GET_ME_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  SET_PROFILE,
  CLEAR_ERROR,
  CLEAR_STATE,
} from './constants';
import { Images } from 'assets/images';

const initialState = {
  loading: false,
  error: '',
  success: false,
  profile: {
    coderCommunityProfile: {
      country: '',
      description: '',
      employer: '',
      role: '',
      languages: [],
      frameworks: [],
    },
    challenges: [],
    tournaments: [],
    exercises: [],
    name: '',
    email: '',
    avatar: '',
    rank: 'novice',
    level: 0,
    totalXp: 0,
    xpForLevel: 0,
    xpInThisLevel: 0,
    challengesWon: 14,
    totalChallenges: 23,
    trophies: {},
    achievements: 15,
    maxAchievements: 30,
    latestCompletedAchievement: {},
    weightedScore: 12832,
    activity: {},
    friends: [
      {
        name: 'Allie Sembler',
        level: 4,
        avatar: Images.tanks,
        challengesWon: 1,
        totalChallenges: 4,
        trophies: 3,
        achievements: 6,
        weightedScore: 2384,
      },
      {
        name: 'Askell',
        level: 11,
        avatar: Images.space,
        challengesWon: 2,
        totalChallenges: 3,
        trophies: 5,
        achievements: 9,
        weightedScore: 13598,
      },
    ],
  },
};

export default function ProfilePageReducer(userType = '') {
  return (baseState = initialState, action) =>
    produce(baseState, (state) => {
      switch (action.type) {
        case `${userType === 'ME' ? GET_ME_REQUEST : GET_USER_REQUEST}`:
          state.loading = true;
          state.error = '';
          state.success = false;
          break;
        case `${userType === 'ME' ? GET_ME_SUCCESS : GET_USER_SUCCESS}`:
          state.loading = false;
          state.error = '';
          state.success = true;
          state.profile = {
            ...state.profile,
            ...action.profile,
            challenges: action.challenges,
            tournaments: action.tournaments,
            exercises: action.exercises,
            achievements: action.completedAchievements,
            maxAchievements: action.totalNumberOfAchievements,
            latestCompletedAchievement: action.latestCompletedAchievement ? action.latestCompletedAchievement : {},
            trophies: action.trophies ? action.trophies : {},
            activity: action.activities ? action.activities : {},
            level: action.level,
            totalXp: action.totalXp,
            xpForLevel: action.xpForLevel,
            xpInThisLevel: action.xpInThisLevel,
          };
          break;
        case `${userType === 'ME' ? GET_ME_FAILURE : GET_USER_FAILURE}`:
          state.loading = false;
          state.error = action.error;
          state.success = false;
          break;
        case UPDATE_USER_REQUEST:
          state.loading = true;
          state.error = '';
          state.success = false;
          break;
        case UPDATE_USER_SUCCESS:
          state.loading = false;
          state.error = '';
          state.success = true;
          break;
        case UPDATE_USER_FAILURE:
          state.loading = false;
          state.error = action.error;
          state.success = false;
          break;
        case SET_PROFILE:
          if (action.isNotCommunity) {
            state.profile[action.field] = action.value;
          } else {
            state.profile.coderCommunityProfile[action.field] = action.value;
          }
          break;
        case CLEAR_ERROR:
          state.error = '';
          break;
        case CLEAR_STATE:
          state.loading = false;
          state.error = '';
          state.success = false;
          break;
        default:
          break;
      }
    });
}
