import API from '../api';

export default class FreecodeRunService {
  constructor() {
    this.API = new API();
  }

  runTrial = (body) => this.API.post('/freecode/run/trial', body);

  runTicket = (body) => this.API.post('/freecode/run/ticket', body);

  runTicketInspect = (body) => this.API.post('/freecode/run/ticket-inspect', body);

  runCreator = (body) => this.API.post('/freecode/run/creator', body);

  runContest = (body) => this.API.post('/freecode/run/contest', body);

  runExercise = (body) => this.API.post('/freecode/run/exercise', body);
}
