import React from 'react';
import styled from 'styled-components';

import { useAppContext } from 'hooks/useContext';

import { darkModeBackground } from 'global/themeDark';
import { lightModeHeaderBackground } from 'global/themeLight';

export default function ToggleThemeSwitch() {
  const { isDarkMode, toggleDarkMode } = useAppContext();

  return (
    <ToggleSwitch>
      <DarkModeInput
        checked={isDarkMode}
        onClick={() => toggleDarkMode()}
        title={isDarkMode ? 'Light Mode' : 'Dark Mode'}
        onChange={() => {}}
      />
      <DarkModeSwitchOutside isDarkMode={isDarkMode}>
        <IconButtonIcon isDarkMode={isDarkMode} />
        <DarkModeSwitchInside isDarkMode={isDarkMode} />
      </DarkModeSwitchOutside>
    </ToggleSwitch>
  );
}

const ToggleSwitch = styled.div`
  position: relative;
  width: 0px;
  width: 22px;
  height: 41px;
`;

const DarkModeInput = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  top: 0;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 22px;
  right: 0;
  margin: 0;
  &:checked ~ span > span {
    top: 21px;
  }
  &:checked ~ span > i {
    top: 4px;
  }
`;

const DarkModeSwitchOutside = styled.span`
  height: 100%;
  border-radius: 11px;
  padding: 11px;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
  background: ${({ isDarkMode }) => (isDarkMode ? lightModeHeaderBackground : darkModeBackground)};
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
`;

const DarkModeSwitchInside = styled.span`
  border-radius: 50%;
  background: ${({ isDarkMode }) => (isDarkMode ? darkModeBackground : lightModeHeaderBackground)};
  position: absolute;
  transition: all 0.3s ease-in-out;
  width: 16px;
  height: 16px;
  left: 3px;
  top: 3px;
`;

const IconButtonIcon = styled.i.attrs(({ isDarkMode }) => ({
  className: isDarkMode ? 'fa-solid fa-sun' : 'fa-regular fa-moon',
}))`
  color: ${({ isDarkMode }) => (isDarkMode ? darkModeBackground : lightModeHeaderBackground)};
  position: absolute;
  font-size: 12px;
  text-align: center;
  width: 100%;
  left: 0;
  top: 25px;
`;
