import { detect } from 'detect-browser';

const browser = detect();

const mobileOs = ['iOS', 'Android OS', 'BlackBerry OS', 'Windows Mobile'];

const isMobile = () => {
  if (mobileOs.some((os) => os === browser.os)) return true;

  let ua = navigator.userAgent.toLowerCase();
  let platform = navigator.platform?.toLowerCase?.();
  let platformName = ua.match(/ip(?:ad|od|hone)/)
    ? 'ios'
    : (ua.match(/(?:webos|android)/) || platform?.match?.(/mac|win|linux/) || ['other'])[0];
  return /ios|android|webos/.test(platformName);
};

export const manageZendeskButton = (mode = 'show') => {
  if (mode === 'hide') return window?.zE?.hide?.();

  if (!window.zE) return renderZendeskButton(mode);

  if (mode === 'show') return window?.zE?.show?.();
  if (mode === 'open') window.zE('webWidget', 'open');
};

//zendesk button
const renderZendeskButton = (mode) => {
  if (isMobile()) return;

  window.zEmbed ||
    (function (e, t) {
      let n,
        o,
        d,
        i,
        s,
        a = [],
        r = document.createElement('iframe');
      window.zEmbed = function () {
        a.push(arguments);
      };
      window.zE = window.zE || window.zEmbed;
      r.src = 'javascript:false'; //eslint-disable-line
      r.title = '';
      r.role = 'presentation';
      (r.frameElement || r).style.cssText = 'display: none';
      d = document.getElementsByTagName('script');
      d = d[d.length - 1];
      d.parentNode.insertBefore(r, d);
      i = r.contentWindow;
      s = i.document;
      window.zESettings = {
        webWidget: {
          position: { horizontal: 'left', vertical: 'bottom' },
          offset: {
            horizontal: '0px',
            vertical: '30px',
            mobile: {
              horizontal: '0px',
              vertical: '40px',
            },
          },
        },
      };
      try {
        o = s;
      } catch (e) {
        n = document.domain;
        r.src = 'javascript:let d=document.open();d.domain="' + n + '";void(0);'; //eslint-disable-line
        o = s;
      }
      o.open()._l = function () {
        const o = this.createElement('script');
        n && (this.domain = n);
        o.id = 'js-iframe-async';
        o.src = e;
        this.t = +new Date();
        this.zendeskHost = t;
        this.zEQueue = a;
        this.body.appendChild(o);
      };
      o.write('<body onload="document._l();">');
      o.close();
    })('https://assets.zendesk.com/embeddable_framework/main.js', 'futureskill.zendesk.com');
  /* ]]> */

  window.zE(function () {
    manageZendeskButton(mode);
  });
};

export default manageZendeskButton;
