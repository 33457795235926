import { produce } from 'immer';

import { SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE, SIGNUP_CLEAR_ERROR, SIGNUP_CLEAR_STATE } from '../constants';

const initialState = {
  loading: false,
  error: '',
  success: false,
};

export default function signUp(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case SIGNUP_CLEAR_STATE:
        state.loading = false;
        state.error = '';
        state.success = false;
        break;
      case SIGNUP_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        break;
      case SIGNUP_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        break;
      case SIGNUP_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case SIGNUP_CLEAR_ERROR:
        state.error = '';
        break;
      default:
        break;
    }
  });
}
