import API from '../api';

export default class CommentService {
  constructor() {
    this.API = new API();
  }

  // Get a list of comments
  getComments = (type, id) => this.API.get(`/communityComment/?${type}=${id}`);

  // Create a new comment
  createComment = (body) => this.API.post('/communityComment/', body);

  // Archive a comment
  archiveComment = (id) => this.API.put(`/communityComment/archive/${id}`);

  // Report a comment
  reportComment = (body) => this.API.post('/communityReport/', body);
}
