import API from '../api';

export default class AchievementService {
  constructor() {
    this.API = new API();
  }
  getPayments = () => this.API.get('/payment/');

  createPayment = (body) => this.API.post('/payment/createPayment', body);

  purchaseTest = (body) => this.API.post('/payment/purchaseTest', body);

  updateSuccessfulTransaction = (paymentId, transactionId) =>
    this.API.post(`/payment/setSuccessfulTransaction/${paymentId}/${transactionId}`);

  updateFailedTransaction = (paymentId) => this.API.post(`/payment/setFailedTransaction/${paymentId}`);
}
