import React from 'react';
import { getConfig } from 'functions/getConfig';

export const recruitersFaqContent = [
  {
    title: 'What programming languages do you cover?',
    data: (
      <p>
        Please check our website{' '}
        <a href={getConfig().REACT_URL} target="_blank" rel="noreferrer">
          www.futureskill.com
        </a>
        , as we continually add more languages. Our most popular tests are in Java, JavaScript, Python C#, C and C++.
      </p>
    ),
  },
  {
    title: 'Am I able to test people remotely?',
    data: (
      <p>
        Yes. Our online platform can be accessed from any computer with an Internet connection. However, you can also
        choose to present the test to the candidate during a job interview or booked screening.
      </p>
    ),
  },
  {
    title: 'Do you offer personality tests?',
    data: (
      <p>
        No. We believe in testing competence rather than personality and only offer tests related to IT and programming.
      </p>
    ),
  },
  {
    title: 'Can I buy one-off tests?',
    data: (
      <p>
        Yes. Send an email to{' '}
        <a href="mailto:contact@futureskill.com" target="_blank" rel="noreferrer">
          contact@futureskill.com
        </a>{' '}
        for more information about our offers and prices. If you have a company account you can also buy tests directly
        on the platform by clicking the available number of tests in the upper right corner when on the dashboard.
      </p>
    ),
  },
  {
    title: 'For how long do you store personal data?',
    data: (
      <p>
        A maximum of three years, unless you want to remove your data. You can read our{' '}
        <a href={`${getConfig().REACT_URL}/privacy-policy`} target="_blank" rel="noreferrer">
          Privacy Policy
        </a>{' '}
        for more information about your rights. Each company can also set a custom value for how long data should be
        stored for tests they send out.
      </p>
    ),
  },
  {
    title: 'Can I adapt the test according to the job role we are advertising?',
    data: (
      <p>
        If you want to test a specific programming language, we recommend a Freecode and a Multichoice test. For
        freecode tests you can choose between C, C#, C++, Go, Java, Javascript, PHP, Python, Ruby, Scala, and
        Typescript.
      </p>
    ),
  },
];

export const candidatesFaqContent = [
  {
    title: 'Can I open the email link now and take the test later?',
    data: <p>Yes. You have to press start on the page for the test to begin.</p>,
  },
  {
    title: 'How much time do I need to take one of your tests?',
    data: (
      <p>
        It depends on the test. Most of our tests are 50 or 60 minutes long. You can see how much time you will have
        availble on the landing page before you start the test.
      </p>
    ),
  },
  {
    title: 'Will I be able to see my test results?',
    data: <p>It depends on the recruiter and whether or not they choose to share the final score with you.</p>,
  },
  {
    title: 'Can I take the test on a mobile device?',
    data: <p>You can take the Multiple choice test on a mobile device. You need a computer for all other tests.</p>,
  },
  {
    title: 'Can I choose what programming language I want to use?',
    data: (
      <p>
        Yes and no. Our Freecode tests are available in the following languages: C, C#, C++, Go, Java, Javascript, PHP,
        Python, Ruby, Scala, and Typescript, but it is up to the recruiter what language they want to test. Some might
        want to verify your knowledge of a specific language, some might leave the choice to you.
      </p>
    ),
  },
  {
    title: 'Do I need to install any software to take the test?',
    data: <p>No. You only need an Internet connection and browser in order to access our online platform.</p>,
  },
  {
    title: 'Am I able to test run my code before submitting it?',
    data: (
      <p>
        Yes. Make sure to write your code in the online editor and press on run code often to verify that your solution
        works.
      </p>
    ),
  },
  {
    title: 'Am I allowed to use the Internet or ask a colleague for help while taking the test?',
    data: (
      <p>
        No. The idea is to score your abilities, without outside help. The exception is general language ducumentation,
        which is ok to look up.
      </p>
    ),
  },
  {
    title: 'Will my coding style affect my score?',
    data: (
      <p>
        It will not affect your score, but your recruiter will be able to take your coding style into account when they
        review your test.
      </p>
    ),
  },
  {
    title: 'What happens if I run out of time?',
    data: (
      <p>
        The deadline is final. After the time runs out, your code will be submitted and reviewed. However, during a
        Freecode test you will be able to continue to code after the time is up, and the report presented to the
        recruiter will also contain the score achieved ater the deadline.
      </p>
    ),
  },
  {
    title: 'Would you like to receive feedback?',
    data: (
      <p>
        We are always looking to improve our tests! You can choose to report questions while you take the test or give
        feedback after the test is finished.
      </p>
    ),
  },
  {
    title: 'What is the Future Skill privacy policy?',
    data: (
      <p>
        To view the policy, visit <a href="privacy-policy">privacy policy</a> page.
      </p>
    ),
  },
];
