const landingPage = '/';
const aboutPage = '/about';
const contactPage = '/contact';
const membershipPage = '/membership';
const cookiesPage = '/cookies';
const policyPage = '/privacy-policy';
const faqPage = '/faq';
const companyPage = '/';
// const companyPage = '/company';
const companyEventsPage = '/company-events';
const codeChallenge = '/codechallenge';
const notFoundPage = '/notFound';
const defaultHandler = '*';

const Path = {
  landingPage,
  aboutPage,
  contactPage,
  membershipPage,
  cookiesPage,
  policyPage,
  faqPage,
  companyPage,
  companyEventsPage,
  codeChallenge,
  notFoundPage,
  defaultHandler,
};

export default Path;
