export const successLabel = {
  de: 'Success! Please check your email',
  en: 'Success! Please check your email',
  sv: 'Framgång! Kontrollera din e-post',
};

export const companyEmpty = {
  de: 'Company name is required',
  en: 'Company name is required',
  sv: 'Företagsnamn krävs',
};

export const nameEmpty = {
  de: 'Name is required',
  en: 'Name is required',
  sv: 'Namn krävs',
};
