import { CardHeader, CardBody, Card } from 'reactstrap';
import styled, { css } from 'styled-components';
import { colors } from 'global/colors';

export const CollapseContainer = styled(Card)`
  display: block;
  width: 100%;
  border: 1px solid ${colors.fsDarkBlue};
  margin-bottom: 10px;
  &.active {
    transform: scale(1.01);
    transition: all ease-in 0.2s;
  }

  ${({ highlighted }) => {
    return (
      highlighted &&
      css`
        border: 1px solid ${colors.fsDarkBlueOffset};
      `
    );
  }}
`;

export const ActionIcon = styled.span`
  font-size: 20px;
  padding: 0 5px;
  color: ${colors.white};
  position: absolute;
  right: 10px;
`;

export const CollapseHeader = styled(CardHeader)`
  display: block;
  align-content: center;
  background: ${colors.fsDarkBlue};
  color: ${colors.white};
  border-bottom: 1px solid ${colors.fsDarkBlueOffset};
  font-size: 18px;
  font-weight: 600;
  cursor: ${(props) => (props.collapsable ? 'pointer' : 'default')};

  &:hover {
    background: ${colors.fsDarkBlueHoverLight};
  }
`;

// TODO: this transition isn't working yet!! need to fix it
export const CollapseBody = styled(CardBody)`
  height: auto;
  transition:
    all,
    0.3s linear;

  &.hidden {
    height: 0;
    transition:
      all,
      0.3s linear;
  }

  background: ${({ theme }) => theme.pageBackground};
  color: ${({ theme }) => theme.label};
`;

export const HeadingTitle = styled.div``;

export const Description = styled.div`
  font-size: 14px;
`;

export const TitleAndActionContainer = styled.div`
  display: flex;
`;
