export const LOAD_REPORT_REQUEST = 'ReportPage/LOAD_REPORT_REQUEST';
export const LOAD_REPORT_SUCCESS = 'ReportPage/LOAD_REPORT_SUCCESS';
export const LOAD_REPORT_FAILURE = 'ReportPage/LOAD_REPORT_FAILURE';
export const UPDATE_TICKET_DEPARTMENT_REQUEST = 'ReportPage/UPDATE_TICKET_DEPARTMENT_REQUEST';
export const UPDATE_TICKET_DEPARTMENT_SUCCESS = 'ReportPage/UPDATE_TICKET_DEPARTMENT_SUCCESS';
export const UPDATE_TICKET_DEPARTMENT_FAILURE = 'ReportPage/UPDATE_TICKET_DEPARTMENT_FAILURE';
export const GENERATE_ANONYMISE_REPORT_LINK_REQUEST = 'ReportPage/GENERATE_ANONYMISE_REPORT_LINK_REQUEST';
export const GENERATE_ANONYMISE_REPORT_LINK_SUCCESS = 'ReportPage/GENERATE_ANONYMISE_REPORT_LINK_SUCCESS';
export const GENERATE_ANONYMISE_REPORT_LINK_FAILURE = 'ReportPage/GENERATE_ANONYMISE_REPORT_LINK_FAILURE';
export const DISABLE_ANONYMISE_REPORT_LINK_REQUEST = 'ReportPage/DISABLE_ANONYMISE_REPORT_LINK_REQUEST';
export const DISABLE_ANONYMISE_REPORT_LINK_SUCCESS = 'ReportPage/DISABLE_ANONYMISE_REPORT_LINK_SUCCESS';
export const DISABLE_ANONYMISE_REPORT_LINK_FAILURE = 'ReportPage/DISABLE_ANONYMISE_REPORT_LINK_FAILURE';
