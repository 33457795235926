import { useContext } from 'react';

import { AppContext } from 'components/AppContextProvider/AppContextProvider';
// import { FreecodeContextLayout } from 'components/FreecodePage/FreecodeContextLayout';
// import { FreecodeContextData } from 'components/FreecodePage/FreecodeContextData';
// import { FreecodeContextRun } from 'components/FreecodePage/FreecodeContextRun';
// import { FreecodeCreatorContext } from 'pages/FreecodeCreator/FreecodeCreator';
// import { MultichoiceCreatorContext } from 'pages/MultichoiceCreator/MultichoiceCreator';

export const useAppContext = () => useContext(AppContext);

// Shared freecode contexts
// export const useFreecodeContextLayout = () => useContext(FreecodeContextLayout);
// export const useFreecodeContextData = () => useContext(FreecodeContextData);
// export const useFreecodeContextRun = () => useContext(FreecodeContextRun);

// // Freecode creator exclusive context
// export const useFreecodeCreatorContext = () => useContext(FreecodeCreatorContext);

// export const useMultichoiceCreatorContext = () => useContext(MultichoiceCreatorContext);
