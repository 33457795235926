/**
 * this file should contain all dispatch constants used in global actions and reducers
 */

// Auth actions
export const AUTH_INITIALIZE = 'auth/AUTH_INITIALIZE';
export const AUTH_INITIALIZE_DONE = 'auth/AUTH_INITIALIZE_DONE';

export const AUTH_LOGIN_REQUEST = 'auth/AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'auth/AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'auth/AUTH_LOGIN_FAILURE';

export const AUTH_UPDATE_SUCCESS = 'auth/AUTH_UPDATE_SUCCESS';

export const AUTH_TWO_FACTOR = 'auth/AUTH_TWO_FACTOR';

export const AUTH_CLEAR_DATA = 'auth/AUTH_CLEAR_DATA';
export const AUTH_CLEAR_ERROR = 'auth/AUTH_CLEAR_ERROR';

export const AUTH_TWO_FACTOR_TIMEOUT = 'auth/AUTH_TWO_FACTOR_TIMEOUT';
export const AUTH_TWO_FACTOR_TIMEOUT_ERROR = 'Took to long to enter two factor code. Please log in again';

export const UPDATE_COMPANY_STATUS = 'auth/UPDATE_COMPANY_STATUS';

export const UPDATE_WEBSOCKET_STATUS = 'auth/UPDATE_WEBSOCKET_STATUS';

// Set/Reset password actions
export const MANAGE_PASSWORD_REQUEST = 'ManagePasswordActions/MANAGE_PASSWORD_REQUEST';
export const MANAGE_PASSWORD_SUCCESS = 'ManagePasswordActions/MANAGE_PASSWORD_SUCCESS';
export const MANAGE_PASSWORD_FAILURE = 'ManagePasswordActions/MANAGE_PASSWORD_FAILURE';
export const UPDATE_PASSWORD_REQUEST = 'ManagePasswordActions/UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'ManagePasswordActions/UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'ManagePasswordActions/UPDATE_PASSWORD_FAILURE';
export const MANAGE_PASSWORD_CLEAR_ERROR = 'ManagePasswordActions/MANAGE_PASSWORD_CLEAR_ERROR';
export const MANAGE_PASSWORD_CLEAR_STATE = 'ManagePasswordActions/MANAGE_PASSWORD_CLEAR_STATE';

// Sign up actions
export const SIGNUP_REQUEST = 'SignupPage/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SignupPage/SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SignupPage/SIGNUP_FAILURE';
export const SIGNUP_CLEAR_ERROR = 'SignupPage/CLEAR_ERROR';
export const SIGNUP_CLEAR_STATE = 'SignupPage/CLEAR_STATE';

// Activate 2 FA actions
export const LOAD_QR_CODE_REQUEST = 'auth/LOAD_QR_CODE_REQUEST';
export const LOAD_QR_CODE_SUCCESS = 'auth/LOAD_QR_CODE_SUCCESS';
export const LOAD_QR_CODE_FAILURE = 'auth/LOAD_QR_CODE_FAILURE';
export const SET_QR_CODE_REQUEST = 'auth/SET_QR_CODE_REQUEST';
export const SET_QR_CODE_SUCCESS = 'auth/SET_QR_CODE_SUCCESS';
export const SET_QR_CODE_FAILURE = 'auth/SET_QR_CODE_FAILURE';

// Freecode actions
export const UPDATE_FREECODE_TICKET_RUN_BODY = 'freecode/UPDATE_FREECODE_TICKET_RUN_BODY';
export const UPDATE_FREECODE_TICKET_RUN_STATUS = 'freecode/UPDATE_FREECODE_TICKET_RUN_STATUS';
export const UPDATE_FREECODE_TICKET_CODE_DATA = 'freecode/UPDATE_FREECODE_TICKET_CODE_DATA';
export const UPDATE_FREECODE_TICKET_SUBMIT_STATUS = 'freecode/UPDATE_FREECODE_TICKET_SUBMIT_STATUS';
export const UPDATE_FREECODE_TICKET_EXTENDED_DEADLINE = 'freecode/UPDATE_FREECODE_TICKET_EXTENDED_DEADLINE';
export const UPDATE_SPECTATORS_COUNT = 'freecode/UPDATE_SPECTATORS_COUNT';
