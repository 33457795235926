import { ObjectException } from 'global/errors';
import { getConfig } from '../functions/getConfig';
import { getCookie } from '../functions/getCookie';

export default class API {
  http = async (method, url, params) => {
    try {
      const token = this.getToken();
      let response = await fetch(this.getUrl(url), {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: token ? `Bearer ${token}` : undefined,
        },
        body: JSON.stringify(params),
      });

      if (response.status < 200 || response.status > 299) {
        const {
          error: { name: errorId, message },
        } = await response.json();
        throw new ObjectException(message || 'Something went wrong.', errorId || 'UNKNOWN_ERROR');
      }

      try {
        return await response.json();
      } catch (error) {
        return null;
      }
    } catch (error) {
      throw error;
    }
  };

  getUrl = (url) => getConfig().API_URL + url;

  getToken = () => getCookie('fsSession');

  get = (url) => this.http('GET', url);

  post = (url, params) => this.http('POST', url, params);

  patch = (url, params) => this.http('PATCH', url, params);

  put = (url, params) => this.http('PUT', url, params);

  delete = (url) => this.http('DELETE', url);
}
