import styled, { css, createGlobalStyle } from 'styled-components';
import { colors } from 'global/colors';

// Style for npm package react-toastify
const toastifyStyles = css`
  .Toastify__toast--success {
    background: ${colors.success} !important;
    color: ${colors.white} !important;
    --toastify-icon-color-success: ${colors.white};
  }
  .Toastify__toast--error {
    background: ${colors.error} !important;
    color: ${colors.white} !important;
    --toastify-icon-color-error: ${colors.white};
  }
  .Toastify__toast--info {
    background: ${colors.info} !important;
    color: ${colors.white} !important;
    --toastify-icon-color-info: ${colors.white};
  }
  .Toastify__toast--warning {
    background: ${colors.warning} !important;
    color: ${colors.white} !important;
    --toastify-icon-color-warning: ${colors.white};
  }
`;

// Style for npm package react-tooltip
const customTooltipStyles = css`
  --rt-opacity: 0.95;
  .custom-tooltip {
    color: ${colors.white} !important;
    background: ${colors.fsDarkBlue} !important;
    overflow: visible !important;
    z-index: 9999 !important;
    text-align: center;
  }
  .custom-tooltip.place-top:after {
    border-top-color: ${colors.fsDarkBlue} !important;
    border-top-style: solid !important;
    border-top-width: 6px !important;
  }
`;

// Quill editor styles both for editor and to display result correctly
const quillStyles = css`
  .ql-editor {
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    line-height: 2;
  }
  .ql-container {
    font-size: 1em;
  }
  .ql-snow .ql-editor pre.ql-syntax,
  .ql-syntax {
    word-wrap: break-word;
    white-space: pre-wrap;
    color: ${colors.black};
    background: ${colors.lightGray};
    border-radius: 5px;
    padding: 5px;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-align-justify {
    text-align: justify;
  }
`;

// Builder.io styles
const builderIoStyles = css`
  .builder-component {
    width: 100%;
  }
  .builder-text p {
    margin-top: 1rem;
  }
  .builder-text {
    hyphens: auto;
  }
`;

// Scrollbar styling
const scrollStyles = css`
  scrollbar-width: thin !important;
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: ${colors.lightGray13};
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: ${colors.lightGray13};
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.fsDarkBlue};
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${toastifyStyles}
  ${customTooltipStyles}
  ${quillStyles}
  ${builderIoStyles}
  ${scrollStyles}
`;

export const AppMainDiv = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  // & :is(h1, h2, h3, h4, h5, h6) {
  //   color: ${({ theme }) => theme.header};
  // }
  // & :is(p) {
  //   color: ${({ theme }) => theme.text};
  // }
`;
