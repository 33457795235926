import API from '../api';

export default class GDPRService {
  constructor() {
    this.API = new API();
  }
  hasCandidateCompanyConsent = (company) => this.API.get(`/GDPR/hasCompanyCandidateConsent?company=${company}`);

  setGDPR = (company, body) => this.API.post(`/GDPR/${company}/setGDPR`, body);

  deleteCandidateData = (session) => this.API.delete(`/GDPR/${session}`);
}
