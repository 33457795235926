export const GET_LOAD_TICKET_REQUEST = 'MultiChoiceTestPage/GET_LOAD_TICKET_REQUEST';
export const GET_LOAD_TICKET_SUCCESS = 'MultiChoiceTestPage/GET_LOAD_TICKET_SUCCESS';
export const GET_LOAD_TICKET_FAILURE = 'MultiChoiceTestPage/GET_LOAD_TICKET_FAILURE';
export const SET_NON_ADAPTIVE_TEST_INPUT_REQUEST = 'MultiChoiceTestPage/SET_NON_ADAPTIVE_TEST_INPUT_REQUEST';
export const SET_NON_ADAPTIVE_TEST_INPUT_SUCCESS = 'MultiChoiceTestPage/SET_NON_ADAPTIVE_TEST_INPUT_SUCCESS';
export const SET_NON_ADAPTIVE_TEST_INPUT_FAILURE = 'MultiChoiceTestPage/SET_NON_ADAPTIVE_TEST_INPUT_FAILURE';
export const SET_ADAPTIVE_TEST_INPUT_REQUEST = 'MultiChoiceTestPage/SET_ADAPTIVE_TEST_INPUT_REQUEST';
export const SET_ADAPTIVE_TEST_INPUT_SUCCESS = 'MultiChoiceTestPage/SET_ADAPTIVE_TEST_INPUT_SUCCESS';
export const SET_ADAPTIVE_TEST_INPUT_FAILURE = 'MultiChoiceTestPage/SET_ADAPTIVE_TEST_INPUT_FAILURE';
export const SET_CLOSE_TICKET_REQUEST = 'MultiChoiceTestPage/SET_CLOSE_TICKET_REQUEST';
export const SET_CLOSE_TICKET_SUCCESS = 'MultiChoiceTestPage/SET_CLOSE_TICKET_SUCCESS';
export const SET_CLOSE_TICKET_FAILURE = 'MultiChoiceTestPage/SET_CLOSE_TICKET_FAILURE';
export const SET_REPORT_QUESTION_REQUEST = 'MultiChoiceTestPage/SET_REPORT_QUESTION_REQUEST';
export const SET_REPORT_QUESTION_SUCCESS = 'MultiChoiceTestPage/SET_REPORT_QUESTION_SUCCESS';
export const SET_REPORT_QUESTION_FAILURE = 'MultiChoiceTestPage/SET_REPORT_QUESTION_FAILURE';
export const SET_CURRENT_QUESTION_OBJECT = 'MultiChoiceTestPage/SET_CURRENT_QUESTION_OBJECT';
export const SET_CURRENT_OPTION_ID = 'MultiChoiceTestPage/SET_CURRENT_OPTION_ID';
export const SET_CURRENT_TEXT_ANSWER = 'MultiChoiceTestPage/SET_CURRENT_TEXT_ANSWER';
