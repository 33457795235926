import { produce } from 'immer';

import { GET_XPS_REQUEST, GET_XPS_SUCCESS, GET_XPS_FAILURE } from './constants';

const initialState = {
  loading: false,
  error: '',
  success: true,
  XPs: [],
  level: undefined,
  xpInThisLevel: undefined,
  xpForLevel: undefined,
  totalXp: undefined,
};

export default function XPPageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case GET_XPS_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        break;
      case GET_XPS_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.XPs = action.XPs.xpDefinitions;
        state.level = action.XPs.level;
        state.xpInThisLevel = action.XPs.xpInThisLevel;
        state.xpForLevel = action.XPs.xpForLevel;
        state.totalXp = action.XPs.totalXp;
        break;
      case GET_XPS_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        state.XPs = [];
        state.level = undefined;
        state.xpForLevel = undefined;
        state.xpInThisLevel = undefined;
        state.totalXp = undefined;
        break;
      default:
        break;
    }
  });
}
