import React from 'react';
import CurvedContainer from 'components/CurvedContainer/CurvedContainer';
import { Images } from 'assets/images';
import { useAppContext } from 'hooks/useContext';
import { Button } from 'components/Button/RoundButton';
import { SectionHeader, Link } from 'components/FormComponents/FormComponents';
import { Container, Wrapper } from 'components/Layout/Layout';
import * as strings from './strings';
import Path from 'routes/Path';

export default function NotFoundPage() {
  const { language } = useAppContext();

  return (
    <Wrapper>
      <CurvedContainer bottom height={110} image={Images.tanks} />
      <Container column center>
        <SectionHeader bold>404</SectionHeader>
        <SectionHeader>{strings.notFound[language]}</SectionHeader>
        <Link to={Path.landingPage}>
          <Button primary border>
            {strings.goHome[language]}
          </Button>
        </Link>
      </Container>
    </Wrapper>
  );
}
