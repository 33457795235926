import _ from 'underscore';
import { produce } from 'immer';

import {
  LOAD_TESTS_REQUEST,
  LOAD_TESTS_SUCCESS,
  LOAD_TESTS_FAILURE,
  PUBLISH_AND_ARCHIVE_TEST_REQUEST,
  PUBLISH_AND_ARCHIVE_TEST_SUCCESS,
  PUBLISH_AND_ARCHIVE_TEST_FAILURE,
  COPY_TEST_REQUEST,
  COPY_TEST_SUCCESS,
  COPY_TEST_FAILURE,
  CREATE_TEST_REQUEST,
  CREATE_TEST_SUCCESS,
  CREATE_TEST_FAILURE,
  RESET_NEW_AND_COPIED_TEST,
} from './constants';

const initialState = {
  loading: false,
  error: '',
  tests: [],
  publishedOrArchivedTest: {},
  copiedTestId: '',
  newTest: {},
};

export default function CustomTestsPageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case LOAD_TESTS_REQUEST:
        state.loading = true;
        state.error = '';
        state.tests = [];
        break;
      case LOAD_TESTS_SUCCESS:
        state.loading = false;
        state.error = '';
        state.tests = action.data;
        break;
      case LOAD_TESTS_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.tests = [];
        break;
      case PUBLISH_AND_ARCHIVE_TEST_REQUEST:
        state.loading = true;
        state.error = '';
        state.publishedOrArchivedTest = {};
        break;
      case PUBLISH_AND_ARCHIVE_TEST_SUCCESS:
        let index = _.findIndex(state.tests, { _id: action.data._id });
        state.tests[index].custom = action.data.custom;
        state.tests[index].archived = action.data.archived;
        state.tests[index].published = action.data.published ?? false;
        state.loading = false;
        state.error = '';
        state.publishedOrArchivedTest = action.data;
        break;
      case PUBLISH_AND_ARCHIVE_TEST_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.publishedOrArchivedTest = {};
        break;
      case COPY_TEST_REQUEST:
        state.loading = true;
        state.error = '';
        state.copiedTestId = '';
        break;
      case COPY_TEST_SUCCESS:
        state.loading = false;
        state.error = '';
        state.copiedTestId = action.data._id;
        break;
      case COPY_TEST_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.copiedTestId = '';
        break;
      case CREATE_TEST_REQUEST:
        state.loading = true;
        state.error = '';
        state.newTest = {};
        break;
      case CREATE_TEST_SUCCESS:
        state.loading = false;
        state.error = '';
        state.newTest = action.data;
        break;
      case CREATE_TEST_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.newTest = {};
        break;
      case RESET_NEW_AND_COPIED_TEST:
        state.loading = false;
        state.copiedTestId = '';
        state.newTest = {};
        break;
      default:
        break;
    }
  });
}
