import { produce } from 'immer';
import _ from 'underscore';

import {
  GET_COMMENTS_REQUEST,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_FAILURE,
  INIT_POST_COMMENT,
  POST_COMMENT_REQUEST,
  POST_COMMENT_SUCCESS,
  POST_COMMENT_FAILURE,
  INIT_COMMENT_ARCHIVE,
  PUT_ARCHIVE_COMMENT_REQUEST,
  PUT_ARCHIVE_COMMENT_SUCCESS,
  PUT_ARCHIVE_COMMENT_FAILURE,
  INIT_COMMENT_REPORT,
  POST_COMMENT_REPORT_REQUEST,
  POST_COMMENT_REPORT_SUCCESS,
  POST_COMMENT_REPORT_FAILURE,
} from './constants';

const initialState = {
  allComments: {},
  rootCommentIds: {},
  commentsLoading: true,
  responseCommentIds: {},
  responseCommentsLoading: {},
  error: '',
  reportCommentSubmitting: false,
  reportCommentSuccess: false,
  reportCommentError: '',
  archiveCommentSubmitting: false,
  archiveCommentSuccess: false,
  archiveCommentError: '',
  createCommentStatus: {},
  createCommentError: '',
};

export default function commentsReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case GET_COMMENTS_REQUEST:
        state.commentsLoading = true;
        state.error = '';
        break;
      case GET_COMMENTS_SUCCESS:
        state.error = '';
        const groupedComments = _.groupBy(action.comments, (comment) => {
          state.allComments[comment._id] = comment;
          return comment.respondsTo || 'root';
        });
        if (groupedComments.root) {
          state.rootCommentIds[action.contestId || action.testId] = groupedComments['root'].map(
            (comment) => comment._id
          );
          delete groupedComments.root;
          for (const parentId in groupedComments) {
            state.responseCommentIds[parentId] = groupedComments[parentId].map((comment) => comment._id);
          }
        }
        // Set loading false after normalizing data
        state.commentsLoading = false;
        break;
      case GET_COMMENTS_FAILURE:
        state.commentsLoading = false;
        state.error = action.error;
        break;
      case INIT_POST_COMMENT:
        state.createCommentStatus[action.respondsTo ? action.respondsTo : 'root'] = undefined;
        state.createCommentError = '';
        break;
      case POST_COMMENT_REQUEST:
        state.createCommentStatus[action.respondsTo ? action.respondsTo : 'root'] = 'posting';
        state.createCommentError = '';
        break;
      case POST_COMMENT_SUCCESS:
        state.createCommentStatus[action.comment.respondsTo ? action.comment.respondsTo : 'root'] = 'success';
        state.createCommentError = '';
        let comment = action.comment;
        state.allComments[comment._id] = comment;
        if (comment.respondsTo) {
          if (!state.responseCommentIds[comment.respondsTo]) {
            state.responseCommentIds[comment.respondsTo] = [];
          }
          state.responseCommentIds[comment.respondsTo].unshift(comment._id);
          state.allComments[comment.respondsTo].responseCount++;
        } else {
          if (!state.rootCommentIds[comment.contest || comment.test]?.length)
            state.rootCommentIds[comment.contest || comment.test] = [comment._id];
          else state.rootCommentIds[comment.contest || comment.test].unshift(comment._id);
        }
        break;
      case POST_COMMENT_FAILURE:
        state.createCommentStatus[action.respondsTo ? action.respondsTo : 'root'] = 'error';
        state.createCommentError = action.error;
        break;
      case INIT_COMMENT_ARCHIVE:
        state.archiveCommentSubmitting = false;
        state.archiveCommentSuccess = false;
        state.archiveCommentError = '';
        break;
      case PUT_ARCHIVE_COMMENT_REQUEST:
        state.archiveCommentSubmitting = true;
        state.archiveCommentSuccess = false;
        state.archiveCommentError = '';
        break;
      case PUT_ARCHIVE_COMMENT_SUCCESS:
        let archivedComment = action.archivedComment;
        if (!archivedComment.respondsTo) {
          // remove root comment
          state.rootCommentIds[archivedComment.contest || archivedComment.test] = state.rootCommentIds[
            archivedComment.contest || archivedComment.test
          ]?.filter((commentId) => commentId !== archivedComment._id);
        } else {
          // remove child comment
          state.responseCommentIds[archivedComment.respondsTo] = state.responseCommentIds?.[
            archivedComment.respondsTo
          ]?.filter((commentId) => commentId !== archivedComment._id);
          state.allComments[archivedComment.respondsTo].responseCount--;
        }
        delete state.allComments[archivedComment._id];
        state.archiveCommentError = '';
        state.archiveCommentSubmitting = false;
        state.archiveCommentSuccess = true;
        break;
      case PUT_ARCHIVE_COMMENT_FAILURE:
        state.archiveCommentSubmitting = false;
        state.archiveCommentSuccess = false;
        state.archiveCommentError = action.error;
        break;
      case INIT_COMMENT_REPORT:
        state.reportCommentError = '';
        state.reportCommentSubmitting = false;
        state.reportCommentSuccess = false;
        break;
      case POST_COMMENT_REPORT_REQUEST:
        state.reportCommentError = '';
        state.reportCommentSubmitting = true;
        state.reportCommentSuccess = false;
        break;
      case POST_COMMENT_REPORT_SUCCESS:
        state.reportCommentError = '';
        state.reportCommentSubmitting = false;
        state.reportCommentSuccess = true;
        break;
      case POST_COMMENT_REPORT_FAILURE:
        state.reportCommentSubmitting = false;
        state.reportCommentSuccess = false;
        state.reportCommentError = action.error;
        break;
      default:
        break;
    }
  });
}
