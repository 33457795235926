export const LOAD_TICKETS_REQUEST = 'TicketsPage/LOAD_TICKETS_REQUEST';
export const LOAD_TICKETS_SUCCESS = 'TicketsPage/LOAD_TICKETS_SUCCESS';
export const LOAD_TICKETS_FAILURE = 'TicketsPage/LOAD_TICKETS_FAILURE';
export const UPDATE_ADMIN_DEPARTMENT_REQUEST = 'TicketsPage/UPDATE_ADMIN_DEPARTMENT_REQUEST';
export const UPDATE_ADMIN_DEPARTMENT_SUCCESS = 'TicketsPage/UPDATE_ADMIN_DEPARTMENT_SUCCESS';
export const UPDATE_ADMIN_DEPARTMENT_FAILURE = 'TicketsPage/UPDATE_ADMIN_DEPARTMENT_FAILURE';
export const UPDATE_VIEW_ALL_DEPARTMENTS_REQUEST = 'TicketsPage/UPDATE_VIEW_ALL_DEPARTMENTS_REQUEST';
export const UPDATE_VIEW_ALL_DEPARTMENTS_SUCCESS = 'TicketsPage/UPDATE_VIEW_ALL_DEPARTMENTS_SUCCESS';
export const UPDATE_VIEW_ALL_DEPARTMENTS_FAILURE = 'TicketsPage/UPDATE_VIEW_ALL_DEPARTMENTS_FAILURE';
export const ARCHIVE_TICKET_REQUEST = 'TicketsPage/ARCHIVE_TICKET_REQUEST';
export const ARCHIVE_TICKET_SUCCESS = 'TicketsPage/ARCHIVE_TICKET_SUCCESS';
export const ARCHIVE_TICKET_FAILURE = 'TicketsPage/ARCHIVE_TICKET_FAILURE';
export const UPDATE_VIEW_ALL_COMPANIES_REQUEST = 'TicketsPage/UPDATE_VIEW_ALL_COMPANIES_REQUEST';
export const UPDATE_VIEW_ALL_COMPANIES_SUCCESS = 'TicketsPage/UPDATE_VIEW_ALL_COMPANIES_SUCCESS';
export const UPDATE_VIEW_ALL_COMPANIES_FAILURE = 'TicketsPage/UPDATE_VIEW_ALL_COMPANIES_FAILURE';
export const UPDATE_ADMIN_COMPANY_REQUEST = 'TicketsPage/UPDATE_ADMIN_COMPANY_REQUEST';
export const UPDATE_ADMIN_COMPANY_SUCCESS = 'TicketsPage/UPDATE_ADMIN_COMPANY_SUCCESS';
export const UPDATE_ADMIN_COMPANY_FAILURE = 'TicketsPage/UPDATE_ADMIN_COMPANY_FAILURE';
export const RESET_UPDATED_IDS = 'TicketsPage/RESET_UPDATED_IDS';
export const UPDATE_TICKET_STATUS = 'TicketsPage/UPDATE_TICKET_STATUS';
