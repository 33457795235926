import API from '../api';

export default class FreecodeService {
  constructor() {
    this.API = new API();
  }

  loadFreecodeTicketInspect = (inputId) => this.API.get(`/freecode/load/ticketInspect/${inputId}`);

  loadFreecodeCreator = async (testId, testImplId) => {
    const query = !testImplId ? '' : `?testImplId=${testImplId}`;
    return await this.API.get(`/freecode/load/freecodeCreator/${testId}${query}`);
  };

  loadFreecodeTakeExercise = (exerciseId, courseId) => {
    const query = !courseId ? '' : `?courseId=${courseId}`;
    return this.API.get(`/freecode/load/takeExercise/${exerciseId}${query}`);
  };

  loadFreecodeContest = (contestId) => this.API.get(`/freecode/load/contest/${contestId}`);

  loadFreecodeTrialTest = () => this.API.get('/freecode/load/trialTest');

  loadFreecodeTakeTicket = (ticketId) => this.API.get(`/freecode/load/takeTicket/${ticketId}`);

  loadFreecodeLiveTicket = (ticketId) => this.API.get(`/freecode/load/liveTicket/${ticketId}`);
}
