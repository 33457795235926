import React from 'react';
import { SpinnerContainer, SpinnerIcon } from './styles';

export const Spinner = ({ title, text, position, width, height, backgroundOverlay, loading = true }) =>
  loading && (
    <SpinnerContainer position={position} width={width} height={height} backgroundOverlay={backgroundOverlay}>
      <SpinnerIcon />
      {title && <label>{title}</label>}
      {text && <p>{text}</p>}
    </SpinnerContainer>
  );

export const AbsoluteSpinner = ({ width, height, backgroundOverlay, loading }) => (
  <Spinner position="absolute" width={width} height={height} backgroundOverlay={backgroundOverlay} loading={loading} />
);

export const SpinnerWrapper = ({ title, text, position, width, height, loading, children }) =>
  !loading ? children : <Spinner title={title} text={text} position={position} width={width} height={height} />;
