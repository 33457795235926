/**
 * Call func after delay. If the function is called again reset the timeout
 *
 * @param {*} func function to call
 * @param {*} delay delay until function is called
 * @returns
 */
export function debounce(func, delay) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      func.apply(this, arguments);
    }, delay);
  };
}
