import _ from 'underscore';

import { objectIdRegex } from 'constants/regex';

// Returns true if id is a valid ObjectID, false otherwise
export const isValidObjectID = (id) => String(id).match(objectIdRegex);

// Returns true if id is NOT a valid ObjectID, false otherwise
export const isNotValidObjectID = (id) => !isValidObjectID(id);

// Returns true if email is valid, false otherwise
export const isValidEmail = (email) => {
  // First check format and extract the  different parts
  const basicRegex = /^(.{1,64})@(.{1,253})\.(.{2,64})$/;
  const basicMatch = String(email).match(basicRegex);
  if (basicMatch?.length !== 4) return false;
  const [_ignore, local, domain, topDomain] = basicMatch; //eslint-disable-line

  // top domain has to be a letter followed by letters and numbers
  if (!/^[A-Za-z][A-Za-z\d]+$/.test(topDomain)) return false;

  // Only allow certain characters A-Z a-z 0-9 . -
  if (!/^[A-Za-z\d\.-]+$/.test(domain)) return false; //eslint-disable-line
  // Check so all dots and dashes are in allowed places
  if (/^-|^\.|-$|\.$|\.\./.test(domain)) return false;

  // If local is in quotation marks pretty much anything is allowed
  // if (/^".+"$/.test(local)) return true;

  // Only allow certain characters A-Z a-z 0-9 !#$%&'*+-/=?^_`{|}~ .
  if (!/^[A-Za-z\d!#$%&'*\+\-/=?^_`{|}~\.]+$/.test(local)) return false; //eslint-disable-line
  // Check so all dots are in allowed places
  if (/^\.|\.$|\.\./.test(local)) return false;

  return true;
};

// Check if password conatin some keyword that is not allowed
export const passwordHasKeyword = (password, email, name, communityName) => {
  let emailKeywords = email.replace(/\.[A-Za-z\d]+$/, '').split('@');
  let invalidKeywords = _.union(emailKeywords, (name || '').split(/\s+/), (communityName || '').split(/\s+/));
  return invalidKeywords.some((keyword) => {
    return !keyword.match(/^\s*$/g) && password.toLowerCase().includes(keyword.toLowerCase());
  });
};
