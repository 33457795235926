import React from 'react';
import PropTypes from 'prop-types';

import {
  CollapseContainer,
  CollapseHeader,
  CollapseBody,
  ActionIcon,
  Description,
  HeadingTitle,
  TitleAndActionContainer,
} from './styles';

const Collapse = ({
  id,
  isCollapsed,
  title,
  description,
  action,
  children,
  isCollapsable = true,
  isHighlighted = false,
}) => {
  return (
    <CollapseContainer id={id} className={!isCollapsed && 'active'} highlighted={isHighlighted ? 'true' : undefined}>
      <CollapseHeader
        className={!isCollapsed && 'active'}
        collapsable={isCollapsable ? 'true' : undefined}
        onClick={() => isCollapsable && action(id)}
      >
        <TitleAndActionContainer>
          <HeadingTitle>{title}</HeadingTitle>
          {isCollapsable && (
            <ActionIcon className={!isCollapsed && 'active'}>
              {isCollapsed ? <i className="fa fa-plus" /> : <i className="fa fa-minus" />}
            </ActionIcon>
          )}
        </TitleAndActionContainer>
        <Description>{description}</Description>
      </CollapseHeader>
      {!isCollapsed && <CollapseBody>{children}</CollapseBody>}
    </CollapseContainer>
  );
};

Collapse.propTypes = {
  id: PropTypes.number,
  isCollapsed: PropTypes.bool,
  action: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

Collapse.defaultProps = {
  id: 0,
  isCollapsed: true,
  action: () => console.log('Action not defined'),
  children: 'No Content!!',
};

export default Collapse;
