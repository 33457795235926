import { produce } from 'immer';
import _ from 'underscore';

import {
  LOAD_TICKETS_REQUEST,
  LOAD_TICKETS_SUCCESS,
  LOAD_TICKETS_FAILURE,
  UPDATE_ADMIN_DEPARTMENT_REQUEST,
  UPDATE_ADMIN_DEPARTMENT_SUCCESS,
  UPDATE_ADMIN_DEPARTMENT_FAILURE,
  UPDATE_VIEW_ALL_DEPARTMENTS_REQUEST,
  UPDATE_VIEW_ALL_DEPARTMENTS_SUCCESS,
  UPDATE_VIEW_ALL_DEPARTMENTS_FAILURE,
  ARCHIVE_TICKET_REQUEST,
  ARCHIVE_TICKET_SUCCESS,
  ARCHIVE_TICKET_FAILURE,
  UPDATE_VIEW_ALL_COMPANIES_REQUEST,
  UPDATE_VIEW_ALL_COMPANIES_SUCCESS,
  UPDATE_VIEW_ALL_COMPANIES_FAILURE,
  UPDATE_ADMIN_COMPANY_REQUEST,
  UPDATE_ADMIN_COMPANY_SUCCESS,
  UPDATE_ADMIN_COMPANY_FAILURE,
  RESET_UPDATED_IDS,
  UPDATE_TICKET_STATUS,
} from './constants';

const initialState = {
  loading: false,
  error: '',
  tickets: [],
  hasCandidateFeedbacks: false,
  departments: [],
  companies: [],
  candidates: [],
  isDepartmentOptionUpdated: false,
  isCompanyOptionUpdated: false,
  testsAvailableForCompany: [],
  archivedTicketId: undefined,
};

export default function TicketsPageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case LOAD_TICKETS_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case LOAD_TICKETS_SUCCESS:
        state.loading = false;
        state.error = '';
        state.hasCandidateFeedbacks = action.data.hasCandidateFeedbacks;
        state.departments = action.data.departments;
        state.companies = action.data.companies;
        state.tickets = action.data.tickets;
        state.testsAvailableForCompany = action.data.testsAvailableForCompany;
        break;
      case LOAD_TICKETS_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case UPDATE_ADMIN_DEPARTMENT_REQUEST:
        state.loading = true;
        state.error = '';
        state.isDepartmentOptionUpdated = false;
        break;
      case UPDATE_ADMIN_DEPARTMENT_SUCCESS:
        state.loading = false;
        state.error = '';
        state.isDepartmentOptionUpdated = true;
        state.tickets = [];
        break;
      case UPDATE_ADMIN_DEPARTMENT_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case UPDATE_VIEW_ALL_DEPARTMENTS_REQUEST:
        state.loading = true;
        state.error = '';
        state.isDepartmentOptionUpdated = false;
        break;
      case UPDATE_VIEW_ALL_DEPARTMENTS_SUCCESS:
        state.loading = false;
        state.error = '';
        state.isDepartmentOptionUpdated = true;
        state.tickets = [];
        break;
      case UPDATE_VIEW_ALL_DEPARTMENTS_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case ARCHIVE_TICKET_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case ARCHIVE_TICKET_SUCCESS:
        state.loading = false;
        state.error = '';
        state.tickets = state.tickets.filter((ticket) => ticket._id !== action.data._id);
        state.archivedTicketId = action.data._id;
        break;
      case ARCHIVE_TICKET_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case UPDATE_VIEW_ALL_COMPANIES_REQUEST:
        state.loading = true;
        state.error = '';
        state.isCompanyOptionUpdated = false;
        break;
      case UPDATE_VIEW_ALL_COMPANIES_SUCCESS:
        state.loading = false;
        state.error = '';
        state.isCompanyOptionUpdated = true;
        state.tickets = [];
        break;
      case UPDATE_VIEW_ALL_COMPANIES_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case UPDATE_ADMIN_COMPANY_REQUEST:
        state.loading = false;
        state.error = '';
        state.isCompanyOptionUpdated = false;
        state.tickets = [];
        break;
      case UPDATE_ADMIN_COMPANY_SUCCESS:
        state.loading = false;
        state.error = action.error;
        state.isCompanyOptionUpdated = true;
        break;
      case UPDATE_ADMIN_COMPANY_FAILURE:
        state.loading = true;
        state.error = '';
        break;
      case RESET_UPDATED_IDS:
        state.archivedTicketId = undefined;
        break;
      case UPDATE_TICKET_STATUS:
        let oldTickets = JSON.parse(JSON.stringify(state.tickets));
        oldTickets = replaceTicket(oldTickets, action.payload);
        state.tickets = oldTickets;
        break;
      default:
        break;
    }
  });
}

function replaceTicket(listOfTickets, replacementTicket) {
  let isTicketPresent = false;
  let newListOfTickets = _.reduce(
    listOfTickets ?? [],
    function (memo, current) {
      if (String(current._id) === String(replacementTicket._id)) {
        isTicketPresent = true;
        if (replacementTicket.archived) return memo;
        memo.push({
          ...current,
          ...replacementTicket,
        });
      } else {
        memo.push(current);
      }
      return memo;
    },
    []
  );
  if (!isTicketPresent) newListOfTickets.push(replacementTicket);
  return newListOfTickets;
}
