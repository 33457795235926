import { produce } from 'immer';
import _ from 'underscore';

import {
  LOAD_SETTINGS_REQUEST,
  LOAD_SETTINGS_SUCCESS,
  LOAD_SETTINGS_FAILURE,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILURE,
  UPDATE_DEPARTMENT_REQUEST,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_FAILURE,
  CREATE_DEPARTMENT_REQUEST,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAILURE,
  UPDATE_EMAIL_REQUEST,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILURE,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  INVITE_USER_FAILURE,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  UPDATE_NAME_REQUEST,
  UPDATE_NAME_SUCCESS,
  UPDATE_NAME_FAILURE,
  UPDATE_USER_ROLE_REQUEST,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_FAILURE,
  UPDATE_USER_DEPARTMENT_REQUEST,
  UPDATE_USER_DEPARTMENT_SUCCESS,
  UPDATE_USER_DEPARTMENT_FAILURE,
  DELETE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAILURE,
  PUBLISH_AGREEMENT_REQUEST,
  PUBLISH_AGREEMENT_SUCCESS,
  PUBLISH_AGREEMENT_FAILURE,
  UPDATE_LEVER_USER_REQUEST,
  UPDATE_LEVER_USER_SUCCESS,
  UPDATE_LEVER_USER_FAILURE,
  CREATE_TT_USER_REQUEST,
  CREATE_TT_USER_SUCCESS,
  CREATE_TT_USER_FAILURE,
  RESET_UPDATED_IDS,
} from './constants';

const initialState = {
  loading: false,
  fetchSettings: false,
  error: '',
  success: false,
  settings: {},
  updateCompanySuccess: false,
  updatedDepartmentId: undefined,
  newDepartmentId: undefined,
  isEmailUpdated: false,
  isDepartmentDeleted: false,
  TTUserId: undefined,
  leverUserId: undefined,
  agreementId: undefined,
  invitedUserId: undefined,
  updatedUserId: undefined,
  deletedUserId: undefined,
};

export default function SettingsPageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    let index;
    switch (action.type) {
      case LOAD_SETTINGS_REQUEST:
        state.fetchSettings = true;
        state.error = '';
        state.success = false;
        state.settings = {};
        break;
      case LOAD_SETTINGS_SUCCESS:
        state.fetchSettings = false;
        state.error = '';
        state.success = true;
        state.settings = action.data;
        break;
      case LOAD_SETTINGS_FAILURE:
        state.fetchSettings = false;
        state.error = action.error;
        state.success = false;
        break;
      case UPDATE_COMPANY_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.updateCompanySuccess = false;
        break;
      case UPDATE_COMPANY_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        if (action?.data?.VATInformation?.vatId) {
          state.settings.company.VATInformation = action.data.VATInformation;
        }
        state.updateCompanySuccess = true;
        break;
      case UPDATE_COMPANY_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case UPDATE_DEPARTMENT_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.updatedDepartmentId = undefined;
        break;
      case UPDATE_DEPARTMENT_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.updatedDepartmentId = action.data._id;
        break;
      case UPDATE_DEPARTMENT_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case CREATE_DEPARTMENT_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.newDepartmentId = undefined;
        break;
      case CREATE_DEPARTMENT_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.newDepartmentId = action.data._id;
        state.settings.departments.push(action.data);
        break;
      case CREATE_DEPARTMENT_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case UPDATE_EMAIL_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.isEmailUpdated = false;
        break;
      case UPDATE_EMAIL_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.isEmailUpdated = true;
        break;
      case UPDATE_EMAIL_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case INVITE_USER_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.invitedUserId = undefined;
        break;
      case INVITE_USER_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.invitedUserId = action.data._id;
        state.settings.teamMembers.push(action.data);
        break;
      case INVITE_USER_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case DELETE_ACCOUNT_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.deletedUserId = undefined;
        break;
      case DELETE_ACCOUNT_SUCCESS:
        state.settings.teamMembers = state.settings.teamMembers.filter((user) => user._id !== action.data);
        state.deletedUserId = action.data;
        state.loading = false;
        state.error = '';
        state.success = true;
        break;
      case DELETE_ACCOUNT_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case UPDATE_NAME_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.updatedUserId = undefined;
        break;
      case UPDATE_NAME_SUCCESS:
        index = _.findIndex(state.settings.teamMembers, (user) => user._id === action.data._id);
        state.settings.teamMembers[index].name = action.data.name;
        state.updatedUserId = action.data._id;
        state.loading = false;
        state.error = '';
        state.success = true;
        break;
      case UPDATE_NAME_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case UPDATE_USER_ROLE_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.updatedUserId = undefined;
        break;
      case UPDATE_USER_ROLE_SUCCESS:
        index = _.findIndex(state.settings.teamMembers, (user) => user._id === action.data._id);
        state.settings.teamMembers[index].role = action.data.role;
        if (action.data.departmentName) {
          state.settings.teamMembers[index].departmentName = action.data.departmentName;
        }
        state.updatedUserId = action.data._id;
        state.loading = false;
        state.error = '';
        state.success = true;
        break;
      case UPDATE_USER_ROLE_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case UPDATE_USER_DEPARTMENT_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.updatedUserId = undefined;
        break;
      case UPDATE_USER_DEPARTMENT_SUCCESS:
        index = _.findIndex(state.settings.teamMembers, (member) => member._id === action.data._id);
        state.settings.teamMembers[index].department = action.data.department;
        state.settings.teamMembers[index].departmentName = action.data.departmentName;
        state.updatedUserId = action.data._id;
        state.loading = false;
        state.error = '';
        state.success = true;
        break;
      case UPDATE_USER_DEPARTMENT_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case DELETE_DEPARTMENT_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.isDepartmentDeleted = false;
        break;
      case DELETE_DEPARTMENT_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.isDepartmentDeleted = true;
        break;
      case DELETE_DEPARTMENT_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case PUBLISH_AGREEMENT_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.agreementId = undefined;
        break;
      case PUBLISH_AGREEMENT_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.agreementId = action.data._id;
        break;
      case PUBLISH_AGREEMENT_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case UPDATE_LEVER_USER_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.leverUserId = undefined;
        break;
      case UPDATE_LEVER_USER_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.leverUserId = action.data.user._id;
        let isUserExists = _.findWhere(state.settings.teamMembers, { _id: action.data.user._id });
        if (!isUserExists) state.settings.teamMembers.push(action.data.user);
        state.settings.lever.integrationToken = action.data.user.integrationSettings.integrationToken;
        state.settings.lever.signatureToken = action.data.user.integrationSettings.signatureToken;
        state.settings.lever.triggerStage = action.data.user.integrationSettings.triggerStage;
        state.settings.lever.webhookUrl = action.data.user.integrationSettings.webhookUrl;
        state.settings.lever.stages = action.data.stages || [];
        break;
      case UPDATE_LEVER_USER_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case CREATE_TT_USER_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.TTUserId = undefined;
        break;
      case CREATE_TT_USER_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.TTUserId = action.data._id;
        state.settings.ttUserToken = action.data.integrationSettings.integrationToken;
        state.settings.teamMembers.push(action.data);
        break;
      case CREATE_TT_USER_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case RESET_UPDATED_IDS:
        state.newDepartmentId = undefined;
        state.updatedDepartmentId = undefined;
        state.invitedUserId = undefined;
        state.updatedUserId = undefined;
        state.deletedUserId = undefined;
        state.updateCompanySuccess = false;
        state.isEmailUpdated = false;
        state.agreementId = undefined;
        state.TTUserId = undefined;
        state.leverUserId = undefined;
        break;
      default:
        break;
    }
  });
}
