import { history } from 'global/configureStore';
import services from 'api/services';
import { userRoles } from 'constants/users';
import { ObjectException, logError } from 'global/errors';
import { getConfig } from 'functions/getConfig';
import { isValidEmail } from 'functions/stringValidation';
import { getCookie } from 'functions/getCookie';
import {
  checkHasFlowProgressInLocalStorage,
  saveFlowProgressInDB,
} from 'functions/flowProgressInLocalStorageFunctions';

import {
  AUTH_INITIALIZE,
  AUTH_INITIALIZE_DONE,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_TWO_FACTOR,
  AUTH_CLEAR_DATA,
  AUTH_CLEAR_ERROR,
  AUTH_TWO_FACTOR_TIMEOUT,
  AUTH_TWO_FACTOR_TIMEOUT_ERROR,
  LOAD_QR_CODE_REQUEST,
  LOAD_QR_CODE_SUCCESS,
  LOAD_QR_CODE_FAILURE,
  SET_QR_CODE_REQUEST,
  SET_QR_CODE_SUCCESS,
  SET_QR_CODE_FAILURE,
} from '../constants';

const { AuthService } = services;

export const clearAuthError = () => (dispatch) => dispatch({ type: AUTH_CLEAR_ERROR });

export const initialize = (preventDataClear) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_INITIALIZE });
    let session = getCookie('fsSession');
    if (window.location.href.includes('/session')) {
      session = window.location.pathname.split('/')?.[2];
      setSession(session);
    }
    if (session) {
      const response = await AuthService.getAccount(session);
      if (response?.user?._id) {
        return dispatch({ type: AUTH_LOGIN_SUCCESS, response });
      }
    }
    if (!preventDataClear) {
      clearLocalAuthInformation();
      dispatch({ type: AUTH_CLEAR_DATA });
    }
  } catch (e) {
    clearLocalAuthInformation();
    dispatch({ type: AUTH_CLEAR_DATA });
  } finally {
    dispatch({ type: AUTH_INITIALIZE_DONE });
  }
};

export const verifyAuthState = (token) => async (dispatch) => {
  try {
    const session = getCookie('fsSession');
    if (session && token === session) {
      return;
    }
    if (token) {
      dispatch({ type: AUTH_CLEAR_DATA });
    }
    if (session) {
      const response = await AuthService.getAccount(session);
      if (response?.user?._id) {
        return dispatch({ type: AUTH_LOGIN_SUCCESS, response });
      }
      localStorage.clear();
    }
    clearLocalAuthInformation();
    dispatch({ type: AUTH_CLEAR_DATA });
  } catch (e) {
    clearLocalAuthInformation();
    dispatch({ type: AUTH_CLEAR_DATA });
  }
};

export const login = (email, password, preventRedirect) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_LOGIN_REQUEST });
    if (!isValidEmail(email)) {
      throw new ObjectException(
        {
          de: 'Email is not valid.',
          en: 'Email is not valid.',
          sv: 'Emailen är inte giltig.',
        },
        'INVALID_EMAIL'
      );
    }

    const authResponse = await AuthService.login(email, password);
    if (!authResponse) return dispatch({ type: AUTH_LOGIN_FAILURE, error: 'No response from server' });

    if (authResponse.message === 'TWO_FACTOR_AUTH_NEEDED') {
      // Use cookie for twoFactorTempSession to set low max-age
      document.cookie = `twoFactorTempSession=${authResponse.tempSession}; Domain=${
        getConfig().COOKIE
      }; Max-Age=180; Path=/; SameSite=Lax`;
      return dispatch({ type: AUTH_TWO_FACTOR });
    }
    if (!authResponse?.user?.token) return dispatch({ type: AUTH_LOGIN_FAILURE, error: 'Login failed' });
    const {
      user: { token, role, membershipAgreementAgreed },
    } = authResponse;

    setSession(token);
    dispatch({ type: AUTH_LOGIN_SUCCESS, response: authResponse });

    if (checkHasFlowProgressInLocalStorage()) saveFlowProgressInDB();

    if (preventRedirect) return;

    if (membershipAgreementAgreed && role === userRoles.CANDIDATE) {
      setTimeout(() => {
        history.push('/challenges');
      }, 0);
    } else if (role === userRoles.CANDIDATE) {
      history.push('/tickets');
    } else {
      history.push('/tests');
    }
  } catch (ex) {
    logError(ex);
    dispatch({ type: AUTH_LOGIN_FAILURE, error: ex.message });
  }
};

export const loginTwoFactor = (code, preventRedirect) => async (dispatch) => {
  try {
    const tempSession = getCookie('twoFactorTempSession');
    if (!tempSession) {
      return dispatch({
        type: AUTH_TWO_FACTOR_TIMEOUT,
        error: AUTH_TWO_FACTOR_TIMEOUT_ERROR,
      });
    }
    const response = await AuthService.twoFactorLogin(code, tempSession);
    if (response?.user?.token) {
      setSession(response.user.token);

      dispatch({ type: AUTH_LOGIN_SUCCESS, response });

      if (preventRedirect) return;

      history.push('/tests');
    } else {
      dispatch({ type: AUTH_LOGIN_FAILURE, error: response.message });
    }
  } catch (ex) {
    logError(ex);
    dispatch({ type: AUTH_LOGIN_FAILURE, error: ex.message });
  }
};

export const logout = () => async (dispatch) => {
  try {
    await AuthService.logout();
  } finally {
    localStorage.clear();
    clearLocalAuthInformation();
    dispatch({ type: AUTH_CLEAR_DATA });
  }
};

const setSession = (session) => {
  //max-age is a week
  document.cookie = `fsSession=${session}; Domain=${getConfig().COOKIE}; Max-Age=604800; Path=/; SameSite=Lax`;
};

export const clearAuthState = () => async (dispatch) => {
  clearLocalAuthInformation();
  dispatch({ type: AUTH_CLEAR_DATA });
};

const clearLocalAuthInformation = () => {
  document.cookie = `fsRole=; Domain=${getConfig().COOKIE}; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/`;
  document.cookie = `fsSession=; Domain=${getConfig().COOKIE}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  document.cookie = `twoFactorTempSession=; Domain=${
    getConfig().COOKIE
  }; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const loadQRCode = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_QR_CODE_REQUEST });
    let response = await AuthService.loadQRCode();
    dispatch({ type: LOAD_QR_CODE_SUCCESS, data: response });
  } catch (ex) {
    logError(ex);
    dispatch({ type: LOAD_QR_CODE_FAILURE, error: ex.message });
  }
};

export const setQRCode = (code) => async (dispatch) => {
  try {
    dispatch({ type: SET_QR_CODE_REQUEST });
    let response = await AuthService.setQRCode(code);
    dispatch({ type: SET_QR_CODE_SUCCESS, data: response });
  } catch (ex) {
    logError(ex);
    dispatch({ type: SET_QR_CODE_FAILURE, error: ex.message });
  }
};
