import API from '../api';

export default class CourseService {
  constructor() {
    this.API = new API();
  }
  getAllCourses = (isKodcentrumCampaign) => this.API.get(`/course?isKodcentrumCampaign=${isKodcentrumCampaign}`);

  getCourse = (id) => this.API.get(`/course/${id}`);
}
