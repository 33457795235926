import API from '../api';

export default class CompanyService {
  constructor() {
    this.API = new API();
  }

  createCompany = (payload) => this.API.post('/company', payload);

  getSuperAdminConfig = () => this.API.get('/config/superadmin');

  getSuperAdminCompanies = () => this.API.get('/company/allActiveCompanies');

  updateCompany = (companyId, payload) => this.API.put(`/company/${companyId}`, payload);

  loadCompanySettings = () => this.API.get('/company/companySettings');

  loadCompanySendingTestSettings = () => this.API.get('/company/companySendingTestSettings');

  activatePremiumTestsTrial = () => this.API.post('/company/activatePremiumTestsTrial');
}
