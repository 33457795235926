/**
 * This file should contain color codes used for Future Skill
 */

/** Primary dark blue #295C88, used for buttons and headers */
export const fsDarkBlue = '#295C88';

export const fsDarkBlueOffset = '#1d4161';
export const fsDarkBlueHoverLight = '#448ac7';
export const fsDarkBlueDisabled = '#7f9bb3';

/** Primary light blue #EDF3F7, used for light backgrounds and highlights*/
export const fsLightBlue = '#EDF3F7';

export const fsLightBlueHover = '#F5F7FA';

/** Primary cyan #00B8B8 used for buttons and headers */
export const fsCyan = '#00B8B8';

/** check and radio button selected color for use in light mode */
export const checkmark = '#2196f3';

// For toasts and info text
export const success = fsDarkBlue;
export const warning = '#ffaa15';
export const error = '#ff4040';
export const info = '#00b8e6';

/**
 * Color comments for displaying the color value when inserting a color
 */
export const colors = {
  /** #ffffff */
  white: '#ffffff',
  /** rgba(255, 255, 255, 0.5) */
  whiteTransparent: 'rgba(255, 255, 255, 0.5)',
  /** rgba(255, 255, 255, 0.9) */
  whiteTranslucent: 'rgba(255, 255, 255, 0.9)',
  /** #ffffff */
  white2: '#F2F2F2',
  /** #000000 */
  black: '#000000',
  /** fsLightBlue */
  fsLightBlue,
  /** fsLightBlueHover */
  fsLightBlueHover,
  /** fsDarkBlue */
  fsDarkBlue,
  /** fsDarkBlueHoverDark */
  fsDarkBlueOffset,
  /** fsDarkBlueHoverLight */
  fsDarkBlueHoverLight,
  /** fsDarkBlueDisabled */
  fsDarkBlueDisabled,
  /** fsCyan */
  fsCyan,
  /** #ebf9f9 */
  cyan2: '#ebf9f9',
  /** checkmark */
  checkmark,
  /** success */
  success,
  /** warning */
  warning,
  /** error */
  error,
  /** info */
  info,
  /** #BEBEC1 */
  gray: '#BEBEC1',
  /** #a1a1a1 */
  gray2: '#a1a1a1',
  /** #dee2e6 */
  gray3: '#dee2e6',
  /** #e5e5e5 */
  gray4: '#e5e5e5',
  /** c9d4e1 */
  gray5: '#c9d4e1',
  /** c2c9d0 */
  gray6: '#c2c9d0',
  /** #efefef */
  lightGray: '#efefef',
  /** #999999 */
  lightGray1: '#999999',
  /** #888888 */
  lightGray2: '#888888',
  /** #F5F5F5 */
  lightGray3: '#F5F5F5',
  /** #A2A4A8 */
  lightGray4: '#A2A4A8',
  /** #f1f2f3 */
  lightGray5: '#f1f2f3',
  /** #e3e8f0 */
  lightGray6: '#e3e8f0',
  /** #828891 */
  lightGray7: '#828891',
  /** #B4C4CA */
  lightGray8: '#B4C4CA',
  /** #D9E3EC */
  lightGray9: '#D9E3EC',
  /** #F7F8FB */
  lightGray10: '#F7F8FB',
  /** #DFE4EA */
  lightGray11: '#DFE4EA',
  /** #bdc7d4 */
  lightGray12: '#bdc7d4',
  /** #EEF2FA */
  lightGray13: '#EEF2FA',
  /** #f6f7f9 */
  lightGray14: '#f6f7f9',
  /** #F5F7F9 */
  lightGray15: '#F5F7F9',
  /** #555555 */
  darkGray: '#555555',
  /** #222222 */
  darkGray2: '#222222',
  /** 4d5265 */
  darkGray3: '#4d5265',
  /** 2f2f2f */
  darkGray4: '#2f2f2f',
  /** 171b22 */
  darkGray5: '#171b22',
  /** 171b22 */
  darkGray6: '#171B2280',
  /** #28323E */
  darkGray7: '#28323E',
  /** #171b22e6 */
  darkGray8: '#171b22e6',
  /** #14191f */
  darkGray9: '#14191f',
  /** #37393C */
  darkGray10: '#37393C',
  /** #64686F */
  darkGray11: '#64686F',
  /** #82889140 */
  darkGray12: '#82889140',
  /** #383a3c */
  darkGray13: '#383a3c',
  /** #3A4556 */
  darkGray14: '#3A4556',
  /** #445056 */
  darkGray15: '#445056',
  /** #4b566c */
  darkGray16: '#4b566c',
  /** #27313e */
  darkGray17: '#27313e',
  /** #465163 */
  darkGray18: '#465163',
  /** #dee2e6 */
  borderGray: '#dee2e6',
  /** #373a3c */
  darkBlue2: '#373a3c',
  /** #27323E */
  darkBlue3: '#27323E',
  /** #ba2a2f */
  darkRed: '#ba2a2f',
  /** #c65853 */
  red: '#c65853',
  /** #fbe2ee */
  red2: '#fbe2ee',
  /** #7ec666 */
  green2: '#7ec666',
  /** #189c00 */
  green3: '#189c00',
  /** #addb9e */
  hoverGreen: '#addb9e',
  /** #f0c700 */
  gold: '#f0c700',
  /** #707070 */
  silver: '#707070',
  /** #d68100 */
  bronze: '#d68100',
  /** #36e0b7 */
  lightGreen: '#36e0b7',
  /** #fdbd4c */
  yellow: '#fdbd4c',
  /** #fdbd4c */
  yellow2: '#f9e198',
  /** #4D462A */
  yellow3: '#4D462A',
  /** #FC9F19 */
  yellow4: '#FC9F19',
  /** #f07a1f */
  orange: '#f07a1f',
  /** #ed4102 */
  darkOrange: '#ed4102',
  /** #f1c40f80 */
  yellowTransparent: '#f1c40f80',
  /** #e4edf3 */
  lightBlue2: '#e4edf3',
  /** #d9edf7 */
  lightBlue3: '#d9edf7',
  /** #31708f */
  lightBlue4: '#31708f',
  /** #bcdff1 */
  lightBlue5: '#bcdff1',
  /** #7f9bb3 */
  lightBlue6: '#7f9bb3',
  /** #3265B0 */
  lightBlue7: '#3265B0',
  /** #3E84EB */
  lightBlue8: '#3E84EB',
  /** #ECF9F9 */
  lightBlue9: '#ECF9F9',
  /** #cce7e6 */
  lightBlue10: '#cce7e6',
  /** #3A4556 */
  lightBlue11: '#3A4556',
  /** #60a1ff */
  lightBlue12: '#60a1ff',
  /** #4a576b */
  lightBlue13: '#4a576b',
  /** #5b97ee */
  lightBlue14: '#5b97ee',
  /** #305d9f */
  lightBlue15: '#305d9f',
  /** #fcf8e3 */
  lightYellow: '#fcf8e3',
  /** #faf2cc */
  lightYellow2: '#faf2cc',
  /** #FFF5E6 */
  lightYellow3: '#FFF5E6',
  /** #8a6d3b */
  lightBrown: '#8a6d3b',
  /** #f2dede */
  lightRed: '#f2dede',
  /** #ebcccc */
  lightRed2: '#ebcccc',
  /** #a94442 */
  lightRed3: '#a94442',
  /** #EF4423 */
  lightRed4: '#EF4423',
  /** #ff000075 */
  lightRed5: '#ff000075',
};
