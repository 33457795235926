import styled, { css } from 'styled-components';

import { colors } from 'global/colors';

export const Header = styled.h1`
  color: ${colors.fsDarkBlue};
  font-weight: 600;
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  background: ${({ color }) => color || colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${(props) => (props.padding ? props.padding : 0)};
  min-height: ${(props) => (props.minHeight ? props.minHeight : 'calc(100% - 320px)')};

  :after {
    content: '';
    flex: auto;
  }

  @media print {
    padding: 0;
  }

  ${({ isNewPage }) =>
    isNewPage &&
    css`
      background: ${({ theme }) => theme.pageBackground};
    `}
`;

export const Container = styled.div`
  width: 80%;
  max-width: 1200px;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1366px) {
    width: 95%;
  }

  ${(props) =>
    props.color &&
    css`
      background: ${props.color};
      border-radius: 10px;
      padding: 20px;
      margin-top: 50px;
      margin-bottom: 50px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    `}

  ${(props) =>
    props.half &&
    css`
      width: 50%;
    `}

  ${(props) =>
    props.wide &&
    css`
      @media (max-width: 1024px) {
        flex-direction: column;
      }
    `}

  @media (max-width: 667px) {
    flex-direction: column;
  }

  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
    `}

  ${(props) =>
    props.full &&
    css`
      max-width: none;
      width: 100% !important;
    `}

  ${(props) =>
    props.center &&
    css`
      justify-content: center;
    `}
  
  ${(props) =>
    props.column &&
    props.center &&
    css`
      align-items: center;
    `}

  ${(props) =>
    props.padding &&
    css`
      padding: 80px 0;

      @media (max-width: 1024px) {
        padding: 30px 0;
      }
    `}

    ${(props) =>
    props.usePadding &&
    css`
      padding: ${props.usePadding};
    `}

    ${(props) =>
    props.useZIndex &&
    css`
      z-index: ${props.useZIndex};
    `}
`;
