import { produce } from 'immer';

import { LOAD_STATISTICS_REQUEST, LOAD_STATISTICS_SUCCESS, LOAD_STATISTICS_FAILURE } from './constants';

const initialState = {
  loading: false,
  error: '',
  statistics: [],
};

export default function StatisticsPageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case LOAD_STATISTICS_REQUEST:
        state.loading = true;
        state.error = '';
        state.statistics = [];
        break;
      case LOAD_STATISTICS_SUCCESS:
        state.loading = false;
        state.error = '';
        state.statistics = action.data;
        break;
      case LOAD_STATISTICS_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.statistics = [];
        break;
      default:
        break;
    }
  });
}
