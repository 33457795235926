import { produce } from 'immer';

import { SET_UNSUBSCRIBE_REQUEST, SET_UNSUBSCRIBE_SUCCESS, SET_UNSUBSCRIBE_FAILURE } from './constants';

const initialState = {
  loading: false,
  error: '',
  isUnsubscribed: false,
};

export default function UnsubscribePageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case SET_UNSUBSCRIBE_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case SET_UNSUBSCRIBE_SUCCESS:
        state.loading = false;
        state.error = '';
        state.isUnsubscribed = action.data;
        break;
      case SET_UNSUBSCRIBE_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      default:
        break;
    }
  });
}
