import React from 'react';
import { useSelector } from 'react-redux';
import {
  FooterWrapper,
  FooterContainer,
  Logo,
  FooterSection,
  SectionHeader,
  SectionText,
  Icon,
  CopyText,
  Link,
  SectionExtLink,
  NavigationLinksContainer,
  SocialLinksContainer,
  SocialLink,
} from './styles';
import { userRoles } from 'constants/users';
import { useAppContext } from 'hooks/useContext';
import { getConfig } from 'functions/getConfig';
import * as strings from './strings';
import Path from 'routes/Path';

export default function Footer({ isCompanyRoute }) {
  const { userRole } = useSelector((state) => state.auth);

  const { language, isDarkMode } = useAppContext();

  let LINKS = [...strings.FRONT_LINKS];
  if (userRole) {
    LINKS = [...LINKS, ...strings.LOGGED_IN_LINKS()];
    LINKS = userRole === userRoles.CANDIDATE ? LINKS : [...LINKS, ...strings.COMPANY_USER_LINKS()];
    LINKS = userRole === userRoles.SUPER_ADMIN ? [...LINKS, ...strings.ADMIN_LINKS()] : LINKS;
  }

  return (
    <FooterWrapper isCompanyRoute={isCompanyRoute}>
      <FooterContainer>
        <FooterSection isCompanyRoute={isCompanyRoute}>
          <SectionHeader>{strings.GET_IN_TOUCH[language]}</SectionHeader>
          <SectionText>Drottninggatan 38</SectionText>
          <SectionText>411 07 {strings.GOTHENBURG[language]}</SectionText>
          <SectionExtLink href="tel:+46739602601" data-iscompanyroute={isCompanyRoute}>
            +46 739 602 601
          </SectionExtLink>
          <SectionExtLink href="mailto:contact@futureskill.com" data-iscompanyroute={isCompanyRoute}>
            contact@futureskill.com
          </SectionExtLink>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/futureskillsweden/" target="blank">
              <Icon icon={'\f09a'} isCompanyRoute={isCompanyRoute} />
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/future-skill/" target="blank">
              <Icon icon={'\f0e1'} isCompanyRoute={isCompanyRoute} />
            </SocialLink>
          </SocialLinksContainer>
        </FooterSection>
        <FooterSection isCompanyRoute={isCompanyRoute}>
          <SectionHeader>Quick Links</SectionHeader>
          <NavigationLinksContainer twoColumns={LINKS.length > 5}>
            {LINKS.sort((a, b) => a[language].localeCompare(b[language])).map((link) => (
              <Link
                key={`${link[language].replace(' ', '-').toLowerCase()}-${link.to}`}
                to={link.to}
                data-iscompanyroute={isCompanyRoute}
              >
                {link[language]}
              </Link>
            ))}
          </NavigationLinksContainer>
        </FooterSection>
        <FooterSection alignBottom isCompanyRoute={isCompanyRoute}>
          <SectionExtLink href={`${getConfig().REACT_URL}/privacy-policy`} data-iscompanyroute={isCompanyRoute}>
            {strings.PRIVACY_POLICY[language]}
          </SectionExtLink>
          <SectionExtLink href={`${getConfig().REACT_URL}/membership`} data-iscompanyroute={isCompanyRoute}>
            {strings.MEMBERSHIP_AGREEMENT[language]}
          </SectionExtLink>
          {/* <SectionExtLink to="">{strings.TERMS_AND_CONDITIONS[language]}</SectionExtLink> */}
          <CopyText isCompanyRoute={isCompanyRoute}>© 2024 FutureSkill.com. {strings.ALL_RIGHTS[language]}</CopyText>
        </FooterSection>
        <FooterSection alignBottom isCompanyRoute={isCompanyRoute}>
          <Link to={Path.landingPage} data-iscompanyroute={isCompanyRoute}>
            <Logo isDarkMode={isDarkMode} isCompanyRoute={isCompanyRoute} />
          </Link>
        </FooterSection>
      </FooterContainer>
    </FooterWrapper>
  );
}
