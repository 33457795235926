import API from '../api';

export default class AuthService {
  constructor() {
    this.API = new API();
  }

  login = (email, password) => this.API.post('/sign-in', { email, password });
  twoFactorLogin = (code, tempSession) => this.API.post('/two-factor', { code, tempSession });

  updatePassword = (body) => this.API.post('/updatePassword', body);

  loadQRCode = () => this.API.post('/set-two-factor');

  setQRCode = (code) => this.API.post(`/verify-two-factor/${code}`);

  signup = (email, company, name, isCompany, isAgreementAgreed, isGDPRAgreed, campaignSource) =>
    this.API.post('/users', {
      email,
      company,
      name,
      isCompany,
      isAgreementAgreed,
      isGDPRAgreed,
      campaignSource,
    });

  setPassword = (body, session) => this.API.post(`/users/set-password/${session}`, body);

  resetPassword = (email) => this.API.post('/users/reset-password', { email });

  joinCommunity = (body) => this.API.post('/users/join-community', body);

  updateUser = (body) => this.API.patch('/users', body);

  getProfile = (id) => this.API.get(`/users/${id ? id : 'me'}`);

  logout = () => this.API.patch('/users/logout', {});

  getAccount = (session) => this.API.get(`/users/account/${session}`);
}
