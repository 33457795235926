export const LOAD_COMPANY_SENDING_TEST_SETTINGS_REQUEST = 'SendTestPage/LOAD_COMPANY_SENDING_TEST_SETTINGS_REQUEST';
export const LOAD_COMPANY_SENDING_TEST_SETTINGS_SUCCESS = 'SendTestPage/LOAD_COMPANY_SENDING_TEST_SETTINGS_SUCCESS';
export const LOAD_COMPANY_SENDING_TEST_SETTINGS_FAILURE = 'SendTestPage/LOAD_COMPANY_SENDING_TEST_SETTINGS_FAILURE';

export const LOAD_TESTS_REQUEST = 'SendTestPage/LOAD_TESTS_REQUEST';
export const LOAD_TESTS_SUCCESS = 'SendTestPage/LOAD_TESTS_SUCCESS';
export const LOAD_TESTS_FAILURE = 'SendTestPage/LOAD_TESTS_FAILURE';

export const POST_SEND_TEST_REQUEST = 'SendTestPage/POST_SEND_TEST_REQUEST';
export const POST_SEND_TEST_SUCCESS = 'SendTestPage/POST_SEND_TEST_SUCCESS';
export const POST_SEND_TEST_FAILURE = 'SendTestPage/POST_SEND_TEST_FAILURE';

export const ACTIVATE_TRIAL_PREMIUM_TEST_REQUEST = 'SendTestPage/ACTIVATE_TRIAL_PREMIUM_TEST_REQUEST';
export const ACTIVATE_TRIAL_PREMIUM_TEST_SUCCESS = 'SendTestPage/ACTIVATE_TRIAL_PREMIUM_TEST_SUCCESS';
export const ACTIVATE_TRIAL_PREMIUM_TEST_FAILURE = 'SendTestPage/ACTIVATE_TRIAL_PREMIUM_TEST_FAILURE';

export const RESET_STATE = 'SendTestPage/RESET_STATE';
