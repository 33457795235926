import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Modal, Body, Footer, FooterButton } from './modalElements';
import { getConfig } from 'functions/getConfig';
import { getCookie } from 'functions/getCookie';
import Path from 'routes/Path';

const setupFacebookPixel = () => {
  (function (f, b, e, v, n, t, s) {
    if (f.fbq) {
      return;
    }
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;

    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  window.fbq('init', '2675701892562186');
};

const setupGoogleAnalytics = () => {
  if (window.location.hostname !== 'futureskill.com') return;

  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-C8HKY75B05';
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', 'G-C8HKY75B05');
};

const setupAnalytics = () => {
  const cookieConsentLevel = getCookie('cookieConsentLevel');
  if (cookieConsentLevel !== 'all') {
    window.builderNoTrack = true;
    return;
  }
  setupFacebookPixel();
  setupGoogleAnalytics();
  window.builderNoTrack = false;
};

export default React.memo(() => {
  const { pathname } = useLocation();

  const [cookieConsentLevel, setCookieConsentLevel] = useState(getCookie('cookieConsentLevel'));

  useEffect(() => setupAnalytics(), []);

  const acceptFunction = (level) => {
    document.cookie = `cookieConsentLevel=${level}; Domain=${
      getConfig().COOKIE
    }; Max-Age=2147483647; Path=/; SameSite=Lax`;

    setCookieConsentLevel(true);
    setupAnalytics();
  };

  return (
    <Modal isOpen={!cookieConsentLevel && pathname !== Path.policyPage}>
      <Body>
        <p>
          We use cookies to improve your experience on our website. For more information read our{' '}
          <a href={`${getConfig().REACT_URL}/cookies`} target="_blank" rel="noopener noreferrer">
            cookie table
          </a>
          .
        </p>
      </Body>
      <Footer>
        <FooterButton label="Accept only necessary cookies" onClick={() => acceptFunction('necessary')} />
        <FooterButton label="Accept all cookies" onClick={() => acceptFunction('all')} autoFocus />
      </Footer>
    </Modal>
  );
});
