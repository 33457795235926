import { produce } from 'immer';

import {
  LOAD_CMS_CONTESTS_REQUEST,
  LOAD_CMS_CONTESTS_SUCCESS,
  LOAD_CMS_CONTESTS_FAILURE,
  CREATE_CMS_CONTEST_REQUEST,
  CREATE_CMS_CONTEST_SUCCESS,
  CREATE_CMS_CONTEST_FAILURE,
  DELETE_CMS_CONTEST_REQUEST,
  DELETE_CMS_CONTEST_SUCCESS,
  DELETE_CMS_CONTEST_FAILURE,
  RESET_UPDATED_IDS,
} from './constants';

const initialState = {
  loading: false,
  error: '',
  contests: [],
  newContestId: undefined,
  deletedContestId: undefined,
};

export default function CMSAllContestsReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case LOAD_CMS_CONTESTS_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case LOAD_CMS_CONTESTS_SUCCESS:
        state.loading = false;
        state.error = '';
        state.contests = action.data;
        break;
      case LOAD_CMS_CONTESTS_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case CREATE_CMS_CONTEST_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case CREATE_CMS_CONTEST_SUCCESS:
        state.loading = false;
        state.error = '';
        state.newContestId = action.data._id;
        break;
      case CREATE_CMS_CONTEST_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case DELETE_CMS_CONTEST_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case DELETE_CMS_CONTEST_SUCCESS:
        state.loading = false;
        state.error = '';
        state.deletedContestId = action.data._id;
        state.contests = state.contests.filter((contest) => contest._id !== action.data._id);
        break;
      case DELETE_CMS_CONTEST_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case RESET_UPDATED_IDS:
        state.newContestId = undefined;
        state.deletedContestId = undefined;
        break;
      default:
        break;
    }
  });
}
