import React from 'react';
import ReactDOM from 'react-dom/client';
import { HistoryRouter as ReactRouter } from 'redux-first-history/rr6';

import * as serviceWorker from './serviceWorker';
import { store, history } from './global/configureStore';
import { Provider } from 'react-redux';
import { Router } from './routes/Router';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'devicon/devicon.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ReactRouter history={history}>
      <Router />
    </ReactRouter>
  </Provider>
);
serviceWorker.unregister();
