import API from '../api';

export default class FreecodeCreatorService {
  constructor() {
    this.API = new API();
  }

  exportFreecode = (testId, testImplId) => {
    const query = !testImplId ? '' : `?testImplId=${testImplId}`;
    return this.API.get(`/freecode/${testId}/export${query}`);
  };

  importFreecode = (testId, testImplId, body) => {
    const query = !testImplId ? '' : `?testImplId=${testImplId}`;
    return this.API.put(`/freecode/${testId}/import${query}`, body);
  };

  saveFreecode = (testId, testImplId, body) => this.API.post(`/freecode/edit/${testId}/${testImplId}/save`, body);

  uploadDescriptionImage = (testId, testImplId, body) =>
    this.API.post(`/freecode/edit/${testId}/${testImplId}/description-image`, body);

  saveNewGraphics = (testId, testImplId, body) =>
    this.API.post(`/freecode/edit/${testId}/${testImplId}/graphics`, body);

  saveGraphics = (testId, testImplId, graphics) =>
    this.API.patch(`/freecode/edit/${testId}/${testImplId}/graphics`, { graphics });

  updateApiSolution = (testId, testImplId, api, newApi) =>
    this.API.patch(`/freecode/edit/${testId}/${testImplId}/api/solution`, { api, newApi });

  updateApiImplementation = (testId, testImplId, api, newApi) =>
    this.API.patch(`/freecode/edit/${testId}/${testImplId}/api/implementation`, { api, newApi });

  resetDefaultSolution = (testId, testImplId) =>
    this.API.patch(`/freecode/edit/${testId}/${testImplId}/reset/solution`);

  resetImplementation = (testId, testImplId) =>
    this.API.patch(`/freecode/edit/${testId}/${testImplId}/reset/implementation`);

  handlePublishStatus = (testId, custom, archived) =>
    this.API.patch(`/freecode/edit/${testId}/publish-status`, { custom, archived });

  loadImplementationVersions = (testId) => this.API.get(`/freecode/edit/${testId}/implementation-versions`);

  createWipTestImplementation = (testId) => this.API.post(`/freecode/edit/${testId}/implementation-versions/wip`);

  makeWipTestImplementationLive = (testId) =>
    this.API.patch(`/freecode/edit/${testId}/implementation-versions/wip/publish`);

  revertWipTestImplementation = (testId) =>
    this.API.patch(`/freecode/edit/${testId}/implementation-versions/wip/revert`);

  moveFreecodeTest = (testId, companyId) => this.API.patch(`/freecode/edit/${testId}/${companyId}/move-freecode/`);
}
