// Saved for future implementation
// export const FRONT_LINKS = [
//   { en: "About Future Skill", sv: "Om Future Skill", to: "/about" },
//   {
//     en: "Coding tests and challenges",
//     sv: "Kodtester och kodtävlingar",
//     to: "/recruitment"
//   },
//   { en: "Careers", sv: "Karriär", to: "/careers" },
//   { en: "Contact us", sv: "Kontakta oss", to: "/contact" }
// ];

export const FRONT_LINKS = [
  { de: 'Courses', en: 'Courses', sv: 'Kurser', to: '/courses' },
  { de: 'Übungen', en: 'Exercises', sv: 'Övningar', to: '/exercises' },
  // { de: 'Freecode', en: 'Freecode', sv: 'Freecode', to: '/freecode' },
  { de: 'Challenges', en: 'Challenges', sv: 'Utmaningar', to: '/challenges' },
];

export const COMPANY_PAGE_LINKS = () => [
  {
    de: 'Firmenveranstaltungen',
    en: 'Company Events',
    sv: 'Företagsevent',
    to: '/company-events',
  },
];

export const LOGGED_IN_LINKS = () => [
  {
    de: 'Tests',
    en: 'Tests',
    sv: 'Tester',
    to: '/tests',
  },
];

export const ADMIN_LINKS = () => [
  {
    de: 'Tests',
    en: 'Tests',
    sv: 'Tests',
    to: '/tests',
  },
];

// export const FRONT_BUTTONS = [
//   {
//     en: "Challenges",
//     to: "/challenges",
//     style: "secondary"
//   },
// ];

export const BASE_BUTTONS = [
  { de: 'Für Unternehmen', en: 'For companies', sv: 'För företag', to: '/', style: 'primary' },
];

export const FOR_DEV_BUTTONS = [
  { de: 'Für Entwickler', en: 'For developers', sv: 'För utvecklare', to: '/', style: 'primary' },
];

export const NOT_LOGGED_IN_BUTTONS = [
  {
    de: 'Einloggen',
    en: 'Log in',
    sv: 'Logga in',
    to: '/login',
    style: 'secondary',
  },
  {
    de: 'Anmelden',
    en: 'Sign up',
    sv: 'Skapa konto',
    to: '/signup',
    style: 'primary',
  },
];

export const LOG_OUT_BUTTON = {
  de: 'Ausloggen',
  en: 'Log out',
  sv: 'Logga ut',
  style: 'secondary',
};
