import { produce } from 'immer';

import {
  LOAD_SUPERADMIN_REQUEST,
  LOAD_SUPERADMIN_SUCCESS,
  LOAD_SUPERADMIN_FAILURE,
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAILURE,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_FAILURE,
  UPDATE_ADMIN_COMPANY_REQUEST,
  UPDATE_ADMIN_COMPANY_SUCCESS,
  UPDATE_ADMIN_COMPANY_FAILURE,
  UPDATE_CONFIG_EMAILS_REQUEST,
  UPDATE_CONFIG_EMAILS_SUCCESS,
  UPDATE_CONFIG_EMAILS_FAILURE,
  UPDATE_CONFIG_REQUEST,
  UPDATE_CONFIG_SUCCESS,
  UPDATE_CONFIG_FAILURE,
  UPDATE_PLAN_REQUEST,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_FAILURE,
} from './constants';

const initialState = {
  loading: false,
  error: '',
  companies: [],
  configEmails: [],
  boilerPlateEmails: {},
  updateCompanySuccess: false,
  companyId: undefined,
  updatedUserId: undefined,
  config: {},
  areEmailsUpdated: false,
  archiveTest: {},
  stages: {},
  candidateRatings: {},
  ratingWeight: {},
  skills: {},
  paymentPlan: {},
  updatedConfig: {},
};

export default function SuperAdminPageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case LOAD_SUPERADMIN_REQUEST:
        state.loading = true;
        state.error = '';
        state.companies = [];
        state.configEmails = [];
        state.boilerPlateEmails = {};
        state.archiveTest = {};
        state.stages = {};
        state.candidateRatings = {};
        state.ratingWeight = {};
        state.skills = {};
        state.paymentPlan = {};
        break;
      case LOAD_SUPERADMIN_SUCCESS:
        state.loading = false;
        state.error = '';
        state.configEmails = action.data.config.filter((config) => {
          return (
            config.key === 'signUp' ||
            config.key === 'testSubmitNotificationEmail' ||
            config.key === 'newApplicationEmail'
          );
        });
        state.boilerPlateEmails = action.data.config.find((config) => config.key === 'boilerplateCompany');
        state.companies = action.data.companies;
        state.archiveTest = action.data.config.find((config) => config.key === 'archivedTest');
        state.stages = action.data.config.find((config) => config.key === 'stages');
        state.candidateRatings = action.data.config.find((config) => config.key === 'candidateRatings');
        state.ratingWeight = action.data.config.find((config) => config.key === 'ratingWeight');
        state.skills = action.data.config.find((config) => config.key === 'skillTags');
        state.paymentPlan = action.data.config.find((config) => config.key === 'paymentPlan');
        break;
      case LOAD_SUPERADMIN_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.companies = [];
        state.configEmails = [];
        state.boilerPlateEmails = {};
        state.archiveTest = {};
        state.stages = {};
        state.candidateRatings = {};
        state.ratingWeight = {};
        state.skills = {};
        state.paymentPlan = {};
        break;
      case CREATE_COMPANY_REQUEST:
        state.loading = true;
        state.error = '';
        state.companyId = false;
        break;
      case CREATE_COMPANY_SUCCESS:
        state.loading = false;
        state.error = '';
        state.companyId = action.data._id;
        state.companies = [...state.companies, action.data];
        break;
      case CREATE_COMPANY_FAILURE:
        state.loading = false;
        state.companyId = false;
        state.error = action.error;
        break;
      case UPDATE_COMPANY_REQUEST:
        state.loading = true;
        state.error = '';
        state.updateCompanySuccess = false;
        break;
      case UPDATE_COMPANY_SUCCESS:
        state.loading = false;
        state.error = '';
        state.updateCompanySuccess = true;
        state.companies = state.companies.reduce((collection, company) => {
          if (company._id === action.data._id) {
            company.testsOnly = action.data.testsOnly;
            if (action.data.deleted) {
              return collection;
            }
          }
          return [...collection, company];
        }, []);
        break;
      case UPDATE_COMPANY_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.updateCompanySuccess = false;
        break;
      case UPDATE_ADMIN_COMPANY_REQUEST:
        state.loading = true;
        state.error = '';
        state.updatedUserId = undefined;
        break;
      case UPDATE_ADMIN_COMPANY_SUCCESS:
        state.loading = false;
        state.error = '';
        state.updatedUserId = action.data._id;
        break;
      case UPDATE_ADMIN_COMPANY_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.updatedUserId = undefined;
        break;
      case UPDATE_CONFIG_EMAILS_REQUEST:
        state.loading = true;
        state.error = '';
        state.areEmailsUpdated = false;
        break;
      case UPDATE_CONFIG_EMAILS_SUCCESS:
        state.loading = false;
        state.error = '';
        state.areEmailsUpdated = action.data;
        break;
      case UPDATE_CONFIG_EMAILS_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.areEmailsUpdated = false;
        break;
      case UPDATE_CONFIG_REQUEST:
        state.loading = true;
        state.error = '';
        state.updatedConfig = {};
        break;
      case UPDATE_CONFIG_SUCCESS:
        state.loading = false;
        state.error = '';
        state.updatedConfig = action.data;
        break;
      case UPDATE_CONFIG_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.updatedConfig = {};
        break;
      case UPDATE_PLAN_REQUEST:
        state.loading = true;
        state.error = '';
        state.updatedPlan = {};
        break;
      case UPDATE_PLAN_SUCCESS:
        state.loading = false;
        state.error = '';
        state.updatedPlan = action.data;
        state.paymentPlan = action.data;
        break;
      case UPDATE_PLAN_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.updatedPlan = {};
        break;
      default:
        break;
    }
  });
}
