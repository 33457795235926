import { produce } from 'immer';

import {
  GET_EXERCISE_REQUEST,
  GET_EXERCISE_SUCCESS,
  GET_EXERCISE_FAILURE,
  SET_EXERCISE_RATING_REQUEST,
  SET_EXERCISE_RATING_SUCCESS,
  SET_EXERCISE_RATING_FAILURE,
  GET_COURSE_REQUEST,
  GET_COURSE_SUCCESS,
  GET_COURSE_FAILURE,
  UPDATE_FLOW_REQUEST,
  UPDATE_FLOW_SUCCESS,
  UPDATE_FLOW_FAILURE,
  RESET_INPUT_EXERCISE_PROGRESS,
  GET_CHALLENGE_REQUEST,
  GET_CHALLENGE_SUCCESS,
  GET_CHALLENGE_FAILURE,
  SET_CHALLENGE_RATING_REQUEST,
  SET_CHALLENGE_RATING_SUCCESS,
  SET_CHALLENGE_RATING_FAILURE,
  GET_TOURNAMENT_PLAYBACK_REQUEST,
  GET_TOURNAMENT_PLAYBACK_SUCCESS,
  GET_TOURNAMENT_PLAYBACK_FAILURE,
  RESET_STATE,
} from './constants';

const initialState = {
  loading: false,
  selectedItem: {},
  inputExercise: {},
  error: '',
};

export default function CommunitySelectedPageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      // Exercise reducers:
      case GET_EXERCISE_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case GET_EXERCISE_SUCCESS:
        state.loading = false;
        state.error = '';
        state.selectedItem = action.exercise;
        break;
      case GET_EXERCISE_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case SET_EXERCISE_RATING_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case SET_EXERCISE_RATING_SUCCESS:
        state.loading = false;
        state.error = '';
        break;
      case SET_EXERCISE_RATING_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      // Course reducers:
      case GET_COURSE_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case GET_COURSE_SUCCESS:
        state.loading = false;
        state.error = '';
        state.selectedItem = action.course;
        break;
      case GET_COURSE_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case UPDATE_FLOW_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case UPDATE_FLOW_SUCCESS:
        state.loading = false;
        state.error = '';
        state.inputExercise = action.inputExercise;
        let selectedItem = JSON.parse(JSON.stringify(state.selectedItem));
        syllabusLoop: for (const syllabus in selectedItem.syllabus) {
          for (const module in selectedItem.syllabus[syllabus].modules) {
            if (selectedItem.syllabus[syllabus].modules[module].exercise.id === action.inputExercise.test) {
              selectedItem.syllabus[syllabus].modules[module].exercise.inputExercise = action.inputExercise;
              state.selectedItem = selectedItem;
              break syllabusLoop;
            }
          }
        }
        break;
      case UPDATE_FLOW_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case RESET_INPUT_EXERCISE_PROGRESS:
        state.inputExercise = {};
        break;

      // Challenge reducers:
      case GET_CHALLENGE_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case GET_CHALLENGE_SUCCESS:
        state.loading = false;
        state.error = '';
        state.selectedItem = action.challenge;
        break;
      case GET_CHALLENGE_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case SET_CHALLENGE_RATING_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case SET_CHALLENGE_RATING_SUCCESS:
        state.loading = false;
        state.error = '';
        break;
      case SET_CHALLENGE_RATING_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case GET_TOURNAMENT_PLAYBACK_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case GET_TOURNAMENT_PLAYBACK_SUCCESS:
        state.loading = false;
        state.error = '';
        state.tournamentPlaybacks = action.tournamentPlaybacks;
        break;
      case GET_TOURNAMENT_PLAYBACK_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case RESET_STATE:
        state.selectedItem = {};
        break;
      default:
        break;
    }
  });
}
