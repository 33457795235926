import React, { useEffect } from 'react';
import { Spinner } from 'components/Spinner/Spinner';
import cookiesTablePdf from 'assets/fs-cookie-table.pdf';

export default function CookiesPage() {
  useEffect(() => {
    window.open(cookiesTablePdf, '_self');
  });

  return <Spinner />;
}
