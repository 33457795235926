import React, { useState } from 'react';

import { Container, Wrapper } from 'components/Layout/Layout';
import { Header, HeadingContainer } from 'components/PageTitleHeader/styles';
import Collapse from 'components/Collapse/Collapse';

import content from './data';

const PolicyPage = () => {
  const [data, setData] = useState(content);

  return (
    <Wrapper isNewPage>
      <Container column usePadding="100px 0 0 0">
        <HeadingContainer>
          <Header isNewPage>Privacy Policy</Header>
        </HeadingContainer>

        {data.map((policy, i) => (
          <Collapse
            key={policy.id}
            title={policy.title}
            id={policy.id}
            isCollapsed={!policy.active}
            action={() => {
              let temp = [...data];
              temp[i]['active'] = !temp[i]['active'];
              setData(temp);
            }}
          >
            {policy.data}
          </Collapse>
        ))}
      </Container>
    </Wrapper>
  );
};

export default PolicyPage;
