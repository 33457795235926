import styled, { css } from 'styled-components';

import { Link as BaseLink } from 'components/FormComponents/FormComponents';

export const FooterWrapper = styled.div`
  width: 100%;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.pageFooter.background};

  @media (max-width: 768px) {
    height: auto;
  }

  @media print {
    display: none;
  }

  ${({ isCompanyRoute }) =>
    isCompanyRoute &&
    css`
      background: ${({ theme }) => theme.oldThemeFooter.background};
    `}
`;

export const FooterContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  padding: 0 20px;

  @media (max-width: 1100px) {
    flex-wrap: wrap;

    & > div:nth-child(3) {
      order: 1;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
    align-items: flex-start;

    & div:nth-child(4) {
      order: -1;
    }

    & > div {
      padding: 20px 0;
    }
  }
`;

export const CopyFooter = styled.div`
  height: 40px;
  width: 100%;
  background: ${({ theme }) => theme.primary};
  padding: 20px 18%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;

  @media (max-width: 1366px) {
    padding: 20px 20px;
  }

  @media (max-width: 667px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 45px;
    padding: 0 15px;
  }
`;

export const Logo = styled.div`
  background: url(${({ theme }) => theme.pageFooter.logo.main});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 160px;
  height: 40px;

  ${({ isCompanyRoute }) =>
    isCompanyRoute &&
    css`
      background: url(${({ theme }) => theme.oldThemeFooter.logo.main});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    `}
`;

export const FooterSection = styled.div`
  height: 320px;
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  color: ${({ theme }) => theme.pageFooter.link.color};
  flex-shrink: 0;

  @media (max-width: 1100px) {
    height: 250px;
    flex: 50%;
    min-height: 102px;
  }
  @media (max-width: 768px) {
    height: auto;
    min-height: auto;
  }

  ${({ alignBottom }) =>
    alignBottom &&
    css`
      justify-content: flex-end;
    `}

  ${({ isCompanyRoute }) =>
    isCompanyRoute &&
    css`
      color: ${({ theme }) => theme.oldThemeFooter.link.color};
    `}
`;

export const SocialLinksContainer = styled.div`
  display: flex;
  width: 55px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
`;

export const SocialLink = styled.a`
  :hover {
    text-decoration: none;
  }
`;

export const Icon = styled.div`
  color: ${({ theme }) => theme.pageFooter.link.color};
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.pageFooter.link.colorHover};
  }
  &::after {
    font-family: FontAwesome;
    content: '${(props) => props.icon}';
  }

  ${({ isCompanyRoute }) =>
    isCompanyRoute &&
    css`
      color: ${({ theme }) => theme.oldThemeFooter.link.color};
      cursor: pointer;
      :hover {
        color: ${({ theme }) => theme.oldThemeFooter.link.colorHover};
      }
    `}
`;

export const SectionHeader = styled.span`
  font-size: 1.125rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const SectionText = styled.span`
  font-size: 1rem;
  margin-bottom: 20px;
  margin-bottom: 10px;
`;

export const Link = styled(BaseLink)`
  font-size: 1rem;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.pageFooter.link.color};
  :hover {
    color: ${({ theme }) => theme.pageFooter.link.colorHover};
  }

  ${({ 'data-iscompanyroute': isCompanyRoute }) =>
    isCompanyRoute &&
    css`
      color: ${({ theme }) => theme.oldThemeFooter.link.color};
      :hover {
        color: ${({ theme }) => theme.oldThemeFooter.link.colorHover};
      }
    `}
`;

export const SectionExtLink = styled.a`
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.pageFooter.link.color};
  :hover {
    color: ${({ theme }) => theme.pageFooter.link.colorHover};
    text-decoration: none;
  }

  ${({ 'data-iscompanyroute': isCompanyRoute }) =>
    isCompanyRoute &&
    css`
      color: ${({ theme }) => theme.oldThemeFooter.link.color};
      :hover {
        color: ${({ theme }) => theme.oldThemeFooter.link.colorHover};
      }
    `}
`;

export const CopyText = styled.span`
  color: ${({ theme }) => theme.pageFooter.link.color};
  opacity: 0.5;
  margin-bottom: 10px;

  ${({ isCompanyRoute }) =>
    isCompanyRoute &&
    css`
      color: ${({ theme }) => theme.oldThemeFooter.link.color};
    `}
`;

export const NavigationLinksContainer = styled.div`
  display: grid;
  ${({ twoColumns }) =>
    twoColumns &&
    css`
      grid-template-columns: 1fr 1fr;
    `}
  column-gap: 40px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-bottom: 10px;
  }
`;
