import React from 'react';
import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { colors } from 'global/colors';

export const Hr = styled.hr`
  border: 0;
  height: 1px;
  background: ${colors.lightGray2};
  width: 100%;
  margin: 0 0 2px;
`;

export const Form = styled.form.attrs(({ onSubmit }) => ({
  onSubmit: (e) => {
    e.preventDefault();
    onSubmit();
  },
}))`
  display: flex;
  flex-direction: column;
  user-select: none;
`;

export const Input = styled.input`
  font-size: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  border: 1px solid ${colors.gray};
  border-radius: 5px;
  background: ${({ theme }) => theme.pageBackground};
  outline: none;
  color: ${({ theme }) => theme.text};
  margin-bottom: 15px;

  :focus {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  }
`;

export const InfoText = styled.span`
  color: ${({ theme }) => theme.freecode.color};
  font-size: 12px;
  margin-bottom: 10px;

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.auth.form.errorColor};
    `}

  ${({ success }) =>
    success &&
    css`
      color: ${({ theme }) => theme.auth.form.verifiedColor};
    `}

    ${(props) =>
    props.medium &&
    css`
      font-size: 14px;
    `}
`;

export const Checkbox = ({ checked, label, right, onChange }) => {
  const input = <CheckboxInput right={right} checked={checked} onChange={onChange} />;
  const inputLabel = <Label right={right}>{label}</Label>;

  return (
    <CheckboxContainer>
      {right ? (
        <label>
          {input}
          {inputLabel}
        </label>
      ) : (
        <label>
          {inputLabel}
          {input}
        </label>
      )}
    </CheckboxContainer>
  );
};

const CheckboxContainer = styled.div``;

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  outline: none;

  ${(props) =>
    !props.right &&
    css`
      margin-left: 5px;
    `}
`;

export const Label = styled.span`
  font-size: 14px;
  color: ${colors.darkGray};

  ${(props) =>
    props.right &&
    css`
      margin-left: 5px;
    `}

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
`;

export const Link = styled(RouterLink)`
  text-decoration: none;
  color: unset;

  :hover {
    color: unset;
    text-decoration: none;
  }
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 0;

  ${(props) =>
    props.dark &&
    css`
      background: ${colors.fsDarkBlue};
    `}

  ${(props) =>
    props.backgroundImage &&
    css`
      background-image: url(${props.backgroundImage});
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      min-height: 600px;
    `}
`;

export const SectionHeader = styled.span`
  text-transform: uppercase;
  font-weight: 300;
  font-size: 30px;
  margin-bottom: 35px;
  color: ${({ theme }) => theme.label};
  ${(props) =>
    props.margin &&
    css`
      margin-top: 100px;
    `}
  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}
    ${(props) =>
    props.large &&
    css`
      font-size: 40px;
    `}
`;

export const FreecodeInputContainer = styled.div`
  position: relative;
  color: ${({ theme }) => theme.freecode.color};
  margin-bottom: 10px;
`;

export const FreecodeInputField = styled.input`
  width: 100%;
  padding: 12px 14px 12px 40px;
  height: 45px;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.freecode.color};
  background: ${({ theme }) => theme.freecode.headerBackground};
  color: ${({ theme }) => theme.freecode.color};

  &:focus {
    outline: none;
  }
`;

export const FreecodeIcon = styled.i.attrs(({ icon }) => ({ className: icon }))`
  margin-left: 15px;
  position: absolute;
  margin-top: 15px;
`;
