import { useState } from 'react';

export const useSwipe = (callback) => {
  const [currentTouch, setCurrentTouch] = useState(null);
  const getTouchOrEvent = (e) => (e.changedTouches ? e.changedTouches[0] : e);
  const onSwipeStart = (e) => {
    setCurrentTouch(getTouchOrEvent(e).clientX);
  };
  const onSwipeMove = (e) => {
    e.preventDefault();
  };
  const onSwipeEnd = (e) => {
    if (currentTouch || currentTouch === 0) {
      callback(Math.sign(getTouchOrEvent(e).clientX - currentTouch));
      setCurrentTouch(null);
    }
  };

  return { onSwipeStart, onSwipeMove, onSwipeEnd };
};
