export const CHALLENGE = 'challenge';
export const EXERCISE = 'exercise';
export const FREECODE = 'exercise';
export const CODE_TEST = 'code';
export const GENERAL_SKILLS = 'General Skills';
export const MULTICHOICE = 'Multiple Choice Programming';

export const categories = {
  CHALLENGE: { category: CHALLENGE, simpleName: 'Challenge' },
  EXERCISE: { category: EXERCISE, simpleName: 'Exercise' },
  FREECODE: { category: FREECODE, simpleName: 'Freecode' },
  CODE: { category: CODE_TEST, simpleName: 'Code Tests' },
  GENERAL_SKILLS: { category: GENERAL_SKILLS, simpleName: 'General Skills' },
  MULTICHOICE: { category: MULTICHOICE, simpleName: 'Multichoice' },
};

export const contestDefaultThumbnail =
  'https://sws-uploads.s3.eu-central-1.amazonaws.com/5b1a71814930bd59dda45dfe/60af655bb705932a147b4a8b-background-thumbnail.jpeg';

export const TOURNAMENT_RUN_DEFAULT = 'topList';
export const TOURNAMENT_RUN_OWN = 'myself';
export const TOURNAMENT_RUN_BOTS = 'fsBots';
export const TOURNAMENT_RUN_OPTIONS = [
  { label: 'Top List', value: TOURNAMENT_RUN_DEFAULT },
  { label: 'Myself', value: TOURNAMENT_RUN_OWN },
  { label: 'FS Bots', value: TOURNAMENT_RUN_BOTS },
];

export const liveCodingStatus = {
  IDLE: 'Idle',
  TYPING: 'Typing',
  RUNNING: 'Running',
};

export const questionTypes = {
  FREE_TEXT: 'freeText',
  ONE_CORRECT_ANSWER: 'oneCorrectAnswer',
};
