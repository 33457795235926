import { produce } from 'immer';

import {
  GET_ALL_EXERCISES_REQUEST,
  GET_ALL_EXERCISES_SUCCESS,
  GET_ALL_EXERCISES_FAILURE,
  GET_ALL_CHALLENGES_REQUEST,
  GET_ALL_CHALLENGES_SUCCESS,
  GET_ALL_CHALLENGES_FAILURE,
  GET_ALL_COURSES_REQUEST,
  GET_ALL_COURSES_SUCCESS,
  GET_ALL_COURSES_FAILURE,
} from './constants';

const initialState = {
  loading: false,
  itemsList: [],
  courses: [],
  error: '',
};

export default function CommunityListPageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case GET_ALL_EXERCISES_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case GET_ALL_EXERCISES_SUCCESS:
        state.loading = false;
        state.error = '';
        state.itemsList = action.exercises;
        break;
      case GET_ALL_EXERCISES_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case GET_ALL_CHALLENGES_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case GET_ALL_CHALLENGES_SUCCESS:
        state.loading = false;
        state.error = '';
        state.itemsList = action.challenges;
        break;
      case GET_ALL_CHALLENGES_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case GET_ALL_COURSES_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case GET_ALL_COURSES_SUCCESS:
        state.loading = false;
        state.error = '';
        state.itemsList = action.courses;
        break;
      case GET_ALL_COURSES_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      default:
        break;
    }
  });
}
