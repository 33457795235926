export const QUICK_LINKS = { de: 'Quicklinks', en: 'Quick links', sv: 'Snabblänkar' };
export const PAGE_LINKS = { de: 'Pagelinks', en: 'Page links', sv: 'Sidlänkar' };
export const GET_IN_TOUCH = { de: 'In Kontakt kommen', en: 'Get in touch', sv: 'Här finns vi' };
export const GOTHENBURG = { de: 'Göteborg, Schweden', en: 'Gothenburg, Sweden', sv: 'Göteborg, Sverige' };
export const ALL_RIGHTS = {
  de: 'Alle Rechte vorbehalten.',
  en: 'All rights reserved.',
  sv: 'Alla rättigheter förbehållna.',
};
export const PRIVACY_POLICY = { de: 'Datenschutz-Bestimmungen', en: 'Privacy policy', sv: 'Integritetspolicy' };
export const MEMBERSHIP_AGREEMENT = {
  de: 'Mitgliedschaftsvereinbarung',
  en: 'Membership Agreement',
  sv: 'Medlemsavtal',
};
export const TERMS_AND_CONDITIONS = {
  de: 'Bedingungen & Konditionen',
  en: 'Terms & conditions',
  sv: 'Allmänna villkor',
};
// const BASE_LINKS = [
//   { en: "Tutorials", sv: "Handledning", to: "" },
//   { en: "About Future Skill", sv: "Om Future Skill", to: "/about" },
//   { en: "Recruitment", sv: "Rekrytering", to: "/recruitment" },
//   { en: "Careers", sv: "Karriär", to: "/careers" },
//   { en: "Contact us", sv: "Kontakta oss", to: "/contact" }
// ];

export const COMMUNITY_LINKS = () => [
  {
    de: 'Erstellen',
    en: 'Create',
    sv: 'Skapa',
    to: '/customTests',
  },
  { de: 'Übungen', en: 'Exercises', sv: 'Övningar', to: '/exercises' },
  { de: 'Freecode', en: 'Freecode', sv: 'Freecode', to: '/freecode' },
  { de: 'Challenges', en: 'Challenges', sv: 'Utmaningar', to: '/challenges' },
];

export const FRONT_LINKS = [
  { de: 'Über Future Skill', en: 'About Future Skill', sv: 'Om Future Skill', to: '/about' },
  { de: 'Firmenveranstaltungen', en: 'Company Events', sv: 'Företagsevent', to: '/company-events' },
  { de: 'Für Unternehmen', en: 'For companies', sv: 'För företag', to: '/' },
  { de: 'Kontaktiere uns', en: 'Contact us', sv: 'Kontakta oss', to: '/contact' },
  { de: 'FAQ', en: 'FAQ', sv: 'FAQ', to: '/faq' },
];

export const LOGGED_IN_LINKS = () => [
  {
    de: 'Custom Tests',
    en: 'Custom Tests',
    sv: 'Custom Tests',
    to: '/customTests',
  },
];

export const COMPANY_USER_LINKS = () => [
  {
    de: 'Challenges',
    en: 'Challenges',
    sv: 'Challenges',
    to: `/cms/allChallenges`,
  },
];

export const ADMIN_LINKS = () => [
  {
    de: 'Tests',
    en: 'Tests',
    sv: 'Tests',
    to: '/tests',
  },
  {
    de: 'Customer Statistics',
    en: 'Customer Statistics',
    sv: 'Customer Statistics',
    to: `/statistics`,
  },
  {
    de: 'Community Email',
    en: 'Community Email',
    sv: 'Community Email',
    to: `/community-email`,
  },
];
