import { produce } from 'immer';

import {
  LOAD_CONTEST_REQUEST,
  LOAD_CONTEST_SUCCESS,
  LOAD_CONTEST_FAILURE,
  LOAD_RANK_LIST_REQUEST,
  LOAD_RANK_LIST_SUCCESS,
  LOAD_RANK_LIST_FAILURE,
  CREATE_RUN_REQUEST,
  CREATE_RUN_SUCCESS,
  CREATE_RUN_FAILURE,
  CREATE_CONTINUOUS_REQUEST,
  CREATE_CONTINUOUS_SUCCESS,
  CREATE_CONTINUOUS_FAILURE,
} from './constants';

const initialState = {
  loading: false,
  error: '',
  contest: {},
  rankList: [],
  isSessionRunning: false,
};

export default function CMSResultReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case LOAD_CONTEST_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case LOAD_CONTEST_SUCCESS:
        state.loading = false;
        state.error = '';
        state.contest = action.data;
        break;
      case LOAD_CONTEST_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case LOAD_RANK_LIST_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case LOAD_RANK_LIST_SUCCESS:
        state.loading = false;
        state.error = '';
        state.rankList = action.data;
        state.isSessionRunning = action.isSessionRunning;
        break;
      case LOAD_RANK_LIST_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case CREATE_RUN_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case CREATE_RUN_SUCCESS:
        state.loading = false;
        state.error = '';
        state.isSessionRunning = action.data;
        break;
      case CREATE_RUN_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case CREATE_CONTINUOUS_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case CREATE_CONTINUOUS_SUCCESS:
        state.loading = false;
        state.error = '';
        state.contest = action.data;
        break;
      case CREATE_CONTINUOUS_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      default:
        break;
    }
  });
}
