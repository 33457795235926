import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import 'bootstrap/dist/css/bootstrap.min.css';

import createRootReducer from './reducers';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

export const store = configureStore({
  reducer: createRootReducer(routerReducer),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([routerMiddleware]),
});

export const history = createReduxHistory(store);
