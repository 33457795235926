export const GET_PLAN_REQUEST = 'PlansPage/GET_PLAN_REQUEST';
export const GET_PLAN_SUCCESS = 'PlansPage/GET_PLAN_SUCCESS';
export const GET_PLAN_FAILURE = 'PlansPage/GET_PLAN_FAILURE';
export const UPDATE_COMPANY_REQUEST = 'PlansPage/UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'PlansPage/UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILURE = 'PlansPage/UPDATE_COMPANY_FAILURE';
export const CREATE_PAYMENT_REQUEST = 'PlansPage/CREATE_PAYMENT_REQUEST';
export const CREATE_PAYMENT_SUCCESS = 'PlansPage/CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAILURE = 'PlansPage/CREATE_PAYMENT_FAILURE';
export const UPDATE_SUCCESSFUL_TRANSACTION_REQUEST = 'PlansPage/UPDATE_SUCCESSFUL_TRANSACTION_REQUEST';
export const UPDATE_SUCCESSFUL_TRANSACTION_SUCCESS = 'PlansPage/UPDATE_SUCCESSFUL_TRANSACTION_SUCCESS';
export const UPDATE_SUCCESSFUL_TRANSACTION_FAILURE = 'PlansPage/UPDATE_SUCCESSFUL_TRANSACTION_FAILURE';
export const UPDATE_FAILED_TRANSACTION_REQUEST = 'PlansPage/UPDATE_FAILED_TRANSACTION_REQUEST';
export const UPDATE_FAILED_TRANSACTION_SUCCESS = 'PlansPage/UPDATE_FAILED_TRANSACTION_SUCCESS';
export const UPDATE_FAILED_TRANSACTION_FAILURE = 'PlansPage/UPDATE_FAILED_TRANSACTION_FAILURE';
