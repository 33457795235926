import { produce } from 'immer';

import {
  UPDATE_FREECODE_TICKET_RUN_BODY,
  UPDATE_FREECODE_TICKET_RUN_STATUS,
  UPDATE_FREECODE_TICKET_CODE_DATA,
  UPDATE_FREECODE_TICKET_SUBMIT_STATUS,
  UPDATE_FREECODE_TICKET_EXTENDED_DEADLINE,
  UPDATE_SPECTATORS_COUNT,
} from '../constants';

const initialState = {
  ticketRunBody: {},
  isCodeRunning: {},
  isTicketSubmitted: {},
  extendedDeadlineTicket: {},
  ticketCodeData: {},
  spectatorsCount: {},
};

export default function freecode(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case UPDATE_FREECODE_TICKET_RUN_BODY:
        state.ticketRunBody[action?.payload?.ticketId] = action?.payload?.ticketRunBody;
        break;
      case UPDATE_FREECODE_TICKET_RUN_STATUS:
        state.isCodeRunning[action?.payload?.ticketId] = action?.payload?.isRunning;
        break;
      case UPDATE_FREECODE_TICKET_CODE_DATA:
        state.ticketCodeData[action?.payload?.ticketId] = action?.payload?.solution;
        break;
      case UPDATE_FREECODE_TICKET_SUBMIT_STATUS:
        state.isTicketSubmitted[action?.payload?.ticketId] = true;
        break;
      case UPDATE_FREECODE_TICKET_EXTENDED_DEADLINE:
        state.extendedDeadlineTicket[action?.payload?.ticketId] = action?.payload?.ticket;
        break;
      case UPDATE_SPECTATORS_COUNT:
        state.spectatorsCount[action?.payload?.ticketId] = action?.payload?.spectatorsCount;
        break;
      default:
        break;
    }
  });
}
