import API from '../api';

export default class TicketService {
  constructor() {
    this.API = new API();
  }

  loadTicket = (ticketId) => this.API.get(`/ticket/${ticketId}/take`);

  loadTickets = () => this.API.get(`/ticket/candidate-tickets`);

  updateInput = (ticketId, body) => this.API.post(`/ticket/${ticketId}/answer-question`, body);

  closeTicket = (ticketId, body) => this.API.post(`/ticket/${ticketId}/submit`, body);

  reportQuestion = (body) => this.API.post(`/questionFeedback/`, body);

  updateCandidateTicketLocation = (ticket, body) => this.API.put(`/ticket/${ticket}/update-location`, body);

  updateCandidateChoosesTest = (ticket, test) => this.API.put(`/ticket/${ticket}/${test}/update-chosen-test`);

  updateSendOldReport = (ticket, oldTicket) => this.API.post(`/ticket/${ticket}/${oldTicket}/send-old-report`);

  sendTicket = (body) => this.API.post(`/ticket/send-ticket/`, body);

  loadReport = (ticketId) => this.API.get(`/ticket/loadReport/${ticketId}`);

  loadAnonymiseReport = (anonymiseId) => this.API.get(`/ticket/loadAnonymiseReport/${anonymiseId}`);

  loadCompanyTickets = () => this.API.get(`/ticket/`);

  archiveTicket = (ticketId) => this.API.put(`/ticket/${ticketId}/archive`);

  updateTicketDepartment = (ticketId, departmentId) =>
    this.API.put(`/ticket/updateTicketDepartment/${ticketId}/${departmentId}`);

  downloadPdfReport = (id, version, anonymiseReport, showDescription) =>
    this.API.get(
      `/ticket/downloadReport/${id}?version=${version}&anonymiseReport=${anonymiseReport}&showDescription=${showDescription}`
    );

  downloadAnonymisePdfReport = (id, version, showDescription) =>
    this.API.get(`/ticket/downloadAnonymiseReport/${id}?version=${version}&showDescription=${showDescription}`);

  generateAnonymiseLink = (ticketId) => this.API.post(`/ticket/generateAnonymiseLink/${ticketId}`);

  disableAnonymiseLink = (anonymiseId) => this.API.put(`/ticket/disableAnonymiseLink/${anonymiseId}`);

  extendTicketDeadline = (ticketId, body) => this.API.post(`/ticket/${ticketId}/extendDeadline`, body);

  updateFreecodeCopyEvent = (ticketId, body) => this.API.post(`/ticket/updateFreecodeCopyEvent/${ticketId}`, body);

  updateAwayTime = (ticketId, body) => this.API.put(`/ticket/${ticketId}/updateAwayTime`, body);
}
