import API from '../api';

export default class MarketingEventService {
  constructor() {
    this.API = new API();
  }

  createMarketingEvent = (body) => this.API.post('/marketingEvent', body);

  updateCommunityEvent = (body) => this.API.post('/marketingEvent/communityEvent', body);

  downloadMarketingStatistics = () => this.API.get('/marketingEvent/statistics');
}
