import { produce } from 'immer';

import {
  LOAD_CONTEST_REQUEST,
  LOAD_CONTEST_SUCCESS,
  LOAD_CONTEST_FAILURE,
  UPDATE_CONTEST_REQUEST,
  UPDATE_CONTEST_SUCCESS,
  UPDATE_CONTEST_FAILURE,
  RESET_UPDATED_IDS,
} from './constants';

const initialState = {
  loading: true,
  error: '',
  contest: {},
  isContestUpdated: false,
};

export default function CMSEditContestReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case LOAD_CONTEST_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case LOAD_CONTEST_SUCCESS:
        state.loading = false;
        state.error = '';
        state.contest = action.data;
        break;
      case LOAD_CONTEST_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case UPDATE_CONTEST_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case UPDATE_CONTEST_SUCCESS:
        state.loading = false;
        state.error = '';
        state.contest = { ...state.contest, ...action.data };
        state.isContestUpdated = true;
        break;
      case UPDATE_CONTEST_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case RESET_UPDATED_IDS:
        state.isContestUpdated = false;
        break;
      default:
        break;
    }
  });
}
