import { produce } from 'immer';

import { GET_ACHIEVEMENTS_REQUEST, GET_ACHIEVEMENTS_SUCCESS, GET_ACHIEVEMENTS_FAILURE } from './constants';

const initialState = {
  loading: false,
  error: '',
  success: true,
  achievements: [],
};
export default function AchievementsPage(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case GET_ACHIEVEMENTS_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        break;
      case GET_ACHIEVEMENTS_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.achievements = action.achievements;
        break;
      case GET_ACHIEVEMENTS_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      default:
        break;
    }
  });
}
