import API from '../api';

export default class DepartmentService {
  constructor() {
    this.API = new API();
  }

  updateDepartment = (id, body) => this.API.put(`/department/${id}`, body);

  createDepartment = (body) => this.API.post('/department', body);

  getDepartment = (id) => this.API.get(`/department/${id}`);

  deleteDepartment = (id) => this.API.delete(`/department/${id}`);
}
