import API from '../api';

export default class AchievementService {
  constructor() {
    this.API = new API();
  }
  getCandidateFeedbacks = (body) => this.API.post('/feedback/', body);

  updateCandidateFeedback = (body) => this.API.post('/feedback/updateFeedback', body);

  createCandidateFeedback = (body) => this.API.post('/feedback/create', body);

  getFeedbackSummary = (body) => this.API.post('/feedback/summary', body);
}
