import React, { useState } from 'react';

import Collapse from 'components/Collapse/Collapse';
import { Container, Wrapper } from 'components/Layout/Layout';
import { Header, HeadingContainer } from 'components/PageTitleHeader/styles';

import { candidatesFaqContent, recruitersFaqContent } from './data';
import { Heading } from './styles';

export default function FAQPage() {
  const [recruiterAccordion, setRecruiterAccordion] = useState(recruitersFaqContent);
  const [candidateAccordion, setCandidateAccordion] = useState(candidatesFaqContent);

  return (
    <Wrapper isNewPage>
      <Container column usePadding="100px 0 0 0">
        <HeadingContainer>
          <Header isNewPage>Frequently Asked Questions</Header>
        </HeadingContainer>

        <Heading>Recruiters' FAQ</Heading>
        {recruiterAccordion.map((question, index) => (
          <Collapse
            key={index}
            title={question.title}
            id={index}
            isCollapsed={!question.active}
            action={() => {
              let temp = [...recruiterAccordion];
              temp[index]['active'] = !temp[index]['active'];
              setRecruiterAccordion(temp);
            }}
          >
            <div>{question.data}</div>
          </Collapse>
        ))}

        <Heading>Candidates' FAQ</Heading>
        {candidateAccordion.map((question, index) => (
          <Collapse
            key={index}
            title={question.title}
            id={index}
            isCollapsed={!question.active}
            action={() => {
              let temp = [...candidateAccordion];
              temp[index]['active'] = !temp[index]['active'];
              setCandidateAccordion(temp);
            }}
          >
            <div>{question.data}</div>
          </Collapse>
        ))}
      </Container>
    </Wrapper>
  );
}
