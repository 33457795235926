export const GET_ME_REQUEST = 'ProfilePage/GET_ME_REQUEST';
export const GET_ME_SUCCESS = 'ProfilePage/GET_ME_SUCCESS';
export const GET_ME_FAILURE = 'ProfilePage/GET_ME_FAILURE';
export const UPDATE_USER_REQUEST = 'ProfilePage/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'ProfilePage/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'ProfilePage/UPDATE_USER_FAILURE';
export const CLEAR_ERROR = 'ProfilePage/CLEAR_ERROR';
export const CLEAR_STATE = 'ProfilePage/CLEAR_STATE';
export const SET_PROFILE = 'ProfilePage/SET_PROFILE';
export const GET_USER_REQUEST = 'ProfilePage/GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'ProfilePage/GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'ProfilePage/GET_USER_FAILURE';
