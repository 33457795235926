import React, { useState } from 'react';
import { withBuilder } from '@builder.io/react';

import { colors } from 'global/colors';
import {
  ArrowContainer,
  Arrow,
  DotContainer,
  Dot,
  ItemsWrapper,
  ItemsContainer,
  Item,
  Overlay,
  UserAvatar,
  UserName,
  UserQuote,
  UserContainer,
  UserWrapper,
} from './styles';
import { useSwipe } from 'hooks/useSwipe';

export const Carousel = ({ items = [], width, renderItem, renderDot, color }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { onSwipeStart, onSwipeMove, onSwipeEnd } = useSwipe((value) => setIndex(currentIndex - value));

  const setIndex = (index) => {
    setCurrentIndex((index + items.length) % items.length);
  };

  return (
    <>
      <ItemsWrapper>
        <Overlay center numItems={items.length} color={color || colors.fsLightBlue} />
        <ItemsContainer
          width={width}
          currentIndex={currentIndex}
          onMouseDown={onSwipeStart}
          onMouseUp={onSwipeEnd}
          onTouchStart={onSwipeStart}
          onTouchMove={onSwipeMove}
          onTouchEnd={onSwipeEnd}
        >
          {items.map((item, index) => (
            <Item key={index}>
              {renderItem ? (
                renderItem(item)
              ) : (
                <UserWrapper key={item.name}>
                  <UserQuote>{item.quote}</UserQuote>
                  <UserContainer>
                    <UserAvatar image={item.avatar} />
                    <UserName>{item.name}</UserName>
                  </UserContainer>
                </UserWrapper>
              )}
            </Item>
          ))}
        </ItemsContainer>
      </ItemsWrapper>
      {items.length > 1 && (
        <>
          <DotContainer>
            {items.map((item, index) =>
              renderDot ? (
                renderDot(item, index, currentIndex === index, () => setIndex(index))
              ) : (
                <Dot key={index} onClick={() => setIndex(index)} isActive={currentIndex === index} />
              )
            )}
          </DotContainer>
          <ArrowContainer>
            <Arrow left onClick={() => setIndex(currentIndex - 1)} />
            <Arrow right onClick={() => setIndex(currentIndex + 1)} />
          </ArrowContainer>
        </>
      )}
    </>
  );
};

export const BuilderCarousel = withBuilder(Carousel, {
  name: 'Carousel',
  inputs: [
    {
      name: 'items',
      type: 'list',
      subFields: [
        { name: 'name', type: 'string' },
        { name: 'quote', type: 'string' },
        { name: 'avatar', type: 'file', allowedFileTypes: ['jpeg', 'png'] },
      ],
    },
    {
      name: 'width',
      type: 'number',
      helperText: 'Width in percentage.',
    },
    {
      name: 'color',
      type: 'color',
      helperText: `Color of the fade effect on the sides, 
        should preferably be set to the same as the background color of the 
        container. Set alpha to 0 to disable fade.`,
    },
  ],
});

export default Carousel;
