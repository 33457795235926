import { produce } from 'immer';
import moment from 'moment';
import _ from 'underscore';

import * as Constants from './constants';
import { questionTypes } from '../../constants/tests';

const initialState = {
  loading: false,
  error: '',
  questionsAttempted: 0,
  success: true,
  ticketResponse: { ticket: {}, test: {} },
  currentQuestionObject: {
    question: { value: '' },
    options: [],
    questionNumber: 1,
  },
  currentOptionId: '',
  textAnswer: '',
  nonAdaptiveQuestionObject: [],
  nextQuestion: {},
  updatedInput: {},
  closedTicket: {},
  reportedQuestion: {},
};

export default function MultichoiceTestPageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case Constants.GET_LOAD_TICKET_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.ticketResponse = { ticket: {}, test: {} };
        break;
      case Constants.GET_LOAD_TICKET_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        let { ticket, test } = action.response;
        ticket.diffToServerTimeInSeconds = moment(new Date()).diff(ticket.serverTime, 'seconds');
        state.ticketResponse = { ticket, test };

        if (ticket && ticket.nextQuestion) {
          state.currentQuestionObject = ticket.nextQuestion;
          state.questionsAttempted = ticket.nextQuestion.questionNumber - 1;
        }
        if (!test.adaptiveTest) {
          let count = 0;
          _.each(ticket.mappedQuestions, (object) => {
            if (object.question.isAnswered) count++;
          });
          state.questionsAttempted = count;
          let activeQuestion = _.find(ticket.mappedQuestions, (object) => {
            if (!object.question.isAnswered) return object;
          });
          state.currentQuestionObject = activeQuestion;
          state.nonAdaptiveQuestionObject = ticket.mappedQuestions;
        }
        break;
      case Constants.GET_LOAD_TICKET_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case Constants.SET_ADAPTIVE_TEST_INPUT_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.nextQuestion = {};
        break;
      case Constants.SET_ADAPTIVE_TEST_INPUT_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.nextQuestion = action.response;
        state.currentQuestionObject = action.response;
        state.questionsAttempted = action.response.questionNumber ? action.response.questionNumber - 1 : 0;
        state.currentOptionId = '';
        break;
      case Constants.SET_ADAPTIVE_TEST_INPUT_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case Constants.SET_NON_ADAPTIVE_TEST_INPUT_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.updatedInput = {};
        break;
      case Constants.SET_NON_ADAPTIVE_TEST_INPUT_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.updatedInput = action.response;
        let updatedInput = action.response;

        let finalQuestionObject = produce(state.nonAdaptiveQuestionObject, (newState) => {
          newState[updatedInput.question].question.isAnswered = true;

          if (newState[updatedInput.question].question.questionType === questionTypes.FREE_TEXT) {
            newState[updatedInput.question].question.textAnswer = updatedInput.textAnswer;
          }

          if (newState[updatedInput.question].question.questionType === questionTypes.ONE_CORRECT_ANSWER) {
            Object.values(newState[updatedInput.question].options).forEach((o) => (o.isAnswered = false));
            newState[updatedInput.question].options[updatedInput.option].isAnswered = true;
          }
        });
        let count = 0;
        _.each(finalQuestionObject, (object) => {
          if (object.question.isAnswered) count++;
        });
        state.questionsAttempted = count;
        state.nonAdaptiveQuestionObject = finalQuestionObject;
        break;
      case Constants.SET_NON_ADAPTIVE_TEST_INPUT_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case Constants.SET_CLOSE_TICKET_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.closedTicket = {};
        break;
      case Constants.SET_CLOSE_TICKET_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.closedTicket = action.ticket;
        state.ticketResponse = { ticket: {}, test: {} };
        break;
      case Constants.SET_CLOSE_TICKET_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case Constants.SET_REPORT_QUESTION_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.reportedQuestion = {};
        break;
      case Constants.SET_REPORT_QUESTION_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.reportedQuestion = action.reportedQuestion;
        break;
      case Constants.SET_REPORT_QUESTION_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case Constants.SET_CURRENT_QUESTION_OBJECT:
        state.currentQuestionObject = action.currentQuestionObject;
        break;
      case Constants.SET_CURRENT_OPTION_ID:
        state.currentOptionId = action.currentOptionId;
        break;
      case Constants.SET_CURRENT_TEXT_ANSWER:
        state.textAnswer = action.textAnswer;
        break;
      default:
        return baseState;
    }
  });
}
