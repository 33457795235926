import { produce } from 'immer';

import {
  LOAD_CMS_CANDIDATE_INFO_REQUEST,
  LOAD_CMS_CANDIDATE_INFO_SUCCESS,
  LOAD_CMS_CANDIDATE_INFO_FAILURE,
  ARCHIVE_SUBMISSION_REQUEST,
  ARCHIVE_SUBMISSION_SUCCESS,
  ARCHIVE_SUBMISSION_FAILURE,
  RESET_UPDATED_IDS,
} from './constants';

const initialState = {
  loading: true,
  error: '',
  candidateInfo: undefined,
  isSubmissionArchived: false,
};

export default function CMSCandidateInfoReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case LOAD_CMS_CANDIDATE_INFO_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case LOAD_CMS_CANDIDATE_INFO_SUCCESS:
        state.loading = false;
        state.error = '';
        state.candidateInfo = action.data;
        break;
      case LOAD_CMS_CANDIDATE_INFO_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case ARCHIVE_SUBMISSION_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case ARCHIVE_SUBMISSION_SUCCESS:
        state.loading = false;
        state.error = '';
        state.isSubmissionArchived = action.data;
        break;
      case ARCHIVE_SUBMISSION_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case RESET_UPDATED_IDS:
        state.isSubmissionArchived = false;
        break;
      default:
        break;
    }
  });
}
