import { produce } from 'immer';

import { sendPrimusRequest } from 'api/PrimusWebSocket';
import {
  AUTH_INITIALIZE,
  AUTH_INITIALIZE_DONE,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_UPDATE_SUCCESS,
  AUTH_TWO_FACTOR,
  AUTH_CLEAR_DATA,
  AUTH_CLEAR_ERROR,
  AUTH_TWO_FACTOR_TIMEOUT,
  LOAD_QR_CODE_REQUEST,
  LOAD_QR_CODE_SUCCESS,
  LOAD_QR_CODE_FAILURE,
  SET_QR_CODE_REQUEST,
  SET_QR_CODE_SUCCESS,
  SET_QR_CODE_FAILURE,
  UPDATE_COMPANY_STATUS,
  UPDATE_WEBSOCKET_STATUS,
} from '../constants';
import { Images } from 'assets/images';

const initialState = {
  isInitialized: false,
  authTwoFactor: false,
  authLoading: false,
  authError: '',
  user: {},
  token: undefined,
  isTicketAuth: undefined,
  userRole: undefined,
  userId: undefined,
  isCommunityUser: false,
  company: undefined,
  department: undefined,
  QRCode: undefined,
  QRResponse: undefined,
  isWebSocketConnected: false,
};

export default function auth(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case AUTH_INITIALIZE:
        state.authLoading = true;
        break;
      case AUTH_INITIALIZE_DONE:
        state.isInitialized = true;
        state.authLoading = false;
        break;
      case AUTH_LOGIN_REQUEST:
        // reset values
        state.user = {};
        state.token = undefined;
        state.isTicketAuth = undefined;
        state.userRole = undefined;
        state.userId = undefined;
        state.isCommunityUser = false;
        state.company = undefined;
        state.department = undefined;
        // set new value
        state.authLoading = true;
        state.authError = '';
        break;
      case AUTH_LOGIN_SUCCESS:
        state.authLoading = false;
        state.authTwoFactor = false;
        let response = action?.response;
        state.user = response?.user;
        if (response?.user) {
          state.token = response.user.token || response.user.session;
          state.isTicketAuth = !!response.user.isTicketAuth || !!response.user.ticketSignIn;
          state.userRole = response.user.role;
          state.userId = response.user._id;
          state.isCommunityUser = !state.isTicketAuth && !!response.user.membershipAgreementAgreed;
          if (!state.user.avatar || state.user.avatar === '/images/placeholder-avatar.png') {
            state.user.avatar = Images.defaultAvatar;
          }
        }
        state.company = response?.company;
        state.department = response?.department;
        // REST sign in success, sign in with primus as well
        sendPrimusRequest({ _handler: 'sign-in' });
        break;
      case AUTH_LOGIN_FAILURE:
        state.authLoading = false;
        state.authError = action.error;
        break;
      case AUTH_TWO_FACTOR:
        state.authTwoFactor = true;
        break;
      case AUTH_TWO_FACTOR_TIMEOUT:
        state.authLoading = false;
        state.authTwoFactor = false;
        state.authError = action.error;
        break;
      case AUTH_CLEAR_DATA:
        state.authTwoFactor = false;
        state.authLoading = false;
        state.authError = '';
        state.user = {};
        state.token = undefined;
        state.isTicketAuth = undefined;
        state.userRole = undefined;
        state.userId = undefined;
        state.isCommunityUser = false;
        state.company = undefined;
        state.department = undefined;
        break;
      case AUTH_CLEAR_ERROR:
        state.authError = '';
        break;
      case AUTH_UPDATE_SUCCESS:
        state.user = { ...state.user, ...action.data };
        // REST update success, sign in with primus as well
        sendPrimusRequest({ _handler: 'sign-in' });
        break;
      case LOAD_QR_CODE_REQUEST:
        state.authLoading = true;
        state.authError = '';
        break;
      case LOAD_QR_CODE_SUCCESS:
        state.authLoading = false;
        state.QRCode = action.data;
        break;
      case LOAD_QR_CODE_FAILURE:
        state.authLoading = false;
        state.error = action.error;
        break;
      case SET_QR_CODE_REQUEST:
        state.authLoading = true;
        state.authError = '';
        break;
      case SET_QR_CODE_SUCCESS:
        state.authLoading = false;
        state.QRResponse = {
          isSuccessful: action.data,
        };
        state.user.isTwoFactorAuthEnabled = true;
        break;
      case SET_QR_CODE_FAILURE:
        state.authLoading = false;
        state.error = action.error;
        break;
      case UPDATE_COMPANY_STATUS:
        if (state?.company?._id === action.payload._id) state.company = action.payload;
        break;
      case UPDATE_WEBSOCKET_STATUS:
        state.isWebSocketConnected = action.payload;
        break;
      default:
        break;
    }
  });
}
