import React from 'react';

import BuilderComponent from 'components/BuilderComponent/BuilderComponent';
import { Wrapper } from 'components/Layout/Layout';

export default function CompanyEventsPage() {
  return (
    <Wrapper>
      <BuilderComponent
        entries={{
          de: 'aceedde55f594e459e1912b896f1da79', // There is no german page, using english
          en: 'aceedde55f594e459e1912b896f1da79',
          sv: '6831efd5c2bb44d59ada57c9b1ca31db',
        }}
      />
    </Wrapper>
  );
}
