import { useRef, useEffect } from 'react';

const focusQuery = [
  'a[href]:not([disabled])',
  'button:not([disabled])',
  'textarea:not([disabled])',
  'input[type="text"]:not([disabled])',
  'input[type="password"]:not([disabled])',
  'input[type="radio"]:not([disabled])',
  'input[type="checkbox"]:not([disabled])',
  'select:not([disabled])',
].join(', ');

const TAB_KEYCODE = 9;
const TAB_NAME = 'Tab';

// modulo that always return positive number
const mod = (n, m) => ((n % m) + m) % m;

/**
 * Hook for handling tab navigation when you want to limit it to be inside a certain element
 * @returns a ref to put on the container element you want to tab inside
 */
export const useFocusTrap = () => {
  const trapRef = useRef(null);

  const trapper = (event) => {
    try {
      const trapRefElem = trapRef.current;
      if (!trapRefElem) return;
      if (event.key !== TAB_NAME && event.keyCode !== TAB_KEYCODE) return;

      let focusableElems = Array.from(trapRefElem.querySelectorAll(focusQuery));
      if (!focusableElems?.length) return;

      let currentIndex = focusableElems.indexOf(document.activeElement) ?? 0;
      let nextIndex = event.shiftKey ? currentIndex - 1 : currentIndex + 1;
      nextIndex = mod(nextIndex, focusableElems.length);
      focusableElems[nextIndex].focus();

      event.preventDefault();
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', trapper);
    return () => {
      window.removeEventListener('keydown', trapper);
    };
  }, []);

  return trapRef;
};
