import React, { useState } from 'react';
import { detect } from 'detect-browser';

import { Modal, Body, Footer, FooterButton } from './modalElements';

const browser = detect();

export default React.memo(() => {
  const [isOpen, setIsOpen] = useState(browser.name === 'ie' || browser.name === 'edge');

  return (
    <Modal isOpen={isOpen}>
      <Body>
        You seem to be using Edge or Internet Explorer. To have a better user experience we recommend using Chrome,
        Safari or Firefox as your web browser.
      </Body>
      <Footer>
        <FooterButton onClick={() => setIsOpen(false)} label="Ok" />
      </Footer>
    </Modal>
  );
});
