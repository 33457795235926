export const GET_LOAD_TICKETS_REQUEST = 'CandidateTicketsPage/GET_LOAD_TICKETS_REQUEST';
export const GET_LOAD_TICKETS_SUCCESS = 'CandidateTicketsPage/GET_LOAD_TICKETS_SUCCESS';
export const GET_LOAD_TICKETS_FAILURE = 'CandidateTicketsPage/GET_LOAD_TICKETS_FAILURE';
export const GET_GDPR_AGREEMENT_CONSENT_REQUEST = 'CandidateTicketsPage/GET_GDPR_AGREEMENT_CONSENT_REQUEST';
export const GET_GDPR_AGREEMENT_CONSENT_SUCCESS = 'CandidateTicketsPage/GET_GDPR_AGREEMENT_CONSENT_SUCCESS';
export const GET_GDPR_AGREEMENT_CONSENT_FAILURE = 'CandidateTicketsPage/GET_GDPR_AGREEMENT_CONSENT_FAILURE';
export const GET_GDPR_AGREEMENT_REQUEST = 'CandidateTicketsPage/GET_GDPR_AGREEMENT_REQUEST';
export const GET_GDPR_AGREEMENT_SUCCESS = 'CandidateTicketsPage/GET_GDPR_AGREEMENT_SUCCESS';
export const GET_GDPR_AGREEMENT_FAILURE = 'CandidateTicketsPage/GET_GDPR_AGREEMENT_FAILURE';
export const SET_GDPR_AGREEMENT_CONSENT_REQUEST = 'CandidateTicketsPage/SET_GDPR_AGREEMENT_CONSENT_REQUEST';
export const SET_GDPR_AGREEMENT_CONSENT_SUCCESS = 'CandidateTicketsPage/SET_GDPR_AGREEMENT_CONSENT_SUCCESS';
export const SET_GDPR_AGREEMENT_CONSENT_FAILURE = 'CandidateTicketsPage/SET_GDPR_AGREEMENT_CONSENT_FAILURE';
export const SET_CANDIDATE_TICKET_LOCATION_REQUEST = 'CandidateTicketsPage/SET_CANDIDATE_TICKET_LOCATION_REQUEST';
export const SET_CANDIDATE_TICKET_LOCATION_SUCCESS = 'CandidateTicketsPage/SET_CANDIDATE_TICKET_LOCATION_SUCCESS';
export const SET_CANDIDATE_TICKET_LOCATION_FAILURE = 'CandidateTicketsPage/SET_CANDIDATE_TICKET_LOCATION_FAILURE';
export const SET_CANDIDATE_CHOOSES_TEST_REQUEST = 'CandidateTicketsPage/SET_CANDIDATE_CHOOSES_TEST_REQUEST';
export const SET_CANDIDATE_CHOOSES_TEST_SUCCESS = 'CandidateTicketsPage/SET_CANDIDATE_CHOOSES_TEST_SUCCESS';
export const SET_CANDIDATE_CHOOSES_TEST_FAILURE = 'CandidateTicketsPage/SET_CANDIDATE_CHOOSES_TEST_FAILURE';
export const SET_SEND_OLD_REPORT_REQUEST = 'CandidateTicketsPage/SET_SEND_OLD_REPORT_REQUEST';
export const SET_SEND_OLD_REPORT_SUCCESS = 'CandidateTicketsPage/SET_SEND_OLD_REPORT_SUCCESS';
export const SET_SEND_OLD_REPORT_FAILURE = 'CandidateTicketsPage/SET_SEND_OLD_REPORT_FAILURE';
export const SET_CREATE_CANDIDATE_FEEDBACK_REQUEST = 'CandidateTicketsPage/SET_CREATE_CANDIDATE_FEEDBACK_REQUEST';
export const SET_CREATE_CANDIDATE_FEEDBACK_SUCCESS = 'CandidateTicketsPage/SET_CREATE_CANDIDATE_FEEDBACK_SUCCESS';
export const SET_CREATE_CANDIDATE_FEEDBACK_FAILURE = 'CandidateTicketsPage/SET_CREATE_CANDIDATE_FEEDBACK_FAILURE';
export const RESET_STATE = 'CandidateTicketsPage/RESET_STATE';
export const UPDATE_TICKET = 'CandidateTicketsPage/UPDATE_TICKET';
