export function ObjectException(message, name) {
  this.message = message;
  this.name = name;
}

ObjectException.prototype.toString = function () {
  return this.name;
};

export const logError = (error) => {
  console.error(error?.toString?.() || error);
};
