import React from 'react';

import BuilderComponent from 'components/BuilderComponent/BuilderComponent';
import { Wrapper } from 'components/Layout/Layout';

export default function AboutPage() {
  return (
    <Wrapper>
      <BuilderComponent
        entries={{
          de: 'b042675cae18480cba2415bc5be8649e',
          en: '6c387bcbd23a4db58798680a78569740',
          sv: 'a4978d53d70f4195ba28590316d56b70',
        }}
      />
    </Wrapper>
  );
}
