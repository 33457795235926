import API from '../api';

export default class FreecodeSubmitService {
  constructor() {
    this.API = new API();
  }

  submitFreecode = (body) => this.API.post('/freecode/submit/freecode', body);

  submitContest = (body) => this.API.post('/freecode/submit/contest', body);
}
