import React from 'react';
import { withBuilder } from '@builder.io/react';

import { Container, ContentContainer, Svg } from './styles';
import { useWindowSizeDebounced } from 'hooks/useWindowSize';

export { CurvedTitle, CurvedSubtitle, CurvedContent } from './styles';

const SVG = ({ image, color, path }) => {
  const id = path.replace(/ /g, '');
  return (
    <Svg width="100%" height="100%">
      <defs>
        <clipPath id={id} clipPathUnits="objectBoundingBox">
          <path d={path} />
        </clipPath>
      </defs>
      {image && (
        <image
          width="100%"
          height="100%"
          xlinkHref={image}
          clipPath={`url(#${id})`}
          preserveAspectRatio="xMidYMid slice"
        />
      )}
      {color && !image && <rect fill={color} width="100%" height="100%" clipPath={`url(#${id})`} />}
    </Svg>
  );
};

export const BuilderSVG = withBuilder(
  ({ image, color, top, reversedTop, bottom, reversedBottom, ratio = 1 }) => {
    const { width } = useWindowSizeDebounced();
    let path = 'M 0 0 V 1 H 1 V 0';

    let resolutionRatio = 1 * ratio;
    if (width < 991 && width > 640) {
      resolutionRatio = 0.75 * (ratio < 1 ? ratio * 1.25 : 1);
    } else if (width < 640) {
      resolutionRatio = 0.5 * (ratio < 1 ? ratio * 1.4 : 1);
    }

    const topPos = 0.05 * resolutionRatio;
    const bottomPos = 1 - topPos;

    const topPos2 = topPos / 2;
    const bottomPos2 = 1 - topPos2;

    const up = 0.18;
    const up2 = (up / 2) * resolutionRatio;
    const up3 = 0.026;

    const down = 1 - up;
    const down2 = 1 - up2;
    const down3 = 1 - up3;

    const curveRatio = 1 + up3;

    if (top && !bottom && !reversedTop && !reversedBottom) {
      path = `M 0 ${topPos2} Q ${up} ${up2}, 0.5 ${up3} T 1 ${topPos} V 1 H 0`;
    } else if (!top && !bottom && reversedTop && !reversedBottom) {
      path = `M 1 ${topPos2} Q ${down} ${up2}, 0.5 ${up3} T 0 ${topPos} V 1 H 1`;
    } else if (!top && bottom && !reversedTop && !reversedBottom) {
      path = `M 0 0 V ${bottomPos2} Q ${up} ${down2}, 0.5 ${down3} T 1 ${bottomPos} V 0`;
    } else if (!top && !bottom && !reversedTop && reversedBottom) {
      path = `M 1 ${bottomPos2} Q ${down} ${down2}, 0.5 ${down3} T 0 ${bottomPos} V 0 H 1`;
    } else if (top && bottom && !reversedTop && !reversedBottom) {
      path = `M 0 ${topPos2} Q ${up} ${up2}, 0.5 ${up3} T 1 ${topPos} V ${bottomPos} Q ${down} ${curveRatio}, 0.5 ${down3} T 0 ${bottomPos2}`;
    } else if (!top && bottom && reversedTop && !reversedBottom) {
      path = `M 1 ${topPos2} Q ${down} ${up2}, 0.5 ${up3} T 0 ${topPos} V ${bottomPos2} Q ${up} ${down2}, 0.5 ${down3} T 1 ${bottomPos}`;
    } else if (top && !bottom && !reversedTop && reversedBottom) {
      path = `M 0 ${topPos2} Q ${up} ${up2}, 0.5 ${up3} T 1 ${topPos} V ${bottomPos2} Q ${down} ${down2}, 0.5 ${down3} T 0 ${bottomPos}`;
    } else if (!top && !bottom && reversedTop && reversedBottom) {
      path = `M 1 ${topPos2} Q ${down} ${up2}, 0.5 ${up3} T 0 ${topPos} V ${bottomPos} Q ${up} ${curveRatio}, 0.5 ${down3} T 1 ${bottomPos2}`;
    }

    return <SVG image={image} color={color} path={path} />;
  },
  {
    name: 'Curved SVG',
    inputs: [
      { name: 'image', type: 'file', allowedFileTypes: ['jpeg', 'png'] },
      {
        name: 'color',
        type: 'color',
        defaultValue: 'rgba(239, 239, 239, 1)',
        helperText: "Is not used if an 'Image' has been set.",
      },
      {
        name: 'top',
        type: 'boolean',
        helperText: "Disable 'Reversed top' if this is enabled.",
      },
      {
        name: 'bottom',
        type: 'boolean',
        defaultValue: true,
        helperText: "Disable 'Reversed bottom' if this is enabled.",
      },
      {
        name: 'reversedTop',
        type: 'boolean',
        helperText: "Disable 'Top' if this is enabled.",
      },
      {
        name: 'reversedBottom',
        type: 'boolean',
        helperText: "Disable 'Bottom' if this is enabled.",
      },
      {
        name: 'ratio',
        type: 'number',
        defaultValue: 1,
        helperText: 'How wide the curve should be, between 0.5 and 1',
      },
    ],
  }
);

export default function CurvedContainer({
  color,
  top,
  bottom,
  reversedTop,
  reversedBottom,
  children,
  image,
  height,
  style,
  full,
}) {
  const { width } = useWindowSizeDebounced();
  let path = 'M 0 0 V 1 H 1 V 0';

  let ratio = 1;
  if (width < 991 && width > 640) {
    ratio = 0.75;
  } else if (width < 640) {
    ratio = 0.5;
  }

  const topPos = 0.05 * ratio;
  const bottomPos = 1 - topPos;

  const up = 0.18;
  const up2 = (up / 2) * ratio;
  const up3 = 0.026;

  const down = 1 - up;
  const down2 = 1 - up2;
  const down3 = 1 - up3;

  const curveRatio = 1 + up3;

  if (top && !bottom && !reversedTop && !reversedBottom) {
    path = `M 0 0 Q ${up} ${up2}, 0.5 ${up3} T 1 ${topPos} V 1 H 0`;
  } else if (!top && !bottom && reversedTop && !reversedBottom) {
    path = `M 1 0 Q ${down} ${up2}, 0.5 ${up3} T 0 ${topPos} V 1 H 1`;
  } else if (!top && bottom && !reversedTop && !reversedBottom) {
    path = `M 0 0 V 1 Q ${up} ${down2}, 0.5 ${down3} T 1 ${bottomPos} V 0`;
  } else if (!top && !bottom && !reversedTop && reversedBottom) {
    path = `M 1 1 Q ${down} ${down2}, 0.5 ${down3} T 0 ${bottomPos} V 0 H 1`;
  } else if (top && bottom && !reversedTop && !reversedBottom) {
    path = `M 0 0 Q ${up} ${up2}, 0.5 ${up3} T 1 ${topPos} V ${bottomPos} Q ${down} ${curveRatio}, 0.5 ${down3} T 0 1`;
  } else if (!top && bottom && reversedTop && !reversedBottom) {
    path = `M 1 0 Q ${down} ${up2}, 0.5 ${up3} T 0 ${topPos} V 1 Q ${up} ${down2}, 0.5 ${down3} T 1 ${bottomPos}`;
  } else if (top && !bottom && !reversedTop && reversedBottom) {
    path = `M 0 0 Q ${up} ${up2}, 0.5 ${up3} T 1 ${topPos} V 1 Q ${down} ${down2}, 0.5 ${down3} T 0 ${bottomPos}`;
  } else if (!top && !bottom && reversedTop && reversedBottom) {
    path = `M 1 0 Q ${down} ${up2}, 0.5 ${up3} T 0 ${topPos} V ${bottomPos} Q ${up} ${curveRatio}, 0.5 ${down3} T 1 1`;
  }

  return (
    <Container style={style}>
      <SVG path={path} image={image} color={color} />
      <ContentContainer full={full} height={height}>
        {children}
      </ContentContainer>
    </Container>
  );
}
