export const LOAD_CMS_CONTESTS_REQUEST = 'CMSAllContests/LOAD_CMS_CONTESTS_REQUEST';
export const LOAD_CMS_CONTESTS_SUCCESS = 'CMSAllContests/LOAD_CMS_CONTESTS_SUCCESS';
export const LOAD_CMS_CONTESTS_FAILURE = 'CMSAllContests/LOAD_CMS_CONTESTS_FAILURE';
export const CREATE_CMS_CONTEST_REQUEST = 'CMSAllContests/CREATE_CMS_CONTEST_REQUEST';
export const CREATE_CMS_CONTEST_SUCCESS = 'CMSAllContests/CREATE_CMS_CONTEST_SUCCESS';
export const CREATE_CMS_CONTEST_FAILURE = 'CMSAllContests/CREATE_CMS_CONTEST_FAILURE';
export const DELETE_CMS_CONTEST_REQUEST = 'CMSAllContests/DELETE_CMS_CONTEST_REQUEST';
export const DELETE_CMS_CONTEST_SUCCESS = 'CMSAllContests/DELETE_CMS_CONTEST_SUCCESS';
export const DELETE_CMS_CONTEST_FAILURE = 'CMSAllContests/DELETE_CMS_CONTEST_FAILURE';
export const RESET_UPDATED_IDS = 'CMSAllContests/RESET_UPDATED_IDS';
