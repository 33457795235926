import styled, { css } from 'styled-components';

import { colors } from 'global/colors';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3;
`;

export const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

export const ContentContainer = styled.div`
  width: 100%;
  padding: 0 15%;
  height: ${(props) => `${props.height}px` || '100%'};

  ${(props) =>
    props.full &&
    css`
      padding: 0;
    `}

  @media (max-width: 1366px) {
    padding: 0 20px;
  }

  @media (max-width: 667px) {
    padding: 0 10px;
  }
`;

export const CurvedTitle = styled.h1`
  color: ${colors.white};
  text-transform: uppercase;

  ${(props) =>
    props.thin &&
    css`
      font-weight: 300;
      > strong {
        font-weight: bold;
      }
    `}

  ${(props) =>
    props.small &&
    css`
      font-size: 30px;
    `}
`;

export const CurvedSubtitle = styled.h3`
  color: ${colors.white};
  font-weight: 600;

  ${(props) =>
    props.thin &&
    css`
      font-weight: normal;
    `}

  ${(props) =>
    props.marginTop &&
    css`
      margin-top: 10px;
    `}

  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: 40px;
    `}

  ${(props) =>
    props.small &&
    css`
      font-size: 16px;
    `}
`;

export const CurvedContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  filter: drop-shadow(2px 4px 6px black);

  ${(props) =>
    props.left &&
    css`
      text-align: left;
      align-items: flex-start;
    `}

  ${(props) =>
    props.half &&
    css`
      width: 45%;

      @media (max-width: 1024px) {
        width: 100%;
      }
    `}

  ${(props) =>
    props.full &&
    css`
      justify-content: flex-start;
    `}

  ${(props) =>
    !props.full &&
    !props.keepCentered &&
    css`
      @media (max-width: 576px) {
        text-align: left;
        align-items: flex-start;
      }
    `}
`;
