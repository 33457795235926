import API from '../api';

export default class ChallengeService {
  constructor() {
    this.API = new API();
  }

  // challenges are loaded from contests while other types are tests
  getAllItems = (type, isKodcentrumCampaign) =>
    this.API.get(type === 'challenges' ? '/contest/' : `/test/${type}?isKodcentrumCampaign=${isKodcentrumCampaign}`);

  // challenges are loaded from contests while other types are tests
  getItem = (id, type) => this.API.get(type === 'challenges' ? `/contest/${id}` : `/test/${type}/${id}`);

  setItemRating = (rating, testId) => this.API.post(`/testRating/${testId}`, { rating });

  getTournamentPlayback = (contest, account) =>
    this.API.get(`/tournamentMatch/getTournamentPlayback/${contest}/${account}/20`);

  getHackathonMatches = (contestId) => this.API.get(`/tournamentMatch/getHackathonMatches/${contestId}`);

  getContestRedirectPath = (shortPath) => this.API.get(`/contest/redirect/${shortPath}`);

  getContestsForCMS = (id) => this.API.get(`/contest/cmsContests/${id}`);

  createContest = (id) => this.API.post(`/contest/${id}`);

  deleteContest = (id) => this.API.delete(`/contest/${id}`);

  getContestForCMS = (contestId) => this.API.get(`/contest/cmsContest/${contestId}`);

  getChallengeRankList = (contestId) => this.API.get(`/contest/getChallengeRankList/${contestId}`);

  getTournamentRankList = (contestId) => this.API.get(`/contest/getTournamentRankList/${contestId}`);

  getContestForEdit = (id) => this.API.get(`/contest/editContest/${id}`);

  updateContest = (body) => this.API.put(`/contest/updateContest`, body);

  runTournament = (contestId, body) => this.API.post(`/contest/runTournament/${contestId}`, body);

  continuousRunningTournament = (contestId, body) =>
    this.API.post(`/contest/continuousRunningTournament/${contestId}`, body);
}
