import API from '../api';

export default class QuestionFeedbackService {
  constructor() {
    this.API = new API();
  }
  getQuestionFeedbacks = () => this.API.get('/questionFeedback/');

  updateQuestionFeedback = (body) => this.API.put('/questionFeedback/', body);
}
