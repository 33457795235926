import styled, { css } from 'styled-components';

import { colors } from 'global/colors';

// z-index 9998 used since toasts use 9999
export const ModalBackground = styled.div`
  position: fixed !important;
  display: flex;
  justify-content: center;
  align-items: start;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9998;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  ${({ backgroundAccess }) => backgroundAccess && 'pointer-events: none;'}

  ${({ isFreecodeModal }) =>
    isFreecodeModal &&
    css`
      backdrop-filter: blur(2px);
    `}
`;

export const ModalContainer = styled.div`
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 15px 30px 5px rgba(50, 50, 50, 0.5);
  color: ${({ theme }) => theme.modal.body};
  background: ${({ theme }) => theme.modal.background};
  border: 1px solid ${({ theme }) => theme.modal.border};
  margin-top: 5vw;
  max-height: 90vh !important;
  width: min(95vw, 550px);
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  font-family: 'IBM Plex Sans', sans-serif;
  pointer-events: auto;

  ${({ isFreecodeModal }) =>
    isFreecodeModal &&
    css`
      color: ${({ theme }) => theme.freecode.modal.color};
      background: ${({ theme }) => theme.freecode.modal.background};
      border: 0px;
    `}
`;

export const ModalContainerHeightBox = styled.div`
  height: 100% !important;
  max-height: 90vh !important;
`;

export const ModalHeader = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.modal.separator};

  ${({ isFreecodeModal }) =>
    isFreecodeModal &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.freecode.headerDropShadow};
    `}
`;

export const ModalFooter = styled.div`
  border-radius: 0px 0px 5px 5px;
  padding: 1rem;
  border-top: 1px solid ${({ theme }) => theme.modal.separator};

  ${({ isFreecodeModal }) =>
    isFreecodeModal &&
    css`
      border-top: 1px solid ${({ theme }) => theme.freecode.headerDropShadow} !important;
    `}
`;

export const ModalTitle = styled.label`
  color: ${({ theme }) => theme.modal.header} !important;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.7px;

  ${({ isFreecodeModal }) =>
    isFreecodeModal &&
    css`
      color: ${({ theme }) => theme.freecode.modal.header} !important;
      margin-bottom: 0;
    `}
`;

export const ModalContent = styled.div`
  font-size: 15px;
  font-weight: 400;
  width: 100%;
`;

export const ModalBody = styled.div`
  max-height: calc(90vh - 230px);
  overflow-y: ${(props) => (props.noOverflow ? 'visible' : 'auto')};
  position: static;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  padding: 1rem;
`;

export const ModalFooterSpacer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: end;
  gap: 5px;
`;

export const ModalTextArea = styled.textarea`
  box-sizing: border-box;
  color: ${({ theme }) => theme.text};
  background: ${({ theme }) => theme.pageBackground};
  border: 1px solid ${colors.gray};
  padding: 0.375rem 0.75rem;
  border-radius: 5px;
  width: 100%;
  min-height: 100px;
  max-height: 100%;
  :focus {
    outline: none !important;
  }
`;

export const FeedbackModalBody = styled.div`
  max-height: 500px;
  overflow: auto;
  padding: 20px;

  img {
    width: 100%;
  }
`;

export const LabelHeading = styled.span`
  font-weight: 600;
`;
