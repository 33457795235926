import _ from 'underscore';
import { produce } from 'immer';

import * as Constants from './constants';
import { categories } from 'constants/tests';

const initialState = {
  loading: false,
  error: '',
  success: false,
  categoryWiseTickets: {},
  gdprAgreement: undefined,
  gdprAgreementConsent: undefined,
  ticketWithLocation: {},
  updatedChosenTestTicket: {},
  updatedSendOlReportTicket: {},
  feedback: {},
  companyLogo: '',
};

export default function CandidateTicketsPageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case Constants.GET_LOAD_TICKETS_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.categoryWiseTickets = {};
        break;
      case Constants.GET_LOAD_TICKETS_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.categoryWiseTickets = action.data.tickets;
        state.companyLogo = action.data.companyLogo;
        break;
      case Constants.GET_LOAD_TICKETS_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case Constants.GET_GDPR_AGREEMENT_CONSENT_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.gdprAgreementConsent = undefined;
        break;
      case Constants.GET_GDPR_AGREEMENT_CONSENT_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.gdprAgreementConsent = action.gdprAgreementConsent;
        break;
      case Constants.GET_GDPR_AGREEMENT_CONSENT_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        state.gdprAgreementConsent = undefined;
        break;
      case Constants.GET_GDPR_AGREEMENT_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.gdprAgreement = undefined;
        break;
      case Constants.GET_GDPR_AGREEMENT_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.gdprAgreement = action.agreement;
        break;
      case Constants.GET_GDPR_AGREEMENT_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        state.gdprAgreement = undefined;
        break;
      case Constants.SET_GDPR_AGREEMENT_CONSENT_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.gdprAgreementConsent = undefined;
        break;
      case Constants.SET_GDPR_AGREEMENT_CONSENT_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.gdprAgreementConsent = action.gdpr;
        break;
      case Constants.SET_GDPR_AGREEMENT_CONSENT_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        state.gdprAgreementConsent = undefined;
        break;
      case Constants.SET_CANDIDATE_TICKET_LOCATION_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.ticketWithLocation = {};
        break;
      case Constants.SET_CANDIDATE_TICKET_LOCATION_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.ticketWithLocation = action.ticket;
        break;
      case Constants.SET_CANDIDATE_TICKET_LOCATION_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case Constants.SET_CANDIDATE_CHOOSES_TEST_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.updatedChosenTestTicket = {};
        break;
      case Constants.SET_CANDIDATE_CHOOSES_TEST_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.updatedChosenTestTicket = action.ticket;
        let existingTickets = JSON.parse(JSON.stringify(state.categoryWiseTickets));
        let index = _.findIndex(existingTickets[categories.MULTICHOICE.category].tickets, {
          _id: action.ticket._id,
        });
        existingTickets[categories.MULTICHOICE.category].tickets[index] = action.ticket;
        state.categoryWiseTickets = existingTickets;
        break;
      case Constants.SET_CANDIDATE_CHOOSES_TEST_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case Constants.SET_SEND_OLD_REPORT_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.updatedSendOlReportTicket = {};
        break;
      case Constants.SET_SEND_OLD_REPORT_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        existingTickets = JSON.parse(JSON.stringify(state.categoryWiseTickets));
        index = _.findIndex(existingTickets[action.ticket.category].tickets, {
          _id: action.ticket._id,
        });
        existingTickets[action.ticket.category].tickets[index] = action.ticket;
        state.categoryWiseTickets = existingTickets;
        state.updatedSendOlReportTicket = action.ticket;
        break;
      case Constants.SET_SEND_OLD_REPORT_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case Constants.SET_CREATE_CANDIDATE_FEEDBACK_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.feedback = {};
        break;
      case Constants.SET_CREATE_CANDIDATE_FEEDBACK_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.feedback = action.feedback;
        break;
      case Constants.SET_CREATE_CANDIDATE_FEEDBACK_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;

      case Constants.UPDATE_TICKET:
        let oldTickets = JSON.parse(JSON.stringify(state.categoryWiseTickets));

        if (oldTickets?.[categories.CODE.category]?.tickets?.length)
          oldTickets[categories.CODE.category].tickets = replaceTicket(
            oldTickets[categories.CODE.category].tickets,
            action.payload
          );

        if (oldTickets?.[categories.MULTICHOICE.category]?.tickets.length)
          oldTickets[categories.MULTICHOICE.category].tickets = replaceTicket(
            oldTickets[categories.MULTICHOICE.category].tickets,
            action.payload
          );

        if (oldTickets?.[categories.GENERAL_SKILLS.category]?.tickets?.length)
          oldTickets[categories.GENERAL_SKILLS.category].tickets = replaceTicket(
            oldTickets[categories.GENERAL_SKILLS.category].tickets,
            action.payload
          );

        state.categoryWiseTickets = oldTickets;
        break;
      case Constants.RESET_STATE:
        for (const key of Object.keys(initialState)) {
          state[key] = initialState[key];
        }
        break;
      default:
        break;
    }
  });
}

function replaceTicket(listOfTickets, replacementTicket) {
  let newListOfTickets = _.reduce(
    listOfTickets ?? [],
    function (memo, current) {
      if (String(current._id) === String(replacementTicket._id)) {
        memo.push({
          ...replacementTicket,
          testName: current.testName,
          duration: current.duration,
          scale: current.scale,
        });
      } else {
        memo.push(current);
      }
      return memo;
    },
    []
  );
  return newListOfTickets;
}
