import React, { useState, useEffect, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';

import { darkTheme } from 'global/themeDark';
import { lightTheme } from 'global/themeLight';
import { getCookie } from 'functions/getCookie';
import { getConfig } from 'functions/getConfig';

export const AppContext = React.createContext();

const getDefaultLanguage = () => {
  // split since we are not interested in the dialect such as en-UK, ge-AU just the main language
  const browserLanguage = window.navigator.language.split('-')[0];
  return localStorage.getItem('language') || (['sv', 'en', 'de'].includes(browserLanguage) ? browserLanguage : 'en');
};

const getDefaultDarkMode = () => {
  try {
    let storedValue = getCookie('isDarkMode');
    if (storedValue) return JSON.parse(storedValue);
  } catch (_ignore) {
    // parsing stored value failed
  }
  // Try to get dark mode preference from system
  return !!window?.matchMedia?.('(prefers-color-scheme: dark)')?.matches;
};

const getActiveTheme = (isDarkMode) => {
  if (isDarkMode) return darkTheme;
  return lightTheme;
};

export const AppContextProvider = ({ children }) => {
  const [language, setLanguage] = useState(getDefaultLanguage());
  const [isDarkMode, setIsDarkMode] = useState(getDefaultDarkMode());

  const theme = useMemo(() => getActiveTheme(isDarkMode), [isDarkMode]);

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  const toggleDarkMode = () => {
    let newValue = !isDarkMode;
    document.cookie = `isDarkMode=${newValue}; Domain=${getConfig().COOKIE}; Max-Age=34560000000; Path=/; SameSite=Lax`;
    setIsDarkMode(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={{ language, setLanguage, isDarkMode, toggleDarkMode }}>
        {children}
      </AppContext.Provider>
    </ThemeProvider>
  );
};
