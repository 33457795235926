import { produce } from 'immer';

import {
  GET_PLAN_REQUEST,
  GET_PLAN_SUCCESS,
  GET_PLAN_FAILURE,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILURE,
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_FAILURE,
  UPDATE_SUCCESSFUL_TRANSACTION_REQUEST,
  UPDATE_SUCCESSFUL_TRANSACTION_SUCCESS,
  UPDATE_SUCCESSFUL_TRANSACTION_FAILURE,
  UPDATE_FAILED_TRANSACTION_REQUEST,
  UPDATE_FAILED_TRANSACTION_SUCCESS,
  UPDATE_FAILED_TRANSACTION_FAILURE,
} from './constants';

const initialState = {
  loading: false,
  error: '',
  plan: {},
  updatedCompany: {},
  payment: {},
  successResponse: {},
  failedResponse: undefined,
};

export default function PlansPageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case GET_PLAN_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case GET_PLAN_SUCCESS:
        state.loading = false;
        state.error = '';
        state.plan = action.data;
        break;
      case GET_PLAN_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case UPDATE_COMPANY_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case UPDATE_COMPANY_SUCCESS:
        state.loading = false;
        state.error = '';
        state.updatedCompany = action.data;
        break;
      case UPDATE_COMPANY_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case CREATE_PAYMENT_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case CREATE_PAYMENT_SUCCESS:
        state.loading = false;
        state.error = '';
        state.payment = action.data;
        break;
      case CREATE_PAYMENT_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case UPDATE_SUCCESSFUL_TRANSACTION_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case UPDATE_SUCCESSFUL_TRANSACTION_SUCCESS:
        state.loading = false;
        state.error = '';
        state.successResponse = action.data;
        break;
      case UPDATE_SUCCESSFUL_TRANSACTION_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case UPDATE_FAILED_TRANSACTION_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case UPDATE_FAILED_TRANSACTION_SUCCESS:
        state.loading = false;
        state.error = '';
        state.failedResponse = action.data;
        break;
      case UPDATE_FAILED_TRANSACTION_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      default:
        break;
    }
  });
}
