export const LOAD_TESTS_REQUEST = 'CustomTestsPage/LOAD_TESTS_REQUEST';
export const LOAD_TESTS_SUCCESS = 'CustomTestsPage/LOAD_TESTS_SUCCESS';
export const LOAD_TESTS_FAILURE = 'CustomTestsPage/LOAD_TESTS_FAILURE';
export const PUBLISH_AND_ARCHIVE_TEST_REQUEST = 'CustomTestsPage/PUBLISH_AND_ARCHIVE_TEST_REQUEST';
export const PUBLISH_AND_ARCHIVE_TEST_SUCCESS = 'CustomTestsPage/PUBLISH_AND_ARCHIVE_TEST_SUCCESS';
export const PUBLISH_AND_ARCHIVE_TEST_FAILURE = 'CustomTestsPage/PUBLISH_AND_ARCHIVE_TEST_FAILURE';
export const COPY_TEST_REQUEST = 'CustomTestsPage/COPY_TEST_REQUEST';
export const COPY_TEST_SUCCESS = 'CustomTestsPage/COPY_TEST_SUCCESS';
export const COPY_TEST_FAILURE = 'CustomTestsPage/COPY_TEST_FAILURE';
export const CREATE_TEST_REQUEST = 'CustomTestsPage/CREATE_TEST_REQUEST';
export const CREATE_TEST_SUCCESS = 'CustomTestsPage/CREATE_TEST_SUCCESS';
export const CREATE_TEST_FAILURE = 'CustomTestsPage/CREATE_TEST_FAILURE';
export const RESET_NEW_AND_COPIED_TEST = 'CustomTestsPage/RESET_NEW_AND_COPIED_TEST';
