import { produce } from 'immer';
import _ from 'underscore';

import {
  LOAD_QUESTION_FEEDBACK_REQUEST,
  LOAD_QUESTION_FEEDBACK_SUCCESS,
  LOAD_QUESTION_FEEDBACK_FAILURE,
  UPDATE_QUESTION_FEEDBACK_REQUEST,
  UPDATE_QUESTION_FEEDBACK_SUCCESS,
  UPDATE_QUESTION_FEEDBACK_FAILURE,
} from './constants';

const initialState = {
  loading: false,
  error: '',
  feedbacks: [],
  tests: {},
  questions: {},
  accounts: {},
  feedback: {},
};

export default function QuestionFeedbackPageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case LOAD_QUESTION_FEEDBACK_REQUEST:
        state.loading = true;
        state.error = '';
        state.payments = [];
        break;
      case LOAD_QUESTION_FEEDBACK_SUCCESS:
        state.loading = false;
        state.error = '';
        state.feedbacks = action.data.feedbacks;
        state.tests = action.data.tests;
        state.questions = action.data.questions;
        state.accounts = action.data.accounts;
        break;
      case LOAD_QUESTION_FEEDBACK_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.feedbacks = [];
        state.tests = {};
        state.questions = {};
        state.accounts = {};
        break;
      case UPDATE_QUESTION_FEEDBACK_REQUEST:
        state.loading = true;
        state.error = '';
        state.feedback = {};
        break;
      case UPDATE_QUESTION_FEEDBACK_SUCCESS:
        state.loading = false;
        state.error = '';
        let index = _.findIndex(state.feedbacks, (feedback) => feedback._id === action.data._id);
        state.feedbacks[index].updatedAt = action.data.updatedAt;
        if (action.data.updateMessage) state.feedbacks[index].updateMessage = action.data.updateMessage;
        if (action.data.rejectMessage) state.feedbacks[index].rejectMessage = action.data.rejectMessage;
        state.feedback = action.data;
        break;
      case UPDATE_QUESTION_FEEDBACK_FAILURE:
        state.loading = false;
        state.error = '';
        state.feedback = {};
        break;
      default:
        break;
    }
  });
}
