import { produce } from 'immer';

import {
  DELETE_CANDIDATE_DATA_REQUEST,
  DELETE_CANDIDATE_DATA_SUCCESS,
  DELETE_CANDIDATE_DATA_FAILURE,
} from './constants';

const initialState = {
  loading: false,
  deleteResponse: '',
  error: '',
};

export default function GDPROptOutPageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case DELETE_CANDIDATE_DATA_REQUEST:
        state.loading = true;
        state.error = '';
        state.deleteResponse = '';
        break;
      case DELETE_CANDIDATE_DATA_SUCCESS:
        state.loading = false;
        state.error = '';
        state.deleteResponse = action.response;
        break;
      case DELETE_CANDIDATE_DATA_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      default:
        break;
    }
  });
}
