import React from 'react';
import { Route, Routes } from 'react-router-dom';

import CompanyPage from 'pages/CompanyPage/CompanyPage';
import CompanyEventsPage from 'pages/CompanyEventsPage/CompanyEventsPage';
import MembershipPage from 'pages/MembershipPage/MembershipPage';
import RedirectPage from 'pages/RedirectPage/RedirectPage';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import CookiesPage from 'pages/CookiesPage/CookiesPage';
import ContactPage from 'pages/ContactPage/ContactPage';
import PolicyPage from 'pages/PolicyPage';
import AboutPage from 'pages/AboutPage/AboutPage';
import FAQPage from 'pages/FAQPage/FAQPage';
import CommunityRoute from './CommunityRoute';
import Path from './Path';
import App from '../App';

export const Router = () => {
  return (
    <App>
      <Routes>
        <Route path={Path.defaultHandler} element={<CommunityRoute component={NotFoundPage} />} />
        <Route path={Path.notFoundPage} element={<CommunityRoute component={NotFoundPage} />} />
        <Route path={Path.companyPage} element={<CommunityRoute component={CompanyPage} zendesk="open" />} />
        <Route path={Path.companyEventsPage} element={<CommunityRoute component={CompanyEventsPage} />} />
        <Route path={Path.membershipPage} element={<CommunityRoute component={MembershipPage} />} />
        <Route path={Path.cookiesPage} element={<CommunityRoute component={CookiesPage} />} />
        <Route path={Path.contactPage} element={<CommunityRoute component={ContactPage} zendesk="open" />} />
        <Route path={Path.policyPage} element={<CommunityRoute component={PolicyPage} />} />
        <Route path={Path.aboutPage} element={<CommunityRoute component={AboutPage} zendesk="open" />} />
        <Route path={Path.faqPage} element={<CommunityRoute component={FAQPage} />} />
        <Route path={Path.redirect} element={<CommunityRoute component={RedirectPage} />} />
      </Routes>
    </App>
  );
};
