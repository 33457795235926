import React from 'react';

import { Button } from 'components/Button/RoundButton';
import { Spinner } from 'components/Spinner/Spinner';
import { useFocusTrap } from 'hooks/useFocusTrap';
import {
  ModalBackground,
  ModalContainer,
  ModalContainerHeightBox,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalFooterSpacer,
  ModalTitle,
} from './styles';

export const Modal = ({ children, isOpen = true, backgroundAccess = false, isFreecodeModal = false }) => {
  const modalRef = useFocusTrap();

  return (
    isOpen && (
      <ModalBackground ref={modalRef} backgroundAccess={backgroundAccess} isFreecodeModal={isFreecodeModal}>
        <ModalContainer isFreecodeModal={isFreecodeModal}>
          <ModalContainerHeightBox>{children}</ModalContainerHeightBox>
        </ModalContainer>
      </ModalBackground>
    )
  );
};

export const Header = ({ isFreecodeModal = false, children }) => (
  <ModalHeader isFreecodeModal={isFreecodeModal}>
    <ModalTitle isFreecodeModal={isFreecodeModal}>{children}</ModalTitle>
  </ModalHeader>
);

export const Body = ({ children, loading, noOverflow = false }) => (
  <ModalBody noOverflow={noOverflow}>{loading ? <Spinner height="150px" /> : children}</ModalBody>
);

export const Footer = ({ children, isFreecodeModal }) => (
  <ModalFooter isFreecodeModal={isFreecodeModal}>
    <ModalFooterSpacer>{children}</ModalFooterSpacer>
  </ModalFooter>
);

export const FooterButton = ({ label, onClick, disabled, selected = false, autoFocus }) => (
  <Button
    small
    primaryBlue
    primaryGreenBorder
    removeMargin
    type="submit"
    onClick={onClick}
    disabled={disabled}
    selected={selected}
    autoFocus={autoFocus}
  >
    {label}
  </Button>
);
