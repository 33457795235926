import API from '../api';

export default class TestService {
  constructor() {
    this.API = new API();
  }
  getPublishedTests = () => this.API.get('/test/getPublishedTests');

  getTests = () => this.API.get('/test/loadTests');

  getTestsOfSendTestPage = () => this.API.get('/test/loadTestsOfSendTestPage');

  publishAndArchiveTest = (id, body) => this.API.put(`/test/${id}/publishAndArchiveTest`, body);

  copyFreecode = (id) => this.API.post(`/test/copyFreecode/${id}`);

  copyMultichoice = (id) => this.API.post(`/test/copyMultichoice/${id}`);

  createFreecodeTest = (body) => this.API.post(`/test/createFreecodeTest`, body);

  createMultichoiceTest = (body) => this.API.post(`/test/createMultichoiceTest`, body);

  getAllChallengesForCMS = () => this.API.get(`/contest/cmsChallenges`);

  loadMCQTest = (testId, questionId) => this.API.get(`/multichoice/${testId}/${questionId ? questionId : ''}`);

  updateMCQTest = (testId, body) => this.API.put(`/multichoice/${testId}`, body);

  deleteOption = (optionId) => this.API.delete(`/multichoice/delete-option/${optionId}`);

  deleteQuestion = (questionId) => this.API.delete(`/multichoice/delete-question/${questionId}`);

  removeParagraph = (questionId) => this.API.put(`/multichoice/remove-paragraph/${questionId}`);
}
