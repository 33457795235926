import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useActions } from 'hooks/useActions';
import { useQueryParams } from 'hooks/useQueryParams';
import { ToastifyConfiguration } from './functions/toastifyConfiguration';
import { AppContextProvider } from './components/AppContextProvider/AppContextProvider';
import BrowserWarningModal from './components/Modals/browserWarningModal';
import CookieModal from './components/Modals/cookieModal';
import { getConfig } from './functions/getConfig';
import * as AuthActions from 'global/actions/authActions';
import PrimusWebSocket from './api/PrimusWebSocket';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import { GlobalStyle, AppMainDiv } from './styles';
import { sendAnalytics } from './functions/sendAnalytics';
import { sendPrimusRequest } from 'api/PrimusWebSocket';

export default function App({ children }) {
  const { logout: forceLogout, c: campaignSource } = useQueryParams();
  const campaignSourceRef = useRef(campaignSource);
  const { pathname } = useLocation();

  const { token, isInitialized, authLoading, authTwoFactor, isWebSocketConnected, userId } = useSelector(
    (state) => state.auth
  );

  const { initialize, verifyAuthState, clearAuthState } = useActions(AuthActions);

  useEffect(() => {
    if (document.referrer && !campaignSourceRef.current && !document.referrer.includes(window.location.hostname)) {
      campaignSourceRef.current = document.referrer;
    }
    if (pathname.includes('/school/')) {
      const path = pathname.split('/');
      campaignSourceRef.current = `${path[1]}-${path[2]}`;
    }
    if (pathname === '/') sendAnalytics('Home Page');
    if (!pathname || !campaignSourceRef.current) return;
    document.cookie = `campaignSource=${campaignSourceRef.current}; Domain=${
      getConfig().COOKIE
    }; Max-Age=2592000; Path=/; SameSite=Lax`;
    sendAnalytics(`Enterpage-${pathname}`, '', false);
  }, [pathname]);

  useEffect(() => {
    if (forceLogout) {
      //logs out the user and resets the url if the logout params is passed (only used when logging out in angularjs)
      clearAuthState();
      localStorage.clear();
      window.history.replaceState(null, null, window.location.pathname);
      return;
    }
    initialize();
  }, [clearAuthState, forceLogout, initialize]);

  useEffect(() => {
    if (isInitialized && !authLoading && !authTwoFactor && token && pathname) {
      verifyAuthState(token);
    }
  }, [authLoading, authTwoFactor, isInitialized, pathname, token, verifyAuthState]);

  useEffect(() => {
    if (isWebSocketConnected && userId) {
      sendPrimusRequest({
        operation: 'joinUserRooms',
        _handler: 'ticket-primus-room-connection',
      });
    }
  }, [isWebSocketConnected, userId]);

  return (
    <AppContextProvider>
      <GlobalStyle />
      <AppMainDiv>
        <BrowserWarningModal />
        <ScrollToTop />
        <CookieModal />
        <ToastifyConfiguration />
        {children}
        <PrimusWebSocket />
      </AppMainDiv>
    </AppContextProvider>
  );
}
