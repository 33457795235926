export const LOAD_CONTEST_REQUEST = 'CMSResult/LOAD_CONTEST_REQUEST';
export const LOAD_CONTEST_SUCCESS = 'CMSResult/LOAD_CONTEST_SUCCESS';
export const LOAD_CONTEST_FAILURE = 'CMSResult/LOAD_CONTEST_FAILURE';
export const LOAD_RANK_LIST_REQUEST = 'CMSResult/LOAD_RANK_LIST_REQUEST';
export const LOAD_RANK_LIST_SUCCESS = 'CMSResult/LOAD_RANK_LIST_SUCCESS';
export const LOAD_RANK_LIST_FAILURE = 'CMSResult/LOAD_RANK_LIST_FAILURE';
export const CREATE_RUN_REQUEST = 'CMSResult/CREATE_RUN_REQUEST';
export const CREATE_RUN_SUCCESS = 'CMSResult/CREATE_RUN_SUCCESS';
export const CREATE_RUN_FAILURE = 'CMSResult/CREATE_RUN_FAILURE';
export const CREATE_CONTINUOUS_REQUEST = 'CMSResult/CREATE_CONTINUOUS_REQUEST';
export const CREATE_CONTINUOUS_SUCCESS = 'CMSResult/CREATE_CONTINUOUS_SUCCESS';
export const CREATE_CONTINUOUS_FAILURE = 'CMSResult/CREATE_CONTINUOUS_FAILURE';
