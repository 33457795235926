import _ from 'underscore';

import services from 'api/services';
const { InputService } = services;

export const saveFlowProgressInLocalStorage = (data) => {
  let progress = JSON.parse(localStorage.getItem(`fs-flow-progress-${data.test}`) ?? JSON.stringify({ levelData: {} }));

  progress.levelData[data.level] = progress.levelData[data.level] || {};
  progress.levelData[data.level][data.language] = progress.levelData[data.level][data.language] || {};
  progress.levelData[data.level][data.language].flowProgress = data.data;

  localStorage.setItem(`fs-flow-progress-${data.test}`, JSON.stringify(progress));

  return progress;
};

export const checkHasFlowProgressInLocalStorage = () => {
  const items = { ...localStorage };
  for (const item in items) if (item.includes('fs-flow-progress')) return true;
  return false;
};

export const saveFlowProgressInDB = async () => {
  for (const [key, value] of Object.entries(localStorage)) {
    if (key.includes('fs-flow-progress')) {
      const test = key.split('fs-flow-progress-')[1];
      const progress = JSON.parse(value);
      const levels = _.keys(progress.levelData);

      for (const level of levels) {
        const languages = progress.levelData[level];
        for (const [language, data] of Object.entries(languages)) {
          const body = {
            data: data.flowProgress,
            language,
            level,
            test,
          };
          await InputService.updateFlowProgress(body);
        }
      }
      localStorage.removeItem(key);
    }
  }
};
