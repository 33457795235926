import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from 'global/colors';
import { Link as BaseLink } from 'components/FormComponents/FormComponents';
import { Images } from 'assets/images';

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: 20px auto;
  width: 100vw;
  max-width: 1360px;
  height: 60px;
  padding: 0 40px;
  transition: all 0.25s ease-in-out;
  z-index: 4;
  border: 1px solid transparent;
  backdrop-filter: blur(8px);

  @media (max-width: 1365px) {
    width: calc(100vw - 20px);
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  ${({ isScrolled, theme }) =>
    isScrolled &&
    css`
      border: 1px solid ${theme.pageBackground};
      border-radius: 30px;
      background: ${theme.pageHeader.scrolledBackground};
      box-shadow: 0px 8px 16px ${theme.pageHeader.scrolledShadow};
    `};
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const NavigationContainer = styled.div`
  color: ${({ theme }) => theme.pageHeader.link.color};
  font-size: 1.125rem;
  display: flex;
  width: 100%;
  align-items: center;
  transition: all 0.25s ease-in-out;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 10px 20px;
    align-items: flex-start;
    position: absolute;
    background: ${({ theme }) => theme.pageHeader.mobileOpenMenuBackground};
    border-radius: 30px;
    top: 0;
    left: 0;
    right: 0;
    transform: translate(0, -100%);
    width: 100%;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
    opacity: 0;

    ${(props) =>
      props.isOpened &&
      css`
        opacity: 1;
        top: 60px;
        transform: translate(0, 0);
      `}
  }
`;

export const Link = styled(BaseLink)`
  letter-spacing: 1px;
  font-weight: 600;
  color: ${({ theme }) => theme.pageHeader.link.color};

  ${({ highlighted }) =>
    highlighted &&
    css`
      font-weight: 700;
      position: relative;

      :after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(100%, -30%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: ${colors.lightBlue8};
      }
    `}

  ${({ $hasBorder, theme }) =>
    $hasBorder &&
    css`
      padding: 0.375rem 1.5rem;
      border: 1px solid ${theme.pageHeader.link.color};
      border-radius: 0.25rem;
    `}

  :hover {
    color: ${({ theme }) => theme.pageHeader.link.colorHover};
    border-color: ${({ theme }) => theme.pageHeader.link.colorHover};
  }

  :first-child {
    margin-left: 0;
  }

  @media (max-width: 1024px) {
    // color: ${colors.darkGray2};
    margin-left: 0;
    margin-bottom: 5px;
  }
`;

export const Logo = styled.div`
  background: url(${({ isScrolled, theme }) =>
    isScrolled ? theme.pageHeader.logo.scrolled : theme.pageHeader.logo.main});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  flex: 1 1 250px;
  width: 250px;
  height: 45px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;

  ${({ isKodcentrumCampaign }) =>
    isKodcentrumCampaign &&
    css`
      background: url(${Images.kodcentrumLogo});
      background-size: contain;
      background-repeat: no-repeat;
    `}
`;

export const MenuIcon = styled.div`
  display: none;
  cursor: pointer;
  color: ${({ theme }) => theme.pageHeader.link.color};
  :after {
    content: '\f0c9';
    font-family: FontAwesome;
    font-size: 32px;
  }

  @media (max-width: 1024px) {
    display: block;
    margin-left: 20px;
  }
`;

const ExperienceRing = styled.svg`
  transform: rotate(-90deg);
  position: absolute;
`;

const calculateDashOffset = (radius, experience) => {
  const circumference = 2 * Math.PI * radius;
  const progress = experience / 100;
  return circumference * (1 - progress);
};

const FilledRing = styled.circle`
  stroke-dasharray: ${(props) => 2 * Math.PI * props.r};
  stroke-dashoffset: ${(props) => props.dashOffset};
  animation: progress 1s ease;
  animation-fill-mode: forwards;

  @keyframes progress {
    from {
      stroke-dashoffset: ${(props) => 2 * Math.PI * props.r};
    }
    to {
      stroke-dashoffset: ${(props) => props.dashOffset};
    }
  }
`;

export const ProfileExperience = ({ experience, children, size = 70 }) => (
  <ExperienceRing width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
    <circle cx={size / 2} cy={size / 2} r={(size - 10) / 2} fill="none" stroke={colors.lightGray2} strokeWidth="6" />
    <FilledRing
      dashOffset={calculateDashOffset((size - 10) / 2, experience)}
      cx={size / 2}
      cy={size / 2}
      r={(size - 10) / 2}
      fill="none"
      stroke={colors.fsCyan}
      strokeWidth="6"
    />
    {children}
  </ExperienceRing>
);

export const ProfileAvatarContainer = styled.div`
  position: relative;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1);
  transition: all 0.25s ease-in-out;
  cursor: pointer;

  @media (max-width: 1024px) {
    transform: scale(0.8);
    width: 55px;
    height: 55px;
  }

  @media (min-width: 1025px) {
    ${(props) =>
      props.isScrolled &&
      css`
        transform: scale(0.75);
      `}
  }
`;

export const ProfileExperienceText = styled.span`
  position: absolute;
  color: ${colors.white};
  font-weight: bold;
  user-select: none;
`;

export const ProfileAvatar = styled.div`
  position: absolute;
  flex-shrink: 0;
  border-radius: 50%;
  width: 58px;
  height: 58px;
  background-image: url(${(props) => props.image || ''});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 20px;
  margin-right: 20px;

  ${({ small }) =>
    small &&
    css`
      width: auto;
      justify-content: flex-end;
    `}

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    width: 100%;
  }
`;

export const LoginButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  text-wrap: nowrap;
`;

const FLAG_HEIGHT = '23px;';
const FLAG_WIDTH = '30px;';

export const FlagIcon = styled.li`
  background: url(${(props) => props.icon && Images[props.icon + `Flag`]});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  width: ${FLAG_WIDTH};
  height: ${FLAG_HEIGHT};
  list-style: none;
`;

export const MenuContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  margin-bottom: 0px;

  @media (max-width: 1024px) {
    margin-bottom: 5px;
    margin-left: 0px;
  }
`;

export const MenuList = styled.ul`
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  top: 0px;
  @media (max-width: 1024px) {
    flex-direction: row;
  }
  @media (min-width: 1025px) {
    top: ${FLAG_HEIGHT};
  }
`;
