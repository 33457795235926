import { produce } from 'immer';

import {
  LOAD_FEEDBACK_REQUEST,
  LOAD_FEEDBACK_SUCCESS,
  LOAD_FEEDBACK_FAILURE,
  LOAD_TEST_FAILURE,
  LOAD_TEST_REQUEST,
  LOAD_TEST_SUCCESS,
  UPDATE_FEEDBACK_FAILURE,
  UPDATE_FEEDBACK_REQUEST,
  UPDATE_FEEDBACK_SUCCESS,
} from './constants';

const initialState = {
  loadingFeedback: false,
  loading: false,
  error: '',
  feedbacks: [],
  visualization: [],
  tests: [],
  statistic: {},
};

export default function CandidateFeedbackPageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case LOAD_FEEDBACK_REQUEST:
        state.loadingFeedback = true;
        state.error = '';
        state.feedbacks = [];
        state.visualization = [];
        break;
      case LOAD_FEEDBACK_SUCCESS:
        state.loadingFeedback = false;
        state.error = '';
        state.feedbacks = action.data.feedbacks;
        state.visualization = action.data.dataSet;
        break;
      case LOAD_FEEDBACK_FAILURE:
        state.loadingFeedback = false;
        state.error = action.error;
        state.feedbacks = [];
        state.visualization = [];
        break;
      case LOAD_TEST_REQUEST:
        state.loading = true;
        state.error = '';
        state.tests = [];
        break;
      case LOAD_TEST_SUCCESS:
        state.loading = false;
        state.error = '';
        state.tests = action.tests;
        break;
      case LOAD_TEST_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.tests = [];
        break;
      case UPDATE_FEEDBACK_REQUEST:
        state.loading = true;
        state.error = '';
        state.statistic = {};
        break;
      case UPDATE_FEEDBACK_SUCCESS:
        state.loading = false;
        state.error = '';
        state.statistic = action.data;
        break;
      case UPDATE_FEEDBACK_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.statistic = {};
        break;
      default:
        break;
    }
  });
}
