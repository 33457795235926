const policyContent = [
  {
    id: 0,
    title: 'How we manage our personal data',
    data: (
      <p>
        This Privacy Policy contains information about how we handle your personal data. In order for you to get a
        better understanding of how we handle information about you, we have outlined the founding principles of our
        practices. We ask you to read our Privacy Policy thoroughly before consenting and allowing us to handle your
        data.
      </p>
    ),
  },
  {
    id: 1,
    title: 'Founding principles',
    data: (
      <>
        <p>
          Future Skill AB pledges to respect and protect your personal data and integrity according to the General Data
          Protection Regulation (GDPR), industry regulations and other relevant norms. To get a better understanding of
          how we handle information about you, we have outlined the founding principles of our personal data operations
          in this Privacy Policy.
        </p>
        <p>
          Personal data is information that by itself or together with other data could be used to identify, locate or
          contact an individual. Names, phone numbers, email, photographs and IP addresses are examples of personal
          data.
        </p>
        <p>
          Management of personal data refers to all kinds of practices involving personal data, for example: collection,
          analysis, registration or accumulation.
        </p>
        <p>The data processor processes personal data on behalf of another body - the data controller.</p>
        <p>
          The data controller determines what personal data is used as well as the purpose of using the data. It is the
          data controller who is ultimately responsible for the management of your personal data. If you wish to revoke
          consent for your personal data, you need to contact the data controller and initiate the request.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: 'Data processor: Future Skill AB',
    data: (
      <p>
        The data processor is Future Skill AB. We are in charge of processing your data on behalf of other companies,
        data controllers
      </p>
    ),
  },
  {
    id: 3,
    title: 'What information do we keep?',
    data: (
      <>
        <p>
          On Future Skill AB’s website, you are able to access different IT tests. That is how we receive information
          about you, such as your name and email address. Sometimes your details are kept anonymous by the data
          controller, i.e. the company where you are applying for a job and who provides us with your details. We
          process the following personal data:
        </p>
        <ul>
          <li>Name</li>
          <li>Email</li>
          <li>
            Technical data: the URL through which you gain access to our websites, your IP address and user patterns,
            such as marketing campaign data and for anti-cheating purposes, the type of browser you use, language and
            information about identifier and operational system.
          </li>
        </ul>
        <p>
          If you are an employee working for one of our clients, you can be asked to leave information about yourself.
          Depending on the situation, we could ask you for the following personal data:
        </p>
        <ul>
          <li>Name</li>
          <li>Email</li>
          <li>What company you work for</li>
        </ul>
      </>
    ),
  },
  {
    id: 4,
    title: 'How do we use the information?',
    data: (
      <>
        <p>
          Future Skill AB handles and uses the information you provide in order to improve our services and adapt our
          organization in accordance with your preferences, for example by making the website as user-friendly as
          possible. Your personal data is used to access statistics and information about user behaviour with the final
          objective of improving our website and user experience.
          <br />
          If you are an employee of one of our clients, your personal data might be used for the following specified
          purposes:
        </p>
        <ul>
          <li>Sales and marketing, such as direct messaging and/or phone contact</li>
          <li>Client care and information strategies regarding offers and job opportunities</li>
        </ul>
        <p>If you are an employee of one of our clients, we may ask for your contact details in order to:</p>
        <ul>
          <li>Send information that may interest you</li>
          <li>
            Add your name and email address to our mailing lists for newsletters and other content you have chosen to
            receive
          </li>
          <li>
            Send requested content, or in other ways fulfilling the task we have undertaken in exchange for your data
          </li>
          <li>Establish and continue contact</li>
        </ul>
        <p>
          Future Skill AB will not, without your consent, forward data about you other than what is required by law or
          in connection to an order of a product or service
        </p>
        <p>
          Future Skill AB will not intentionally exploit information from children or minors and do not pursue direct
          marketing targeted at children or minors
        </p>
      </>
    ),
  },
  {
    id: 5,
    title: 'Consent in regards to newsletters, direct marketing and continued contact',
    data: (
      <>
        <p class="m-b-0">
          When you give us your consent to handle your personal data in line with the information above, you agree with
          the following:
        </p>
        <ul>
          <li>We handle your personal data according to this Privacy Policy</li>
          <li>
            As an employee of one of our clients, we are allowed to provide you with direct marketing about our services
            via email and contact you via email or phone
          </li>
          <li>
            Subscribe to the type of emails you have chosen to receive. You can update your subscription preferences and
            choose not to receive further emails by clicking on the link at the bottom of every email, or by contacting
            us directly via email or phone
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 6,
    title: 'For how long do we store data?',
    data: (
      <p>
        We handle personal data for a maximum of three years, there after we delete the data. If you give us your
        consent to continue handling your personal data, we will store your data for another three years.
      </p>
    ),
  },
  {
    id: 7,
    title: 'Who can access the personal data?',
    data: (
      <p>
        Received data will be accessible for a limited number of people at the departments of sales and IT at Future
        Skill AB.
      </p>
    ),
  },
  {
    id: 8,
    title: 'We share some information within the boundaries for cooperation with a third party',
    data: (
      <>
        <p>We will not sell your personal data to a third party.</p>
        <p>
          We also use the services of third-parties such as Zendesk and Sendgrid for running the Future Skill platform
          and analysis of web traffic.
        </p>
      </>
    ),
  },
  {
    id: 9,
    title: 'How we use cookies',
    data: (
      <>
        <p>
          A cookie file is part of a text that - with your consent - can be placed in your computer’s hard drive. Your
          browser will then add the text in a separate file. Future Skill AB uses cookies to facilitate the traffic
          analyses on our websites by accessing information about who has visited a certain website at a certain time.
          In the settings of your browser, you can choose to accept cookies or not. You are also able to erase cookie
          files that have been placed in your hard drive.
        </p>
        <p>
          When you visit the sections of Future Skill AB’s website that are adapted after your user profile or require a
          login, your browser must be set to accept cookies. Aside from that, you can visit our website without
          disclosing your identity
        </p>
      </>
    ),
  },
  {
    id: 10,
    title: 'Where do we store the information and how do we protect it?',
    data: (
      <>
        <p>Future Skill AB protects your personal data through technical and organizational safety measures.</p>
        <p>
          Your personal information is stored behind secured networks and is only accessible for a limited amount of
          people who have specific rights to our systems. These individuals are required to keep your information
          confidential. All information that you provide is encrypted via SSL (Secure Socket Layer Technology).
        </p>
        <p>
          The data is managed in accordance with the GDPR. All information is covered by confidentiality agreements and
          our personnel only have access to the systems and functions that their work requires.
        </p>
      </>
    ),
  },
  {
    id: 11,
    title: 'You have the right to access the information we have about you',
    data: (
      <>
        <p>
          Future Skill AB endeavours to make sure that all the information we manage is correct. This also applies to
          the personal data we receive from the data controller. If data somehow is revealed to be incorrect, we will
          immediately rectify the problem. You have the right to access your personal data.
        </p>
        <p>
          From the 25th of May 2018, the GDPR will be put into action. From then on you have the right to access the
          information we have of you through the data controller, i.e. the company where you are applying for a job .
          You can also demand that we rectify erroneous information about you through the data controller or withdraw
          your consent if you no longer want us to handle your personal data. If you withdraw your consent from the data
          controller, they are required to inform us of your wishes and we will then erase the information we have of
          you. Observe that erasing your personal data can affect the delivery of information within the boundaries of a
          client relationship as well as potentially affect current dialogues.
        </p>
        <p>
          If you want to withdraw your consent or demand a register excerpt, correction or erasure, you need to contact
          the data controller to initiate the request. In order to access a register excerpt, you need to send the data
          controller an electronic copy of a signed document with your request
        </p>
      </>
    ),
  },
  {
    id: 12,
    title: 'How to contact Future Skill AB',
    data: (
      <>
        <p>
          Future Skill AB is the data processor of your personal data. If you have any queries regarding our operations
          or if you wish to contact us in order to exercise your rights, you can find our contact details below:
        </p>
        <p class="m-b-0 p-t-0">Future Skill AB</p>
        <p class="m-b-0 p-t-0">Drottninggatan 38, 411 07 GÖTEBORG</p>
        <p class="m-b-0 p-t-0">031-361 31 91</p>
        <p class="p-t-0">contact@futureskill.com</p>
      </>
    ),
  },
  {
    id: 13,
    title: 'Links to other websites',
    data: (
      <p>
        Please note that the links that appear on our websites may bring you to other websites that operate under other
        privacy regulations than what we have outlined in our Privacy Policy
      </p>
    ),
  },
  {
    id: 14,
    title: 'How do I remove my personal information from your page?',
    data: (
      <>
        <p>
          If you wish to remove all of your your personal information from Future Skill under the General Data
          Protection Regulation (GDPR) laws, then go to the
          <a href="GDPR"> GDPR page </a>and enter your email address to get the opt out link as an email.
        </p>
        <p>
          Note that this will remove all of your information from Future Skills systems, and will impact all companies
          that have sent tests to you, as well as remove your participation from any community activities.
        </p>
      </>
    ),
  },
];

export default policyContent;

// TODO: Do something about address styles
