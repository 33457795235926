import styled, { css } from 'styled-components';

import { colors } from 'global/colors';

export const SpinnerContainer = styled.div`
  ${({ position }) =>
    position &&
    css`
      position: ${position};
    `}
  ${({ width = '100%', height = '100%' }) => css`
    width: ${width};
    height: ${height};
  `}
  ${({ backgroundOverlay }) =>
    backgroundOverlay &&
    css`
      background-color: ${colors.fsLightBlue + '80'};
    `}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SpinnerIcon = styled.div.attrs({
  // spinner-border is from bootstrap
  className: 'spinner-border',
})`
  height: 5rem !important;
  width: 5rem !important;
  color: ${colors.fsDarkBlue} !important;
`;
