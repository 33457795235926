import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { colors } from 'global/colors';

export const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: block;
    ${(props) =>
      props.flexOnSmallScreen &&
      css`
        display: flex;
      `}
  }
`;

export const Header = styled.h1`
  color: ${colors.fsDarkBlue};
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 42px;
  min-width: 220px;

  ${({ isNewPage }) =>
    isNewPage &&
    css`
      color: ${({ theme }) => theme.title};
    `}
`;

export const CustomTestHeading = styled.div`
  display: flex;
  padding-top: 10px;
`;

export const RightHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
`;

export const DescriptionHeading = styled.h6`
  color: ${colors.fsDarkBlue};
  padding-top: 20px;
`;

export const Link = styled(RouterLink)`
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;
  color: ${colors.white};

  :hover {
    color: ${colors.fsCyan};
    text-decoration: none;
  }
`;
