import { colors } from './colors';
import { Images } from 'assets/images';

export const lightModeBackground = '#EEF2FA';
export const lightModeHeaderBackground = '#FCFBFC';

const header = {
  background: lightModeBackground,
  scrolledBackground: '#FFFFFFCC',
  mobileOpenMenuBackground: colors.white,
  scrolledShadow: '#FFFFFF15',
  link: {
    color: colors.darkGray4,
    colorHover: colors.lightBlue8,
  },
  icon: {
    color: colors.black,
  },
  logo: {
    main: Images.logoMainBlackText,
    scrolled: Images.logoMonoMain,
  },
};

const oldThemeHeader = {
  background: colors.fsDarkBlue,
  scrolledBackground: colors.fsDarkBlue,
  mobileOpenMenuBackground: colors.fsDarkBlue,
  scrolledShadow: '#00000015',
  link: {
    color: colors.white,
    colorHover: colors.fsCyan,
  },
  icon: {
    color: colors.white,
  },
  logo: {
    main: Images.logo,
    scrolled: Images.logo,
  },
};

// primary button colors
const button = {
  background: colors.lightBlue8,
  backgroundHover: colors.fsDarkBlueHoverLight,
  backgroundSelected: colors.checkmark,
  backgroundDisabled: colors.fsDarkBlueDisabled,
  border: colors.lightBlue8,
  borderHover: colors.fsDarkBlueHoverLight,
  borderSelected: colors.checkmark,
  borderDisabled: colors.fsDarkBlueDisabled,
  label: colors.white,
};

// Check and radio boxes
const selectionBox = {
  color: colors.gray2,
  border: colors.gray2,
  label: colors.darkBlue2,
  background: colors.white,
  checked: { color: colors.checkmark, border: colors.checkmark },
  disabled: { label: colors.gray2 },
};

// Dropdown menues
const dropdown = {
  background: colors.white,
  backgroundHover: colors.fsLightBlue,
  border: colors.fsDarkBlue,
  label: colors.black,
};

// Code editor colors
const ace = {
  comment: '#607080',
};

const playControls = {
  background: '#DDDDDD',
  backgroundHover: '#AAAAAA',
  backgroundDisabled: '#EEEEEE',
  color: '#000000',
};

const tabs = {
  label: colors.gray2,
  labelActive: colors.fsDarkBlue,
  background: colors.white,
  backgroundActive: colors.white,
  border: colors.borderGray,
};

const modal = {
  header: colors.fsDarkBlue,
  body: colors.darkGray10,
  background: colors.white,
  separator: colors.fsLightBlue,
  border: colors.fsLightBlue,
};

const footer = {
  background: lightModeBackground,
  link: {
    color: colors.darkGray4,
    colorHover: colors.lightBlue8,
  },
  icon: {
    color: colors.black,
  },
  logo: {
    main: Images.logoMainBlackText,
  },
};

const oldThemeFooter = oldThemeHeader;

export const communityList = {
  background: colors.whiteTransparent,
  selectedBackground: colors.white,
  cardBackground: colors.white,
  borderColor: colors.lightGray6,
  color: colors.lightGray7,
  selectedColor: colors.darkGray4,
  backgroundImage: Images.communityListLightBackground,
  tagBackground: colors.lightBlue9,
  tagColor: colors.fsCyan,
  difficultyTagBackground: colors.lightYellow3,
  difficultyTagColor: colors.yellow4,
  dropdownBackground: colors.whiteTranslucent,
  loaderColor: colors.white,
  checkbox: {
    background: colors.white,
    border: colors.darkGray4,
    selectedBackground: colors.lightBlue9,
    selectedBorder: colors.lightBlue10,
    markedIcon: colors.fsCyan,
    label: colors.darkGray4,
  },
};

export const auth = {
  backgroundImage: Images.authLightBackground,
  background: colors.white,
  backgroundGradient: 'linear-gradient(0deg, #EEF2FA 5%, rgba(238, 242, 250, 0) 93.75%)',
  heading: colors.darkGray4,
  form: {
    background: colors.white,
    border: colors.lightGray7,
    color: colors.lightGray7,
    linkColor: colors.darkGray9,
    checkboxColor: colors.darkGray4,
    errorBackgroundColor: colors.red2,
    errorColor: colors.red,
    successBackgroundColor: colors.cyan2,
    successColor: colors.fsCyan,
    verifiedColor: colors.green3,
  },
};

export const freecode = {
  logo: Images.logoBlack,
  logoSmall: Images.logoOnlyBlack,
  headerBackground: colors.lightGray13,
  headerDropShadow: colors.darkGray12,
  buttonBackground: colors.lightGray11,
  buttonBackgroundHover: colors.white,
  buttonColor: colors.darkGray13,
  color: colors.darkGray13,
  colorHover: colors.darkGray13,
  dropdownItemColor: colors.lightGray7,
  dropdownItemActiveColor: colors.darkGray13,
  activeAreaBackgroundColor: colors.white,
  activeAreaColor: colors.lightBlue12,
  activeAreaFocusColor: colors.lightGray13,
  commentColor: colors.lightGray7,
  scrollBarColor: colors.darkGray13,
  checkboxColor: colors.white,
  modal: {
    header: colors.darkGray13,
    background: colors.white,
    color: colors.darkGray13,
    primaryButtonBackgroundColor: colors.lightBlue8,
    primaryButtonBackgroundHoverColor: colors.lightBlue14,
    secondaryButtonBackgroundColor: colors.lightGray11,
    secondaryButtonBackgroundHoverColor: colors.lightGray14,
    secondaryButtonColor: colors.darkGray13,
  },
};

const table = {
  background: colors.white,
  secondBackground: colors.lightGray10,
};

const primaryButton = {
  background: colors.lightBlue8,
  backgroundHover: '#5B96EE',
  label: colors.white,
  labelHover: colors.white,
};
const secondaryButton = {
  background: colors.white,
  backgroundHover: colors.lightGray15,
  label: colors.darkBlue2,
  labelHover: colors.lightBlue8,
};
const grayButton = {
  background: colors.lightGray11,
  backgroundHover: colors.lightGray15,
  label: colors.darkBlue2,
  labelHover: colors.darkBlue2,
};

export const lightTheme = {
  primary: colors.fsDarkBlue,
  primaryHover: colors.fsDarkBlueHoverLight,
  primaryDisabled: colors.fsDarkBlueDisabled,
  secondary: colors.fsLightBlue,
  header: colors.fsDarkBlue,
  title: colors.lightBlue7,
  label: colors.black,
  text: colors.darkGray4,
  info: colors.darkGray11,
  tagBackground: colors.lightGray9,
  pageHeader: header,
  pageFooter: footer,
  oldThemeHeader: oldThemeHeader,
  oldThemeFooter: oldThemeFooter,
  pageBackground: lightModeBackground,
  scrollBarColor: colors.darkBlue3,
  playControls,
  button,
  selectionBox,
  dropdown,
  ace,
  tabs,
  modal,
  communityList,
  table,
  auth,
  freecode,
  primaryButton,
  secondaryButton,
  grayButton,
};
