import API from '../api';

export default class GDPRAgreementService {
  constructor() {
    this.API = new API();
  }
  getCompanyGDPRAgreement = (company) => this.API.get(`/GDPRAgreement/getCompanyGDPRAgreement?company=${company}`);

  publishAgreement = (companyId, body) => this.API.post(`/GDPRAgreement/publishAgreement/${companyId}`, body);
}
