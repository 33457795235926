import AuthService from './auth.service';
import ChallengeService from './challenge.service';
import AchievementService from './achievement.service';
import TicketService from './ticket.service';
import StatisticsService from './statistics.service';
import FeedbackService from './feedback.service';
import FreecodeService from './freecode.service';
import TestService from './test.service';
import PaymentService from './payment.service';
import CommentService from './comment.service';
import GDPRService from './gdpr.service';
import GDPRAgreementService from './GDPRAgreement.service';
import CompanyService from './company.service';
import DepartmentService from './department.service';
import EmailService from './email.service';
import AccountService from './account.service';
import CourseService from './course.service.js';
import ConfigService from './config.service';
import XPService from './xp.service';
import QuestionFeedbackService from './questionFeedback.service';
import FreecodeCreatorService from './freecode.creator.service';
import FreecodeRunService from './freecode.run.service';
import FreecodeSubmitService from './freecode.submit.service';
import InputService from './input.service';
import ChallengeSubmissionService from './challengeSubmission.service';
import TournamentSubmissionService from './tournamentSubmission.service';
import MarketingEventService from './marketingEvent.service';
import CommunityEmailService from './communityEmail.service';

const services = {
  AuthService: new AuthService(),
  ChallengeService: new ChallengeService(),
  AchievementService: new AchievementService(),
  TicketService: new TicketService(),
  StatisticsService: new StatisticsService(),
  FeedbackService: new FeedbackService(),
  FreecodeService: new FreecodeService(),
  FreecodeCreatorService: new FreecodeCreatorService(),
  FreecodeRunService: new FreecodeRunService(),
  FreecodeSubmitService: new FreecodeSubmitService(),
  TestService: new TestService(),
  PaymentService: new PaymentService(),
  CommentService: new CommentService(),
  GDPRService: new GDPRService(),
  GDPRAgreementService: new GDPRAgreementService(),
  CompanyService: new CompanyService(),
  DepartmentService: new DepartmentService(),
  EmailService: new EmailService(),
  AccountService: new AccountService(),
  CourseService: new CourseService(),
  ConfigService: new ConfigService(),
  XPService: new XPService(),
  QuestionFeedbackService: new QuestionFeedbackService(),
  InputService: new InputService(),
  ChallengeSubmissionService: new ChallengeSubmissionService(),
  TournamentSubmissionService: new TournamentSubmissionService(),
  MarketingEventService: new MarketingEventService(),
  CommunityEmailService: new CommunityEmailService(),
};

export default services;
