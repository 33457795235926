import { useState, useEffect } from 'react';
import { debounce } from '../functions/debounce';

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener and call function get get initial value
    window.addEventListener('resize', handleResize);
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

export const useWindowSizeDebounced = (delay = 200) => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }, delay);

    window.addEventListener('resize', handleResize);
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    return () => window.removeEventListener('resize', handleResize);
  }, [delay]);
  return windowSize;
};
