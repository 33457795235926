import { produce } from 'immer';

import {
  MANAGE_PASSWORD_REQUEST,
  MANAGE_PASSWORD_SUCCESS,
  MANAGE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  MANAGE_PASSWORD_CLEAR_ERROR,
  MANAGE_PASSWORD_CLEAR_STATE,
} from '../constants';

const initialState = {
  loading: false,
  error: '',
  success: false,
  updatePasswordSuccess: false,
};

export default function managePassword(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case MANAGE_PASSWORD_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        break;
      case MANAGE_PASSWORD_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        break;
      case MANAGE_PASSWORD_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case UPDATE_PASSWORD_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case UPDATE_PASSWORD_SUCCESS:
        state.loading = false;
        state.error = '';
        state.updatePasswordSuccess = true;
        break;
      case UPDATE_PASSWORD_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case MANAGE_PASSWORD_CLEAR_ERROR:
        state.error = '';
        break;
      case MANAGE_PASSWORD_CLEAR_STATE:
        state.loading = false;
        state.error = '';
        state.success = false;
        break;
      default:
        break;
    }
  });
}
