import { produce } from 'immer';

import {
  LOAD_COMPANY_SENDING_TEST_SETTINGS_REQUEST,
  LOAD_COMPANY_SENDING_TEST_SETTINGS_SUCCESS,
  LOAD_COMPANY_SENDING_TEST_SETTINGS_FAILURE,
  LOAD_TESTS_REQUEST,
  LOAD_TESTS_SUCCESS,
  LOAD_TESTS_FAILURE,
  POST_SEND_TEST_REQUEST,
  POST_SEND_TEST_SUCCESS,
  POST_SEND_TEST_FAILURE,
  ACTIVATE_TRIAL_PREMIUM_TEST_REQUEST,
  ACTIVATE_TRIAL_PREMIUM_TEST_SUCCESS,
  ACTIVATE_TRIAL_PREMIUM_TEST_FAILURE,
  RESET_STATE,
} from './constants';

const initialState = {
  loading: true,
  testLoading: true,
  error: '',
  success: false,
  companySettings: {},
  testsData: [],
  sendTestResult: {},
  isPremiumTestTrialActivated: false,
};

export default function SendTestPageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case LOAD_COMPANY_SENDING_TEST_SETTINGS_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.companySettings = {};
        break;
      case LOAD_COMPANY_SENDING_TEST_SETTINGS_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.companySettings = action.companySettings;
        break;
      case LOAD_COMPANY_SENDING_TEST_SETTINGS_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        break;
      case LOAD_TESTS_REQUEST:
        state.testLoading = true;
        state.error = '';
        state.success = false;
        state.testsData = [];
        break;
      case LOAD_TESTS_SUCCESS:
        state.testLoading = false;
        state.error = '';
        state.success = true;
        state.testsData = action.tests;
        break;
      case LOAD_TESTS_FAILURE:
        state.testLoading = false;
        state.error = action.error;
        state.success = false;
        break;
      case POST_SEND_TEST_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.sendTestResult = {};
        break;
      case POST_SEND_TEST_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.sendTestResult = action.sendTestResult;
        break;
      case POST_SEND_TEST_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        state.sendTestResult = {};
        break;
      case ACTIVATE_TRIAL_PREMIUM_TEST_REQUEST:
        state.loading = true;
        state.error = '';
        state.isPremiumTestTrialActivated = false;
        break;
      case ACTIVATE_TRIAL_PREMIUM_TEST_SUCCESS:
        state.loading = false;
        state.error = '';
        state.isPremiumTestTrialActivated = true;
        break;
      case ACTIVATE_TRIAL_PREMIUM_TEST_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      case RESET_STATE:
        state.sendTestResult = {};
        state.isPremiumTestTrialActivated = false;
        break;
      default:
        break;
    }
  });
}
