import React from 'react';

import BuilderComponent from 'components/BuilderComponent/BuilderComponent';
import { Wrapper } from 'components/Layout/Layout';

export default function ContactPage() {
  return (
    <Wrapper>
      <BuilderComponent
        entries={{
          de: '0fc41075c2be4f5ba3dc71d251679e2b',
          en: 'b7a84c2657a145feae112b42f8512f2f',
          sv: '5c55ee4592fe4075b51268f6b4e9382f',
        }}
      />
    </Wrapper>
  );
}
