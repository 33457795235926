import React, { useEffect } from 'react';
import { Spinner } from 'components/Spinner/Spinner';
import agreementPdf from 'assets/Future_skill_membership_agreement_v01.pdf';

export default function MembershipPage() {
  useEffect(() => {
    window.open(agreementPdf, '_self');
  });

  return <Spinner />;
}
