export const GET_EXERCISE_REQUEST = 'ExercisePage/GET_EXERCISE_REQUEST';
export const GET_EXERCISE_SUCCESS = 'ExercisePage/GET_EXERCISE_SUCCESS';
export const GET_EXERCISE_FAILURE = 'ExercisePage/GET_EXERCISE_FAILURE';
export const SET_EXERCISE_RATING_REQUEST = 'ExercisePage/SET_EXERCISE_RATING_REQUEST';
export const SET_EXERCISE_RATING_SUCCESS = 'ExercisePage/SET_EXERCISE_RATING_SUCCESS';
export const SET_EXERCISE_RATING_FAILURE = 'ExercisePage/SET_EXERCISE_RATING_FAILURE';

export const GET_COURSE_REQUEST = 'CoursePage/GET_COURSE_REQUEST';
export const GET_COURSE_SUCCESS = 'CoursePage/GET_COURSE_SUCCESS';
export const GET_COURSE_FAILURE = 'CoursePage/GET_COURSE_FAILURE';
export const UPDATE_FLOW_REQUEST = 'CoursePage/UPDATE_FLOW_REQUEST';
export const UPDATE_FLOW_SUCCESS = 'CoursePage/UPDATE_FLOW_SUCCESS';
export const UPDATE_FLOW_FAILURE = 'CoursePage/UPDATE_FLOW_FAILURE';
export const RESET_INPUT_EXERCISE_PROGRESS = 'CoursePage/RESET_INPUT_EXERCISE_PROGRESS';

export const GET_CHALLENGE_REQUEST = 'ChallengePage/GET_CHALLENGE_REQUEST';
export const GET_CHALLENGE_SUCCESS = 'ChallengePage/GET_CHALLENGE_SUCCESS';
export const GET_CHALLENGE_FAILURE = 'ChallengePage/GET_CHALLENGE_FAILURE';
export const SET_CHALLENGE_RATING_REQUEST = 'ChallengePage/SET_CHALLENGE_RATING_REQUEST';
export const SET_CHALLENGE_RATING_SUCCESS = 'ChallengePage/SET_CHALLENGE_RATING_SUCCESS';
export const SET_CHALLENGE_RATING_FAILURE = 'ChallengePage/SET_CHALLENGE_RATING_FAILURE';
export const GET_TOURNAMENT_PLAYBACK_REQUEST = 'ChallengePage/GET_TOURNAMENT_PLAYBACK_REQUEST';
export const GET_TOURNAMENT_PLAYBACK_SUCCESS = 'ChallengePage/GET_TOURNAMENT_PLAYBACK_SUCCESS';
export const GET_TOURNAMENT_PLAYBACK_FAILURE = 'ChallengePage/GET_TOURNAMENT_PLAYBACK_FAILURE ';
export const RESET_STATE = 'ChallengePage/RESET_STATE';
