export const LOAD_SETTINGS_REQUEST = 'SettingsPage/LOAD_SETTINGS_REQUEST';
export const LOAD_SETTINGS_SUCCESS = 'SettingsPage/LOAD_SETTINGS_SUCCESS';
export const LOAD_SETTINGS_FAILURE = 'SettingsPage/LOAD_SETTINGS_FAILURE';
export const UPDATE_COMPANY_REQUEST = 'SettingsPage/UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'SettingsPage/UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILURE = 'SettingsPage/UPDATE_COMPANY_FAILURE';
export const UPDATE_DEPARTMENT_REQUEST = 'SettingsPage/UPDATE_DEPARTMENT_REQUEST';
export const UPDATE_DEPARTMENT_SUCCESS = 'SettingsPage/UPDATE_DEPARTMENT_SUCCESS';
export const UPDATE_DEPARTMENT_FAILURE = 'SettingsPage/UPDATE_DEPARTMENT_FAILURE';
export const CREATE_DEPARTMENT_REQUEST = 'SettingsPage/CREATE_DEPARTMENT_REQUEST';
export const CREATE_DEPARTMENT_SUCCESS = 'SettingsPage/CREATE_DEPARTMENT_SUCCESS';
export const CREATE_DEPARTMENT_FAILURE = 'SettingsPage/CREATE_DEPARTMENT_FAILURE';
export const UPDATE_EMAIL_REQUEST = 'SettingsPage/UPDATE_EMAIL_REQUEST';
export const UPDATE_EMAIL_SUCCESS = 'SettingsPage/UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_FAILURE = 'SettingsPage/UPDATE_EMAIL_FAILURE';
export const INVITE_USER_REQUEST = 'SettingsPage/INVITE_USER_REQUEST';
export const INVITE_USER_SUCCESS = 'SettingsPage/INVITE_USER_SUCCESS';
export const INVITE_USER_FAILURE = 'SettingsPage/INVITE_USER_FAILURE';
export const DELETE_ACCOUNT_REQUEST = 'SettingsPage/DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_SUCCESS = 'SettingsPage/DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE = 'SettingsPage/DELETE_ACCOUNT_FAILURE';
export const UPDATE_NAME_REQUEST = 'SettingsPage/UPDATE_NAME_REQUEST';
export const UPDATE_NAME_SUCCESS = 'SettingsPage/UPDATE_NAME_SUCCESS';
export const UPDATE_NAME_FAILURE = 'SettingsPage/UPDATE_NAME_FAILURE';
export const UPDATE_USER_ROLE_REQUEST = 'SettingsPage/UPDATE_USER_ROLE_REQUEST';
export const UPDATE_USER_ROLE_SUCCESS = 'SettingsPage/UPDATE_USER_ROLE_SUCCESS';
export const UPDATE_USER_ROLE_FAILURE = 'SettingsPage/UPDATE_USER_ROLE_FAILURE';
export const UPDATE_USER_DEPARTMENT_REQUEST = 'SettingsPage/UPDATE_USER_DEPARTMENT_REQUEST';
export const UPDATE_USER_DEPARTMENT_SUCCESS = 'SettingsPage/UPDATE_USER_DEPARTMENT_SUCCESS';
export const UPDATE_USER_DEPARTMENT_FAILURE = 'SettingsPage/UPDATE_USER_DEPARTMENT_FAILURE';
export const DELETE_DEPARTMENT_REQUEST = 'SettingsPage/DELETE_DEPARTMENT_REQUEST';
export const DELETE_DEPARTMENT_SUCCESS = 'SettingsPage/DELETE_DEPARTMENT_SUCCESS';
export const DELETE_DEPARTMENT_FAILURE = 'SettingsPage/DELETE_DEPARTMENT_FAILURE';
export const PUBLISH_AGREEMENT_REQUEST = 'SettingsPage/PUBLISH_AGREEMENT_REQUEST';
export const PUBLISH_AGREEMENT_SUCCESS = 'SettingsPage/PUBLISH_AGREEMENT_SUCCESS';
export const PUBLISH_AGREEMENT_FAILURE = 'SettingsPage/PUBLISH_AGREEMENT_FAILURE';
export const UPDATE_LEVER_USER_REQUEST = 'SettingsPage/UPDATE_LEVER_USER_REQUEST';
export const UPDATE_LEVER_USER_SUCCESS = 'SettingsPage/UPDATE_LEVER_USER_SUCCESS';
export const UPDATE_LEVER_USER_FAILURE = 'SettingsPage/UPDATE_LEVER_USER_FAILURE';
export const CREATE_TT_USER_REQUEST = 'SettingsPage/CREATE_TT_USER_REQUEST';
export const CREATE_TT_USER_SUCCESS = 'SettingsPage/CREATE_TT_USER_SUCCESS';
export const CREATE_TT_USER_FAILURE = 'SettingsPage/CREATE_TT_USER_FAILURE';
export const RESET_UPDATED_IDS = 'SettingsPage/RESET_UPDATED_IDS';
