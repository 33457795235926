import services from 'api/services';

import { ObjectException } from 'global/errors';
import { isValidEmail } from 'functions/stringValidation';

import { SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE, CLEAR_STATE, CLEAR_ERROR } from './constants';

const { AuthService } = services;

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};

export const clearError = () => (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
};

export const signup = (email, company, name, isCompany, campaignSource) => async (dispatch) => {
  try {
    if (!isValidEmail(email)) {
      throw new ObjectException(
        {
          de: 'Email is not valid.',
          en: 'Email is not valid.',
          sv: 'Emailen är inte giltig.',
        },
        'INVALID_EMAIL'
      );
    }
    dispatch({ type: SIGNUP_REQUEST });
    await AuthService.signup(email, company, name, isCompany, false, false, campaignSource);
    dispatch({ type: SIGNUP_SUCCESS });
  } catch (ex) {
    dispatch({ type: SIGNUP_FAILURE, error: ex.message });
  }
};
