import { colors } from './colors';
import { Images } from 'assets/images';

/** Dark mode editor background */
export const monokaiBackground = '#272822';
export const monokaiBackgroundOffset = '#2F3129';
export const monokaiCommentColor = '#ababab';

/** Page background in dark mode */
// export const darkModeBackground = '#151515';
export const darkModeBackground = '#27323E';

const darkThemeHeader = {
  background: colors.fsDarkBlue,
  scrolledBackground: '#000000CC',
  mobileOpenMenuBackground: colors.black,
  scrolledShadow: '#00000015',
  // background: '#27323E',
  // background: '#27323F',
  link: {
    color: colors.white,
    colorHover: colors.fsCyan,
  },
  icon: {
    color: colors.white,
  },
  logo: {
    main: Images.logo,
    scrolled: Images.logo,
  },
};

const oldThemeHeader = {
  background: colors.fsDarkBlue,
  scrolledBackground: colors.fsDarkBlue,
  mobileOpenMenuBackground: colors.fsDarkBlue,
  scrolledShadow: '#00000015',
  link: {
    color: colors.white,
    colorHover: colors.fsCyan,
  },
  icon: {
    color: colors.white,
  },
  logo: {
    main: Images.logo,
    scrolled: Images.logo,
  },
};

// primary button colors
const button = {
  background: colors.fsDarkBlue,
  backgroundHover: colors.fsDarkBlueHoverLight,
  backgroundSelected: colors.checkmark,
  backgroundDisabled: colors.fsDarkBlueDisabled,
  border: colors.fsDarkBlue,
  borderHover: colors.fsDarkBlueHoverLight,
  borderSelected: colors.checkmark,
  borderDisabled: colors.fsDarkBlueDisabled,
  label: colors.white,
};

// Check and radio boxes
const selectionBox = {
  color: colors.gray2,
  border: colors.gray2,
  label: colors.darkBlue2,
  background: colors.white,
  checked: { color: colors.checkmark, border: colors.checkmark },
  disabled: { label: colors.gray2 },
};

// Dropdown menues
const dropdown = {
  background: monokaiBackground,
  backgroundHover: colors.black,
  border: colors.black,
  label: colors.fsCyan,
};

// Code editor colors
const ace = {
  comment: '#ACAFB3',
};

const playControls = {
  background: '#000000',
  backgroundHover: '#444444',
  backgroundDisabled: monokaiBackground,
  color: colors.fsCyan,
};

const tabs = {
  label: colors.gray2,
  labelActive: colors.fsCyan,
  background: monokaiBackgroundOffset,
  backgroundActive: monokaiBackground,
  border: monokaiBackground,
};

const modal = {
  header: colors.fsDarkBlue,
  body: colors.darkGray10,
  background: colors.white,
  separator: colors.fsLightBlue,
  border: colors.fsLightBlue,
};

const footer = {
  background: darkModeBackground,
  link: {
    color: colors.white,
    colorHover: colors.lightGray2,
  },
  icon: {
    color: colors.white,
  },
  logo: {
    main: Images.logo,
  },
};

const oldThemeFooter = oldThemeHeader;

export const communityList = {
  background: colors.darkGray6,
  selectedBackground: colors.darkGray5,
  cardBackground: colors.black,
  borderColor: colors.lightGray7,
  color: colors.lightGray8,
  selectedColor: colors.white,
  backgroundImage: Images.communityListDarkBackground,
  tagBackground: colors.darkGray7,
  tagColor: colors.fsCyan,
  difficultyTagBackground: colors.yellow3,
  difficultyTagColor: colors.yellow4,
  dropdownBackground: colors.darkGray8,
  loaderColor: colors.gray6,
  checkbox: {
    background: colors.darkGray6,
    border: colors.lightGray7,
    selectedBackground: colors.lightBlue9,
    selectedBorder: colors.lightBlue9,
    markedIcon: colors.fsCyan,
    label: colors.lightGray8,
  },
};

export const auth = {
  backgroundImage: Images.authDarkBackground,
  background: darkModeBackground,
  backgroundGradient: 'linear-gradient(0deg, #27323e 15%, rgba(39, 50, 62, 0) 93.75%)',
  heading: colors.white,
  form: {
    background: colors.darkGray9,
    border: colors.lightGray7,
    color: colors.lightGray7,
    linkColor: colors.white,
    checkboxColor: colors.lightGray7,
    errorBackgroundColor: colors.red2,
    errorColor: colors.red,
    successBackgroundColor: colors.cyan2,
    successColor: colors.fsCyan,
    verifiedColor: colors.green2,
  },
};

export const freecode = {
  logo: Images.logo,
  logoSmall: Images.logoOnlyWhite,
  headerBackground: colors.darkBlue3,
  headerDropShadow: colors.darkGray15,
  buttonBackground: colors.darkGray14,
  buttonBackgroundHover: colors.darkGray18,
  buttonColor: colors.lightGray12,
  color: colors.lightGray12,
  colorHover: colors.white,
  dropdownItemColor: colors.lightGray12,
  dropdownItemActiveColor: colors.white,
  activeAreaBackgroundColor: colors.lightBlue11,
  activeAreaColor: colors.lightBlue12,
  activeAreaFocusColor: colors.lightBlue13,
  commentColor: colors.lightGray7,
  scrollBarColor: colors.lightGray12,
  checkboxColor: colors.white,
  modal: {
    header: colors.lightGray6,
    background: colors.darkGray14,
    color: colors.lightGray12,
    primaryButtonBackgroundColor: colors.lightBlue8,
    primaryButtonBackgroundHoverColor: colors.lightBlue15,
    secondaryButtonBackgroundColor: colors.darkGray17,
    secondaryButtonBackgroundHoverColor: colors.darkGray16,
    secondaryButtonColor: colors.white,
  },
};

const table = {
  background: colors.black,
  secondBackground: darkModeBackground,
};

const primaryButton = {
  background: colors.lightBlue7,
  backgroundHover: colors.lightBlue15,
  label: colors.white,
  labelHover: colors.white,
};
const secondaryButton = {
  background: colors.darkBlue3,
  backgroundHover: colors.lightBlue11,
  label: '#BCC8D4',
  labelHover: '#5B96EE',
};
const grayButton = {
  background: colors.lightBlue11,
  backgroundHover: colors.lightBlue13,
  label: '#BCC8D4',
  labelHover: '#BCC8D4',
};

export const darkTheme = {
  primary: monokaiBackground,
  primaryHover: '#828891',
  primaryDisabled: monokaiBackgroundOffset,
  secondary: '#3A4556',
  header: colors.fsCyan,
  title: colors.white,
  label: colors.white,
  text: colors.lightGray4,
  info: colors.white,
  tagBackground: colors.darkGray5,
  pageHeader: darkThemeHeader,
  pageFooter: footer,
  oldThemeHeader: oldThemeHeader,
  oldThemeFooter: oldThemeFooter,
  pageBackground: darkModeBackground,
  scrollBarColor: colors.lightGray13,
  playControls,
  button,
  selectionBox,
  dropdown,
  ace,
  tabs,
  modal,
  communityList,
  table,
  auth,
  freecode,
  primaryButton,
  secondaryButton,
  grayButton,
};
