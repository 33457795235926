import { css, createGlobalStyle } from 'styled-components';

const scrollStyles = css`
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.pageBackground} !important;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
    border-radius: 1px;
  }
  ::-webkit-scrollbar {
    background: ${({ theme }) => theme.pageBackground} !important;
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollBarColor} !important;
    border-radius: 8px;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${scrollStyles}
`;
