export const GET_ALL_EXERCISES_REQUEST = 'CommunityListPage/GET_ALL_EXERCISES_REQUEST';
export const GET_ALL_EXERCISES_SUCCESS = 'CommunityListPage/GET_ALL_EXERCISES_SUCCESS';
export const GET_ALL_EXERCISES_FAILURE = 'CommunityListPage/GET_ALL_EXERCISES_FAILURE';

export const GET_ALL_CHALLENGES_REQUEST = 'CommunityListPage/GET_ALL_CHALLENGES_REQUEST';
export const GET_ALL_CHALLENGES_SUCCESS = 'CommunityListPage/GET_ALL_CHALLENGES_SUCCESS';
export const GET_ALL_CHALLENGES_FAILURE = 'CommunityListPage/GET_ALL_CHALLENGES_FAILURE';

export const GET_ALL_COURSES_REQUEST = 'CommunityListPage/GET_ALL_COURSES_REQUEST';
export const GET_ALL_COURSES_SUCCESS = 'CommunityListPage/GET_ALL_COURSES_SUCCESS';
export const GET_ALL_COURSES_FAILURE = 'CommunityListPage/GET_ALL_COURSES_FAILURE';
