import API from '../api';

export default class AccountService {
  constructor() {
    this.API = new API();
  }
  inviteUser = (body) => this.API.post(`/users/inviteUser`, body);

  deleteAccount = (id) => this.API.delete(`/users/${id}`);

  updateName = (id, body) => this.API.patch(`/users/updateName/${id}`, body);

  updateUserRole = (id, body) => this.API.patch(`/users/updateRole/${id}`, body);

  updateUserDepartment = (id, departmentId) => this.API.patch(`/users/${id}/${departmentId}`);

  updateLeverIntegrationUser = (body) => this.API.post(`/users/updateLeverIntegrationUser`, body);

  createTeamTailorIntegrationUser = (body) => this.API.post(`/users/createTeamTailorIntegrationUser`, body);

  updateAdminCompany = (companyId) => this.API.patch(`/users/updateAdminCompany/${companyId}`);

  unsubscribeUser = (accountId) => this.API.patch(`/users/unsubscribe/${accountId}`);

  updateAdminDepartment = (departmentId) => this.API.patch(`/users/updateAdminDepartment/${departmentId}`);

  updateViewAllDepartments = (body) => this.API.patch(`/users/updateViewAllDepartments`, body);

  updateViewAllCompanies = (body) => this.API.patch(`/users/updateViewAll`, body);

  getParticipantProfileForCMS = (accountId, contestId) =>
    this.API.get(`/users/getParticipantProfileForCMS/${accountId}/${contestId}`);

  createGDPRDeletionRequest = (body) => this.API.post('/users/GDPR-delete-me', body);

  sendFreecodeLinkEmail = (body) => this.API.post('/users/email-freecode-link', body);
}
