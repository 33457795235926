// Get comment
export const GET_COMMENTS_REQUEST = 'CommentsComponent/GET_COMMENTS';
export const GET_COMMENTS_SUCCESS = 'CommentsComponent/GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_FAILURE = 'CommentsComponent/GET_COMMENTS_FAILURE';

// create comment
export const INIT_POST_COMMENT = 'CommentsComponent/INIT_POST_COMMENT';
export const POST_COMMENT_REQUEST = 'CommentsComponent/POST_COMMENT_REQUEST';
export const POST_COMMENT_SUCCESS = 'CommentsComponent/POST_COMMENT_SUCCESS';
export const POST_COMMENT_FAILURE = 'CommentsComponent/POST_COMMENT_FAILURE';

// Archive actions
export const INIT_COMMENT_ARCHIVE = 'CommentsComponent/INIT_COMMENT_ARCHIVE';
export const PUT_ARCHIVE_COMMENT_REQUEST = 'CommentsComponent/PUT_ARCHIVE_COMMENT_REQUEST';
export const PUT_ARCHIVE_COMMENT_SUCCESS = 'CommentsComponent/PUT_ARCHIVE_COMMENT_SUCCESS';
export const PUT_ARCHIVE_COMMENT_FAILURE = 'CommentsComponent/PUT_ARCHIVE_COMMENT_FAILURE';

// Report actions
export const INIT_COMMENT_REPORT = 'CommentsComponent/INIT_COMMENT_REPORT';
export const POST_COMMENT_REPORT_REQUEST = 'CommentsComponent/POST_COMMENT_REPORT_REQUEST';
export const POST_COMMENT_REPORT_SUCCESS = 'CommentsComponent/POST_COMMENT_REPORT_SUCCESS';
export const POST_COMMENT_REPORT_FAILURE = 'CommentsComponent/POST_COMMENT_REPORT_FAILURE';
