import { produce } from 'immer';

import { LOAD_CMS_CHALLENGES_REQUEST, LOAD_CMS_CHALLENGES_SUCCESS, LOAD_CMS_CHALLENGES_FAILURE } from './constants';

const initialState = {
  loading: true,
  error: '',
  challenges: [],
};

export default function CMSAllChallengesReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case LOAD_CMS_CHALLENGES_REQUEST:
        state.loading = true;
        state.error = '';
        break;
      case LOAD_CMS_CHALLENGES_SUCCESS:
        state.loading = false;
        state.error = '';
        state.challenges = action.data;
        break;
      case LOAD_CMS_CHALLENGES_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      default:
        break;
    }
  });
}
