import React from 'react';
import { builder, BuilderComponent as BuilderIoComponent } from '@builder.io/react';
import { Spinner } from 'components/Spinner/Spinner';

import { Container, LoadingLabel } from './styles';
import { useAppContext } from 'hooks/useContext';
import * as strings from './strings';

// The following imports are to expose them to builder.io
// eslint-disable-next-line
import { BuilderSVG } from 'components/CurvedContainer/CurvedContainer';
// eslint-disable-next-line
import { BuilderCarousel } from 'components/Carousel/Carousel';

builder.init('ec17360b17744903a233e7aa739d27b7');

export default function BuilderComponent({ entries, entry, key, ...props }) {
  const { language } = useAppContext();
  return (
    <BuilderIoComponent name="page" key={key || language} entry={entries[language] || entry} {...props}>
      <Container>
        <LoadingLabel>{strings.loadingLabel[language]}</LoadingLabel>
        <Spinner />
      </Container>
    </BuilderIoComponent>
  );
}
