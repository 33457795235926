import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAppContext } from 'hooks/useContext';
import { useIsScrolled } from 'hooks/useIsScrolled';
import { useQueryParams } from 'hooks/useQueryParams';
import { getCookie } from 'functions/getCookie';
import { HeaderWrapper, HeaderContainer, Logo, NavigationContainer, Link, MenuIcon, LinkContainer } from './styles';
import FlagDropdown from './FlagDropdown';
import * as strings from './strings';
import ToggleThemeSwitch from './ToggleThemeSwitch';

export default function HeaderCommunity() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { c: campaignSource } = useQueryParams();
  const isKodcentrumCampaign = (campaignSource || getCookie('campaignSource')) === 'kodcentrum';

  const [isOpened, setIsOpened] = useState(false);
  const isScrolled = useIsScrolled();

  const headerLinks = [...strings.BASE_BUTTONS, ...strings.COMPANY_PAGE_LINKS()];
  const { language, setLanguage, isDarkMode, toggleDarkMode } = useAppContext();

  // Automatically closes menu if pathname or language changes (only happens on smaller resolutions)
  useEffect(() => {
    setIsOpened(false);
  }, [pathname, language]);

  return (
    <HeaderWrapper isScrolled={isScrolled}>
      <HeaderContainer>
        <Logo isKodcentrumCampaign={isKodcentrumCampaign} onClick={() => navigate('/')} isScrolled={isScrolled} />
        <NavigationContainer isOpened={isOpened}>
          <LinkContainer>
            {headerLinks.map((link) => {
              return (
                <Link key={link.to} to={link.to}>
                  {link[language]}
                </Link>
              );
            })}
          </LinkContainer>
          <LinkContainer small>
            <FlagDropdown language={language} setLanguage={setLanguage} />
          </LinkContainer>
        </NavigationContainer>
        <ToggleThemeSwitch isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
        <MenuIcon isScrolled={isScrolled} onClick={() => setIsOpened(!isOpened)} />
      </HeaderContainer>
    </HeaderWrapper>
  );
}
