import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CurvedContainer from 'components/CurvedContainer/CurvedContainer';
import { Images } from 'assets/images';
import { SectionHeader } from 'components/FormComponents/FormComponents';
import { Container, Wrapper } from 'components/Layout/Layout';
import { Spinner } from 'components/Spinner/Spinner';

import services from 'api/services';

const { ChallengeService } = services;

export default function RedirectPage() {
  const { redirectValue } = useParams();

  const [loading, setLoading] = useState(true);
  const [subtitle, setSubtitle] = useState(undefined);

  const navigate = useNavigate();

  useEffect(() => {
    setSubtitle(redirectValue || 'missing redirect path');
    if (redirectValue) {
      let checkRedirect = async () => {
        let result = await ChallengeService.getContestRedirectPath(redirectValue);
        if (result?._id) {
          navigate(`/challenges/${result._id}`);
        } else {
          setLoading(false);
          setSubtitle(`Could not find the /${redirectValue} page`);
        }
      };
      checkRedirect();
    }
  }, [redirectValue, navigate]);

  return (
    <Wrapper>
      <CurvedContainer bottom height={110} image={Images.tanks} />
      <Container column center>
        <SectionHeader bold>Redirect Page</SectionHeader>
        {subtitle && <SectionHeader>{subtitle}</SectionHeader>}
        {loading && <Spinner />}
      </Container>
    </Wrapper>
  );
}
