import { produce } from 'immer';

import {
  LOAD_REPORT_REQUEST,
  LOAD_REPORT_SUCCESS,
  LOAD_REPORT_FAILURE,
  UPDATE_TICKET_DEPARTMENT_REQUEST,
  UPDATE_TICKET_DEPARTMENT_SUCCESS,
  UPDATE_TICKET_DEPARTMENT_FAILURE,
  GENERATE_ANONYMISE_REPORT_LINK_REQUEST,
  GENERATE_ANONYMISE_REPORT_LINK_SUCCESS,
  GENERATE_ANONYMISE_REPORT_LINK_FAILURE,
  DISABLE_ANONYMISE_REPORT_LINK_REQUEST,
  DISABLE_ANONYMISE_REPORT_LINK_SUCCESS,
  DISABLE_ANONYMISE_REPORT_LINK_FAILURE,
} from './constants';

const initialState = {
  loading: true,
  error: '',
  success: false,
  report: {},
  updatedTicket: {},
  anonymiseIdResponse: '',
  disableAnonymiseId: false,
};

export default function ReportPageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case LOAD_REPORT_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.report = {};
        break;
      case LOAD_REPORT_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.report = action.data;
        break;
      case LOAD_REPORT_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        state.report = {};
        break;
      case UPDATE_TICKET_DEPARTMENT_REQUEST:
        state.loading = true;
        state.error = '';
        state.success = false;
        state.updatedTicket = {};
        break;
      case UPDATE_TICKET_DEPARTMENT_SUCCESS:
        state.loading = false;
        state.error = '';
        state.success = true;
        state.updatedTicket = { ...state.report.ticket, department: action.data.department };
        state.report.ticket = { ...state.report.ticket, department: action.data.department };
        break;
      case UPDATE_TICKET_DEPARTMENT_FAILURE:
        state.loading = false;
        state.error = action.error;
        state.success = false;
        state.updatedTicket = {};
        break;
      case GENERATE_ANONYMISE_REPORT_LINK_REQUEST:
        state.error = '';
        state.success = false;
        break;
      case GENERATE_ANONYMISE_REPORT_LINK_SUCCESS:
        state.success = true;
        state.disableAnonymiseId = false;
        state.anonymiseIdResponse = action.data.anonymiseId;
        break;
      case GENERATE_ANONYMISE_REPORT_LINK_FAILURE:
        state.error = action.error;
        state.success = false;
        break;
      case DISABLE_ANONYMISE_REPORT_LINK_REQUEST:
        state.error = '';
        state.success = false;
        break;
      case DISABLE_ANONYMISE_REPORT_LINK_SUCCESS:
        state.success = true;
        state.disableAnonymiseId = true;
        state.anonymiseIdResponse = '';
        break;
      case DISABLE_ANONYMISE_REPORT_LINK_FAILURE:
        state.error = action.error;
        state.success = false;
        break;
      default:
        break;
    }
  });
}
