import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Path from 'routes/Path';
import HeaderCommunity from 'components/HeaderCommunity/HeaderCommunity';
import Footer from 'components/Footer/Footer';
import { Spinner } from 'components/Spinner/Spinner';
import { manageZendeskButton } from 'functions/zendesk';
import { GlobalStyle } from './styles';

export default function CommunityRoute({ component: Component, authMode, zendesk = 'show', subpage }) {
  const navigate = useNavigate();
  const { isInitialized, userRole } = useSelector((state) => state.auth);

  useEffect(() => manageZendeskButton(zendesk), [zendesk]);

  useEffect(() => {
    if (!authMode || !isInitialized) return;
    if (!userRole && authMode === 'private') navigate(Path.loginPage);
    if (userRole && authMode === 'unauthed') navigate(Path.landingPage);
  }, [authMode, isInitialized, userRole, navigate]);

  return (
    <>
      <HeaderCommunity />
      <GlobalStyle />
      {!authMode || isInitialized ? <Component subpage={subpage} /> : <Spinner />}
      <Footer />
    </>
  );
}
