import { produce } from 'immer';

import { CREATE_GDPR_OPT_OUT_REQUEST, CREATE_GDPR_OPT_OUT_SUCCESS, CREATE_GDPR_OPT_OUT_FAILURE } from './constants';

const initialState = {
  loading: false,
  GDPRResponse: '',
  error: '',
};

export default function GDPROptOutPageReducer(baseState = initialState, action) {
  return produce(baseState, (state) => {
    switch (action.type) {
      case CREATE_GDPR_OPT_OUT_REQUEST:
        state.loading = true;
        state.error = '';
        state.GDPRResponse = '';
        break;
      case CREATE_GDPR_OPT_OUT_SUCCESS:
        state.loading = false;
        state.error = '';
        state.GDPRResponse = action.response;
        break;
      case CREATE_GDPR_OPT_OUT_FAILURE:
        state.loading = false;
        state.error = action.error;
        break;
      default:
        break;
    }
  });
}
