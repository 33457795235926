import { getCookie } from './getCookie';
import services from 'api/services';
import { getConfig } from 'functions/getConfig';

const { MarketingEventService } = services;

/**
 *
 * @param {String} event the event name, such as pageView, run code, etc.
 * @param {String} refId reference id, such as the id of the challenge, course, etc.
 */

export function sendAnalytics(event = 'enterPage', refId = '', isCommunityEvent = true) {
  // If no trackingId, generate one and set it as a cookie
  let trackingId = getCookie('trackingId');
  if (!trackingId) {
    trackingId = [...Array(30)].map(() => Math.random().toString(36)[2]).join('');
    document.cookie = `trackingId=${trackingId}; Domain=${
      getConfig().COOKIE
    }; Max-Age=2147483647; Path=/; SameSite=Lax`;
    MarketingEventService.updateCommunityEvent({ event: 'Unique Visitor' });
  }
  if (isCommunityEvent) MarketingEventService.updateCommunityEvent({ event });

  const campaignSource = getCookie('campaignSource');
  if (!campaignSource) return;
  let request = { event, campaignSource, trackingId };
  if (refId !== '') {
    request.refId = refId;
  }

  MarketingEventService.createMarketingEvent(request);
}
