import { useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import _ from 'underscore';

import { useActions } from 'hooks/useActions';
import { getConfig } from 'functions/getConfig';
import { getCookie } from 'functions/getCookie';
import {
  UPDATE_COMPANY_STATUS,
  UPDATE_FREECODE_TICKET_RUN_BODY,
  UPDATE_FREECODE_TICKET_RUN_STATUS,
  UPDATE_FREECODE_TICKET_CODE_DATA,
  UPDATE_FREECODE_TICKET_SUBMIT_STATUS,
  UPDATE_FREECODE_TICKET_EXTENDED_DEADLINE,
  UPDATE_SPECTATORS_COUNT,
  UPDATE_WEBSOCKET_STATUS,
} from 'global/constants';
// import { UPDATE_TICKET } from 'pages/CandidateTicketsPage/constants';
// import { UPDATE_TICKET_STATUS } from 'pages/TicketsPage/constants';

window.PrimusWebSocket = null;
window.PrimusConnected = false;

const storePrimusResponse = (data) => async (dispatch) => {
  if (data.length) {
    for (const response of _.flatten(data)) {
      // if (response.type === 'ticket') dispatch({ type: UPDATE_TICKET_STATUS, payload: response });
      if (response.type === 'company') dispatch({ type: UPDATE_COMPANY_STATUS, payload: response });
    }
  } else if (data.type === 'ticket') {
    // dispatch({ type: UPDATE_TICKET, payload: data });
    // dispatch({ type: UPDATE_TICKET_STATUS, payload: data });
  } else if (data.type === 'company') {
    dispatch({ type: UPDATE_COMPANY_STATUS, payload: data });
  } else if (data.isFreecodeData) {
    if (data.type === 'ticketCodeData') dispatch({ type: UPDATE_FREECODE_TICKET_CODE_DATA, payload: data });
    else if (data.type === 'ticketRunData') dispatch({ type: UPDATE_FREECODE_TICKET_RUN_BODY, payload: data });
    else if (data.type === 'ticketRunStatus') dispatch({ type: UPDATE_FREECODE_TICKET_RUN_STATUS, payload: data });
    else if (data.type === 'ticketSubmitted') dispatch({ type: UPDATE_FREECODE_TICKET_SUBMIT_STATUS, payload: data });
    else if (data.type === 'ticketSpectatorsCount') dispatch({ type: UPDATE_SPECTATORS_COUNT, payload: data });
    else if (data.type === 'deadlineExtended')
      dispatch({ type: UPDATE_FREECODE_TICKET_EXTENDED_DEADLINE, payload: data });
  } else if (data.type === 'updateWebSocketStatus') {
    dispatch({ type: UPDATE_WEBSOCKET_STATUS, payload: data.status });
  }
  // Unknown types will be lost to the void
};

const primusActions = { storePrimusResponse };

export default function PrimusWebSocket() {
  const { storePrimusResponse } = useActions(primusActions);

  const { sendJsonMessage, lastJsonMessage } = useWebSocket(`${getConfig().WEBSOCKET_ROOT_URL}/live`, {
    onOpen: () => {
      storePrimusResponse({ type: 'updateWebSocketStatus', status: true });
      window.PrimusConnected = true;
    },
    onClose: () => {
      storePrimusResponse({ type: 'updateWebSocketStatus', status: false });
      window.PrimusConnected = false;
    },
    shouldReconnect: () => true,
  });

  useEffect(() => {
    if (sendJsonMessage) window.sendJsonMessage = sendJsonMessage;
  }, [sendJsonMessage]);

  useEffect(() => {
    if (lastJsonMessage) storePrimusResponse(lastJsonMessage);
  }, [lastJsonMessage, storePrimusResponse]);
}

export const sendPrimusRequest = (data) => {
  if (window.PrimusConnected) {
    let interval;
    const sendData = () => {
      let details = { ...data, isReact: true };
      let session = getCookie('fsSession');
      if (session) {
        details = { ...details, _session: session };
      }
      window.sendJsonMessage(details);
      if (interval) return clearInterval(interval);
      interval = setInterval(sendData, 200);
    };
    sendData();
  }
};
