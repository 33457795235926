import ProfilePageReducer from './ProfilePage/reducer';
import AchievementsPage from './AchievementsPage/reducer';
import MultichoiceTestPageReducer from './MultichoiceTestPage/reducer';
import CandidateFeedbackPageReducer from './CandidateFeedbackPage/reducer';
import StatisticsPageReducer from './StatisticsPage/reducer';
import PaymentsPageReducer from './PaymentsPage/reducer';
import CustomTestsPageReducer from './CustomTestsPage/reducer';
import SuperAdminPageReducer from './SuperAdminPage/reducer';
import CandidateTicketsPageReducer from './CandidateTicketsPage/reducer';
import SettingsPageReducer from './SettingsPage/reducer';
import SendTestPageReducer from './SendTestPage/reducer';
import ReportPageReducer from './ReportPage/reducer';
import HackathonMatchesReducer from './HackathonMatches/reducer';
import XPPageReducer from './XPPage/reducer';
import QuestionFeedbackPageReducer from './QuestionFeedbackPage/reducer';
import UnsubscribePageReducer from './UnsubscribePage/reducer';
import TicketsPageReducer from './TicketsPage/reducer';
import CMSAllChallengesReducer from './cmsAllChallenges/reducer';
import CMSAllContestsReducer from './cmsAllContests/reducer';
import CMSCandidateInfoReducer from './cmsCandidateInfo/reducer';
import CMSResultReducer from './cmsResult/reducer';
import CMSEditContestReducer from './cmsEditContest/reducer';
import PlansPageReducer from './PlansPage/reducer';
import GDPROptOutPageReducer from './GDPRDeletionPage/reducer';
import GDPRConfirmationPageReducer from './GDPRConfirmationPage/reducer';
import CommunityListPageReducer from './CommunityListPage/reducer';
import CommunitySelectedPageReducer from './CommunitySelectedPage/reducer';

const userReducers = {
  profile: ProfilePageReducer('ME'),
  userProfile: ProfilePageReducer('OTHER'),
  AchievementsPage,
  MultichoiceTestPageReducer,
  CandidateFeedbackPageReducer,
  StatisticsPageReducer,
  PaymentsPageReducer,
  CustomTestsPageReducer,
  SuperAdminPageReducer,
  CandidateTicketsPageReducer,
  SettingsPageReducer,
  SendTestPageReducer,
  ReportPageReducer,
  HackathonMatchesReducer,
  XPPageReducer,
  QuestionFeedbackPageReducer,
  UnsubscribePageReducer,
  TicketsPageReducer,
  CMSAllChallengesReducer,
  CMSAllContestsReducer,
  CMSCandidateInfoReducer,
  CMSResultReducer,
  CMSEditContestReducer,
  PlansPageReducer,
  GDPROptOutPageReducer,
  GDPRConfirmationPageReducer,
  CommunityListPageReducer,
  CommunitySelectedPageReducer,
};

export default userReducers;
