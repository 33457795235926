const apiErrors = {
  DEFAULT: {
    de: 'Something went wrong.',
    en: 'Something went wrong.',
    sv: 'Något gick fel.',
  },
  USER_ALREADY_EXISTS: {
    de: 'This email is already in use.',
    en: 'This email is already in use.',
    sv: 'Denna email används redan.',
  },
  LOGIN_CREDENTIALS_ERROR: {
    de: 'Invalid email or password.',
    en: 'Invalid email or password.',
    sv: 'Ogiltig email eller lösenord.',
  },
  TWO_FACTOR_CREDENTIALS_ERROR: {
    de: 'Invalid code.',
    en: 'Invalid code.',
    sv: 'Ogiltig kod.',
  },
  RESOURCE_NOT_FOUND_ERROR: {
    de: 'The user does not exist.',
    en: 'The user does not exist.',
    sv: 'Användaren finns ej.',
  },
};

export default apiErrors;
