import { combineReducers } from 'redux';

import userReducers from 'pages/reducers';
import auth from './authReducer';
import signUp from './signUpReducer';
import managePassword from './passwordReducer';
import freecode from './freecodeReducer';
import commentsReducer from 'components/CommunityComments/reducer';

const createRootReducer = (routerReducer) =>
  combineReducers({
    router: routerReducer,
    auth,
    signUp,
    managePassword,
    commentsReducer,
    freecode,
    ...userReducers,
  });

export default createRootReducer;
