import React, { useRef } from 'react';
import { FlagIcon, MenuContainer, MenuList } from './styles';
import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick';

const allLanguages = ['sv', 'en', 'de'];

export default function FlagDropdown({ language, setLanguage }) {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);
  const onSetLanguage = (lang) => {
    setLanguage(lang);
    setIsActive(!isActive);
  };

  const displayMenuList = () => {
    return (
      isActive && (
        <MenuList ref={dropdownRef}>
          {allLanguages.map((lang) => (
            <FlagIcon onClick={() => onSetLanguage(lang)} key={lang} icon={lang} />
          ))}
        </MenuList>
      )
    );
  };

  return (
    <MenuContainer>
      {!isActive && <FlagIcon onClick={onClick} icon={language} />}
      {displayMenuList()}
    </MenuContainer>
  );
}
