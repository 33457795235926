import API from '../api';

export default class CommunityEmailService {
  constructor() {
    this.API = new API();
  }

  sendCommunityEmail = (body) => this.API.post('/communityEmail/', body);

  getCommunityEmails = () => this.API.get('/communityEmail/');
}
