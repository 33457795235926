import { useState, useEffect } from 'react';

export const useIsScrolled = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setIsScrolled(window.pageYOffset > 0);
    };

    // Add listener and call it once to get an initial value
    window.addEventListener('scroll', onScroll);
    onScroll();

    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  return isScrolled;
};
